import {RoutePoint} from "./RoutePoint";
import {HTTP_METHOD, HttpClient} from "../client/service/HttpClient";
import {DPoint} from "../map/geom/DPoint";
import {IChartData} from "../routeplanner/IChartData";
import {IPrintProperties} from "../map/print/IPrintProperties";
import {Translation} from "../Translation";
import {IRouteProperties} from "./IRouteProperties";

export enum ConnType {
    bicycle = "bicycle",
    bus = "bus",
}

export enum route_type {
    simple = "simple",
    advanced = "advanced"
}

export abstract class ARoute {
    protected crs: string = null;
    protected points: RoutePoint[];
    protected url: string = null;
    protected calculated: IRouteProperties;
    protected routeType: route_type = route_type.simple;
    protected commonPrintProperties: { [key: string]: string };
    protected options: { [key: string]: any } = {};

    public constructor(url: string, crs: string) {
        this.url = url;
        this.crs = crs;
        this.points = [
            new RoutePoint(null, null, ConnType.bicycle, ConnType.bicycle, null),
            new RoutePoint(null, null, ConnType.bicycle, ConnType.bicycle, null)
        ];
    }

    public getUrl() {
        return this.url;
    }

    public getPoints() {
        return this.points;
    }

    public setPoints(points: RoutePoint[]) {
        this.points = points;
    }

    public getRouteType(): route_type {
        return this.routeType;
    }

    public setRouteType(routeType: route_type): ARoute {
        this.routeType = routeType;
        return this;
    }

    public isCalculated(): boolean {
        return !!this.calculated;
    }

    public getCalculated(): IRouteProperties {
        return this.calculated;
    }

    public getRoute(): any {
        if (this.calculated) {
            return this.calculated[this.routeType];
        }
        return null;
    }

    public getStart(): RoutePoint {
        return this.points[0];
    }

    public setStart(point: RoutePoint) {
        this.points[0] = point;
        return this;
    }

    public getEnd(): RoutePoint {
        return this.points[this.points.length - 1];
    }

    public setEnd(point: RoutePoint) {
        this.points[this.points.length - 1] = point;
        return this;
    }

    public getVia(pos: number): RoutePoint {
        if (pos > 0 && pos < this.points.length - 1) {
            return this.points[pos];
        }
        return null;
    }

    public getViaPoints(): RoutePoint[] {
        return this.points.slice(1, this.points.length - 1);
    }

    public addVia(point: RoutePoint, pos: number) {
        this.points.splice(pos, 0, point);
    }

    public setVia(point: RoutePoint, pos: number) {
        if (pos > 0 && pos < this.points.length - 1) {
            this.points[pos] = point;
        } else if (pos === this.points.length - 1) {
            this.addVia(point, pos);
        }
    }

    public removeVia(pos: number) {
        this.points.splice(pos, 1);
    }

    public movePoint(from: number, to: number): void {
        if (from === to) {
            return;
        }
        const point = this.points.splice(from, 1);
        const p = this.points.splice(to, 0, point[0]);
    }

    public getPointsCount() {
        return this.points.length;
    }

    public setOptions(options: { [key: string]: any }) {
        this.options = options;
    }

    protected transform(cordinates: number[], crs: string) {
        return new DPoint(cordinates, crs).getCoordinates(this.crs);
    }

    protected createRoutePoint(cordinates: number[], crs: string, title: string) {
        return new RoutePoint(new DPoint(cordinates, crs).getCoordinates(this.crs), title, ConnType.bicycle, ConnType.bicycle, null);
    }

    public reset() {
        if (this.points.length > 2) {
            this.points.splice(2, this.points.length - 2);
        }
        this.points.fill(new RoutePoint(null, null, ConnType.bicycle, ConnType.bicycle, null));
    }

    public getElevationData(): IChartData {
        return {
            profile: this.calculated.simpleElevationProfile,
            colors: this.calculated.simpleElevationColors,
            route: {
                distanceUom: this.calculated.totalDistanceUom,
                distanceValue: this.calculated.totalDistanceValue + "",
            }
        };
    }

    public getPrintProperties(legendTitle: string): IPrintProperties {
        return {...this.getCommonPrintProperties(), ...this.getCurrentPrintProperties(legendTitle)};
    }

    protected getCurrentPrintProperties(legendTitle: string) {
        const trans = Translation.getInstance();
        const r = this.calculated;
        // console .log(r);
        return {
            legend_label: legendTitle,
            route: r.name,
            route_logo: r.logos.map((logo: string) => {
                const a = document.createElement("a");
                a.href = logo;
                return a.href;
            }),
            route_instructions: r.instructions,
            description: r.description,
            startpoint: this.getStart().getTitle(),
            endpoint: this.getEnd().getTitle(),
            length: Math.round(r.totalDistanceValue) + " " + r.totalDistanceUom,
            ascending: Math.round(r.heightUp) + " " + r.heightUom,
            descending: Math.round(r.heightDown) + " " + r.heightUom,
            datasource: "" //
        };
    }

    protected getCommonPrintProperties() {
        if (!this.commonPrintProperties) {
            const trans = Translation.getInstance();
            this.commonPrintProperties = {
                datasource_label: trans.trans("print_template_datasource_label"),
                scale_label: trans.trans("print_template_scale_label"),
                route_label: trans.trans("print_template_route_name_label"),
                route_title: trans.trans("print_template_route_title"),
                description_label: trans.trans("print_template_route_description_label"),
                length_label: trans.trans("print_template_route_length_label"),
                startpoint_label: trans.trans("print_template_route_startpoint_label"),
                endpoint_label: trans.trans("print_template_route_endpoint_label"),
                ascending_label: trans.trans("print_template_route_ascending_label"),
                descending_label: trans.trans("print_template_route_descending_label")
            };
        }
        return this.commonPrintProperties;
    }

    public reverseRoute(): void {
        const points = this.points.reverse();
        for (let i = 0; i < points.length - 1; i++) {
            points[i].setConnection(points[i + 1].getConnection());
        }
        this.points = points;
    }

    public getPoint(idx: number) {
        if (idx > -1 && idx < this.points.length) {
            return this.points[idx];
        }
        return null;
    }

    public abstract parametersForCalculate(): any;

    public abstract setCalculated(data: IRouteProperties): void;

    public abstract isRequestValid(): boolean;
}
