import Overlay, {Options as OverlayOptions} from "ol/Overlay";

export class OlPopup extends Overlay {
    protected static closerClass: string = "ol-popup-closer";
    protected closer: boolean;
    protected content: HTMLElement;

    public constructor(options: OverlayOptions, useCloser: boolean) {
        super(options);
        this.content = document.createElement("div");
        this.content.classList.add("ol-popup-content");
        this.getElement().appendChild(this.content);
        if (useCloser) {
            this.addCloser();
        }
    }

    public addCloser(): void {
        if (!this.closer) {
            this.getElement().addEventListener("click", (e: Event) => {
                if ((e.target as HTMLElement).classList.contains(OlPopup.closerClass)) {
                    this.hide();
                }
            });
            // this.closer = document.createElement("a");
            // this.closer.classList.add("ol-popup-closer");
            // this.getElement().appendChild(this.closer);
            // this.closer.addEventListener("click", this.hide.bind(this));
            this.closer = true;
        }
    }

    public addContent(content: Node[]): void {
        for (const node of content) {
            this.content.appendChild(node);
        }
    }

    public show(coordinate: any): void {
        this.setPosition(coordinate);
    }

    public hide(): void {
        this.setPosition(undefined);
        if (this.closer) {
            const link = this.getElement().getElementsByClassName(OlPopup.closerClass);
            if (link.length === 1) {
                (link[0] as HTMLLinkElement).blur();
            }
        }
    }

    public setContent(content: string | Node[]): void {
        this.clearContent();
        if (typeof content === "string" || content instanceof String) {
            this.content.innerHTML = "" + content;
        } else {
            for (const node of content) {
                this.content.appendChild(node);
            }
        }
    }

    public getContent(): HTMLElement {
        return this.content;
    }

    public clearContent(): void {
        this.content.innerHTML = "";
    }
}
