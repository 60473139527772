import {OlPopup} from "../OlPopup";
import {Options as OverlayOptions} from "ol/Overlay";

export class TabPopup extends OlPopup {
    protected tabTitle: HTMLElement;
    protected tabContent: HTMLElement;

    public constructor(options: OverlayOptions, useCloser: boolean) {
        super(options, useCloser);
        this.content.classList.add("tab-content");
        this.tabContent = document.createElement("div");
        this.tabContent.classList.add("tab-content");
        this.content.appendChild(this.tabContent);
        this.tabTitle = document.createElement("div");
        this.tabTitle.classList.add("tab-title");
        this.content.appendChild(this.tabTitle);
        this.tabTitle.addEventListener("click", (e: MouseEvent) => {
            this.activate((e.target as HTMLElement).getAttribute("data-tab"));
        });
    }

    public addTabContent(title: string, content: string | Node[]): void {
        const idx = "content-" + this.tabTitle.childNodes.length;
        const titleElm = document.createElement("div");
        titleElm.setAttribute("data-tab", idx);
        titleElm.textContent = title;
        this.tabTitle.appendChild(titleElm);
        const contentElm = document.createElement("div");
        contentElm.setAttribute("data-content", idx);
        if (typeof content === "string" || content instanceof String) {
            contentElm.innerHTML = "" + content;
        } else {
            for (const node of content) {
                contentElm.appendChild(node);
            }
        }
        this.tabContent.appendChild(contentElm);
        this.activate(idx);
    }

    public addContent(content: Node[]): void {
        //
    }

    public setContent(content: string | Node[]): void {
        //
    }

    public clearContent(): void {
        this.tabTitle.innerHTML = "";
        this.tabContent.innerHTML = "";
    }

    protected activate(contentId: string) {
        if (!contentId) {
            return false;
        }
        const tabToActivate = this.tabTitle.querySelector('[data-tab="' + contentId + '"]');
        const contentToActivate = this.tabContent.querySelector('[data-content="' + contentId + '"]');
        if (tabToActivate && contentToActivate) {
            for (const elm of Array.from(this.tabTitle.childNodes)) {
                if ((elm as HTMLElement).classList.contains("activated")) {
                    (elm as HTMLElement).classList.remove("activated");
                }
            }
            tabToActivate.classList.add("activated");
            for (const elm of Array.from(this.tabContent.childNodes)) {
                if (!(elm as HTMLElement).classList.contains("hidden")) {
                    (elm as HTMLElement).classList.add("hidden");
                }
            }
            contentToActivate.classList.remove("hidden");
        }
    }
}
