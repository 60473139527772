import DragZoom from "ol/interaction/DragZoom";
import Control from "ol/control/Control";
import {DMap} from "../DMap";

export abstract class AControl extends Control {

    public static createElement(cssClass: string) {
        const wrapper = document.createElement("div");
        wrapper.classList.add(cssClass, "ol-unselectable", "ol-control");
        return wrapper;
    }

    protected dmap: DMap;
    // private dragzoom: DragZoom;
    // private button: HTMLButtonElement;

    constructor(dmap: DMap, cssClass = "") {
        super({
            element: AControl.createElement(cssClass),
        });
        this.dmap = dmap;
    }

    protected addElementToOverlay(){
        (this.dmap.getMap().getViewport().querySelector(".ol-overlaycontainer-stopevent") as HTMLElement)
            .appendChild(this.element);
    }
}
