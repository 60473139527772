import {DMap} from "../DMap";
import {IDeeplinkOpts} from "./IDeeplinkOpts";
import {ISidebarFormData} from "../../routeplanner/ISidebarFormData";
import {HttpClient} from "../../client/service/HttpClient";
import {IDeeplinkHandlerOpts} from "./IDeeplinkHandlerOpts";

export class DeeplinkHandler {

    public static create(dmap: DMap, options: IDeeplinkHandlerOpts) {
        if (DeeplinkHandler.instance === null) {
            DeeplinkHandler.instance = new DeeplinkHandler(dmap, options);
        }
        return DeeplinkHandler.instance;
    }

    public static getInstance(): DeeplinkHandler | null {
        return DeeplinkHandler.instance;
    }

    private static instance: DeeplinkHandler = null;
    protected dmap: DMap;
    protected allSources: { [key: string]: string[] };
    protected getVisibleBgSources: () => string[];
    protected setVisibleBgSources: (uuid: string[]) => void;
    protected options: IDeeplinkHandlerOpts;

    private constructor(dmap: DMap, options: IDeeplinkHandlerOpts) {
        this.dmap = dmap;
        this.allSources = {};
        this.options = options;
    }

    public addSources(sources: { [key: string]: string[] }) {
        for (const name of Object.keys(sources)) {
            if (!this.allSources[name]) {
                this.allSources[name] = [];
            }
            this.allSources[name] = Array.from(new Set(this.allSources[name].concat(sources[name])));
        }
    }

    public setGetVisibleBgSources(func: () => string[]) {
        this.getVisibleBgSources = func;
    }

    public setSetVisibleBgSources(func: (uuid: string[]) => void) {
        this.setVisibleBgSources = func;
    }

    public saveDeeplink(data: ISidebarFormData) {
        const dl = this.serialize(data);
        const id = "deeplink-" + new Date().getTime();
        const events = this.dmap.getMapActivity().getEvents(id);
        return new Promise((resolve, reject) => {
            HttpClient.sendContent(this.options.saveUrl, dl,"json", events)
                .then((data: any) => {
                    resolve(this.options.callUrl + "?deeplink=" + data.idx);
                })
                .catch((errors) => {
                    reject(errors);
                });
        });
    }

    public loadDeeplink(options: IDeeplinkOpts) {
        this.unserialize(options);
    }

    private unserialize(dlo: IDeeplinkOpts) {
        this.hideSources();
        this.setBackground(dlo);
        this.showSources(dlo);
    }

    private serialize(data: ISidebarFormData): IDeeplinkOpts {
        /* tslint:disable:object-literal-sort-keys */
        return {
            bbox: this.dmap.getExtent(),
            center: this.dmap.getCenter(),
            scale: this.dmap.getScale(),
            srs: this.dmap.getCurrentSrs(),
            backgroundSources: this.getVisibleBgSources !== undefined ? this.getVisibleBgSources() : [],
            visibleSources: this.getVisibleSources(),
            route: data,
        };
    }

    private getVisibleSources(): { [key: string]: string[] } {
        const o = {};
        for (const name of Object.keys(this.allSources)) {
            const as = this.dmap.getASource(name);
            if (as && as.getSourceState().getRealVisibles().length > 0) {
                o[name] = as.getSourceState().getRealVisibles();
            }
        }
        return o;
    }

    private centerMap(dlo: IDeeplinkOpts) {
        if (dlo.center && dlo.scale) {
            this.dmap.toCenter(dlo.center, dlo.scale);
        } else {
            this.dmap.zoomToExtent(dlo.bbox, dlo.srs);
        }
    }

    private hideSources() {
        for (const name of Object.keys(this.allSources)) {
            this.dmap.hideLayers(name, this.allSources[name]);
        }
    }

    private setBackground(dlo: IDeeplinkOpts) {
        if (dlo.backgroundSources.length) {
            this.setVisibleBgSources(dlo.backgroundSources);
        }
    }

    private showSources(dlo: IDeeplinkOpts) {
        // show all visibles
        for (const key of Object.keys(dlo.visibleSources)) {
            this.dmap.showLayers(key, dlo.visibleSources[key]);
        }
    }
}
