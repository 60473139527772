import {AInteractionHandler} from "../interaction/AInteractionHandler";
import {MapEventType} from "../MapEventObserver";
import {DMap} from "../DMap";
import Feature from "ol/Feature";
import {Styles} from "../Styles";
import {OverlayTemplate} from "../OverlayTemplate";
import {OlPopup} from "../OlPopup";
import {ClusteredVector} from "./ClusteredVector";

export class ClusterSelect extends AInteractionHandler {

    protected overlay: OverlayTemplate;
    protected popup: OlPopup;
    protected cusomized: ClusteredVector;
    protected lastContent: string;

    public constructor(mapEvent: MapEventType,
                       dmap: DMap,
                       cusomized: ClusteredVector,
                       overlay: OverlayTemplate,
                       popup: OlPopup = null) {
        super(mapEvent, dmap, cusomized.getLayer());
        this.cusomized = cusomized;
        this.overlay = overlay;
        this.setPopup(popup);
    }

    public hidePopup() {
        this.last.clear();
        this.lastContent = "";
        this.popup.hide();
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOnMulti(coordinate: [number, number], features: Feature[]): boolean {
        const added = super.interactionOnMulti(coordinate, features);
        if (features.length === 0) {
            this.lastContent = "";
            this.popup.hide();
        }
        return added;
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOn(coordinate: [number, number], feature: Feature): boolean {
        if (!this.last.has(feature)) {
            const aggregation = feature.get("aggregation");
            const count = feature.get("count");
            if (count === 1 || (count > 1 && this.cusomized.isMinResolution())) {
                const ids = [];
                for (const type of Object.keys(aggregation)) {
                    for (const item of aggregation[type]) {
                        ids.push(item.id);
                    }
                }
                this.showPopup(coordinate, ids);
                this.last.set(feature, Styles.styleAsArray(feature)); // TODO ?
            } else if (count > 1) {
                const cur = this.dmap.getMap().getView().getZoom();
                this.dmap.getMap().getView().setCenter(coordinate);
                if (cur < this.dmap.getMap().getView().getMaxZoom()) {
                    this.dmap.getMap().getView().setZoom(cur + 1);
                }
                this.dmap.getMap().getView().dispatchEvent("change");
            }
            return true;
        } else {
            // this.last.delete(feature);
            // this.popup.hide();
            this.popup.setContent(this.lastContent);
            this.popup.show(coordinate);
        }
        return false;
    }

    protected showPopup(coordinate: [number, number], ids: string[]) {
        this.cusomized.loadInfo(ids)
            .then((rows: any[]) => {
                const oarr = [];
                for (const row of rows) {
                    const o: any = {};
                    for (const name of this.overlay.getNames()) {
                        o[name] = row[name] ? row[name] : "";
                    }
                    oarr.push(o);
                }
                this.lastContent = '<div class="poi-popup">' + this.overlay.render(oarr) + "</div>";
                this.popup.setContent(this.lastContent);
                this.popup.show(coordinate);
            })
            .catch((errors) => {
                // reject(errors);
            });
    }

    /**
     * Removes a given features from an interaction layer
     * @param layer
     * @param feature
     */
    protected interactionOff(coordinate: [number, number], feature: Feature): boolean {
        if (this.last.has(feature)) {
            this.last.delete(feature);
            this.lastContent = "";
            this.popup.hide();
            return true;
        } else {
            this.lastContent = "";
            this.popup.hide();
        }
        return false;
    }

    private setPopup(popup: OlPopup = null) {
        if (popup !== null) {
            this.popup = popup;
        } else {
            this.popup = this.dmap.createPopup();
        }
    }
}
