import Layer from "ol/layer/Layer";
import {DMap, MODEL, SOURCE_POSITION} from "../DMap";
import {DExtent} from "../geom/DExtent";
import {Utils} from "../geom/Utils";
import {GroupItem} from "./GroupItem";
import Event from "ol/events/Event";
import {ViewChange} from "./ViewChangeListener";
import {IChangedSource} from "./IChangedSource";
import {IFeatureInfoOptions} from "./FeatureInfo/IFeatureInfoOptions";
import {SourceState} from "./SourceState";
import {ILayerPrintOptions} from "../print/ILayerPrintOptions";
import {IAttribution} from "./IAttribution";

export enum PropertyType {
    visibleLayers = "visibleLayers",
}

export enum SourceType {
    wms = "wms",
    vector = "vector",
}

export const ATTRIBUTION_KEY = "ATTRIBUTION";

export abstract class ASource extends GroupItem {
    protected uuid: string;
    protected dmap: DMap;
    protected mapLayer: Layer;
    protected opacity: number;
    protected extent: DExtent;
    protected srs: string;
    protected lastState: SourceState;
    protected loading: number;
    protected resolutionChangedF: (e: Event) => void;
    protected featureInfoOptions: IFeatureInfoOptions;

    public constructor(dmap: DMap, uuid: string) {
        super();
        this.dmap = dmap;
        this.uuid = uuid;
        this.lastState = new SourceState();
        this.loading = 0;
    }

    public isResolutionInRange(resolution: number): boolean {
        return resolution >= this.mapLayer.getMinResolution()
            && (this.mapLayer.getMaxResolution() === Infinity || resolution < this.mapLayer.getMaxResolution());
    }

    public abstract getType(): SourceType;

    public abstract getUrl(): string;

    public abstract getPrintOptions(extent: number[]): ILayerPrintOptions;

    public setItemVisible(itemNames: string[], visible: boolean): IChangedSource {
        return this.resetState(this.lastState.getScale(), itemNames, visible);
    }

    public setItemInfoable(itemNames: string[], infoable: boolean): IChangedSource {
        const changed = this.setItemInfoable(itemNames, infoable);
        this.lastState.setInfoables(this.getInfoables());
        return changed;
    }

    public getUuid() {
        return this.uuid;
    }

    public setVisible(value: boolean) {
        if (this.getVisible() !== value) {
            super.setVisible(value);
            const realVis = this.resetState(this.lastState.getScale(), [], null);
        }
    }

    public getFeatureInfoOptions(): IFeatureInfoOptions {
        return this.featureInfoOptions;
    }

    public getSourceState(): SourceState {
        return this.lastState;
    }

    public getInfoUrl(coordinates: [number, number]): string {
        return null;
    }

    public fromOptions(options: { [key: string]: any }) {
        this.opacity = options.hasOwnProperty("opacity") ? options.opacity : 1;
        this.setVisible(options.hasOwnProperty("visible") ? options.visible : true);
        this.mapLayer.setOpacity(this.opacity);
        this.mapLayer.setVisible(this.visible);
        if (options.hasOwnProperty("extent") && options.hasOwnProperty("srs")) {
            this.extent = DExtent.fromArray(
                options.extent as [number, number, number, number],
                Utils.createProjection(options.srs));
        }
        this.setMinResolution();
        this.setMaxResolution();
        this.mapLayer.set(MODEL, this);
        this.mapLayer.set(PropertyType.visibleLayers, this.lastState.getRealVisibles());
        super.fromOptions(options);
        this.resolutionChangedF = this.resolutionChanged.bind(this);
        this.dmap.getViewChangeListener().subscribe(ViewChange.resolution, this.resolutionChangedF);
        if (options.featureInfo) {
            this.featureInfoOptions = options.featureInfo;
            this.lastState.setInfoables(this.getInfoables());
        }
        this.lastState.setNames(this.getNames());
    }

    protected registerFeatureInfo() {
        if (this.lastState.getInfoables().length > 0) {
            this.dmap.getFeatureInfo().register(this);
        } else {
            this.dmap.getFeatureInfo().unregister(this);
        }
    }

    protected resetState(scale: number, itemNames: string[], visible: boolean): IChangedSource {
        let changed = false;
        if (itemNames.length > 0 && visible !== null) {
            const changedItemVis = this.setVisibilityForNames(itemNames, visible);
            this.lastState.setVisibles(this.getVisibles());
            changed = true;
        }
        if (this.lastState.getScale() !== scale) {
            this.lastState.setScale(scale);
            changed = true;
        }
        const layers = this.getRealVisibles(scale);
        if (!this.areLayersEquals(this.lastState.getRealVisibles(), layers)) {
            //
            changed = true;
        }
        if (changed) {
            const changes = this.getChanged(this.lastState.getRealVisibles(), layers);
            this.lastState.setRealVisibles(layers);
            return changes;
        } else {
            return null;
        }
    }

    protected addLayerToMap(position: SOURCE_POSITION) {
        this.uuid = this.dmap.addSource(this.mapLayer, position, this.getUuid());
    }

    protected setMaxResolution() {
        // !!! the max resolution is exclusive and the maxScale is inclusive: max resoulution <-> max scale + 1
        this.mapLayer.setMaxResolution(
            !this.maxScale || this.maxScale === Infinity ? Infinity : Utils.resolutionsForScales(
                [this.maxScale + 1], Utils.createProjection(this.dmap.getCurrentSrs()).getUnits())[0]);
    }

    protected setMinResolution() {
        this.mapLayer.setMinResolution(!this.minScale || this.minScale === 0 ? 0 : Utils.resolutionsForScales(
            [this.minScale], Utils.createProjection(this.dmap.getCurrentSrs()).getUnits())[0]);
    }

    protected areLayersEquals(layersOld: string[], layersNew: string[]): boolean {
        if (layersOld.length !== layersNew.length) {
            return false;
        }
        for (let i = 0; i < layersOld.length; i++) {
            if (layersOld[i] !== layersNew[i]) {
                return false;
            }
        }
        return true;
    }

    protected sourceLoadStart(e: Event): void {
        this.dmap.getMapActivity().sourceLoadStart(this.getUuid());
    }

    protected sourceLoadEnd(e: Event): void {
        this.dmap.getMapActivity().sourceLoadEnd(this.getUuid());
    }

    protected sourceLoadError(e: Event): void {
        this.dmap.getMapActivity().sourceLoadError(this.getUuid());
    }

    protected resolutionChanged(e: Event) {
        const changes: IChangedSource = this.resetState(this.dmap.getScale(), [], null);
    }

    protected sourceChanged(changed: IChangedSource): void {
        window.setTimeout(() => {
            this.dmap.getMapActivity().visibilityChanged(this.getUuid(), changed);
        }, 0);
    }

    protected getChanged(layersOld: string[], layersNew: string[]): IChangedSource {
        const changed: IChangedSource = {
            changed: {
                visibles: [],
                invisibles: [],
            },
            names: this.lastState.getNames(),
            visibles: this.lastState.getVisibles(),
            realVisibles: layersNew,
            uuid: this.getUuid()
        };
        for (const l of layersOld) {
            if (layersNew.indexOf(l) === -1) {
                changed.changed.invisibles.push(l);
            }
        }
        for (const l of layersNew) {
            if (layersOld.indexOf(l) === -1) {
                changed.changed.visibles.push(l);
            }
        }
        return changed;
    }

    protected setAttribution(options: any) {
        if (options.attribution) {
            this.mapLayer.getSource().set(ATTRIBUTION_KEY, options.attribution);
            this.mapLayer.getSource().setAttributions(renderAttribution(options.attribution, false));
        }
    }
}

export const renderAttribution = (attribution: IAttribution[], onlyTitle: boolean = true): string[] => {
    const result = [];
    for (const attr of attribution ? attribution : []) {
        result.push(
            attr.onlineResource && !onlyTitle ? '<a href="' + attr.onlineResource + '" target="_blank">' + attr.title + '</a>' : attr.title
        );
    }
    return result;
}
