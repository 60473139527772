import Map from "ol/Map";
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from "ol/css";
import Control from "ol/control/Control";
import EventType from "ol/events/EventType";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Vector as VectorLayer, Layer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import Geolocation from "ol/Geolocation";
import {IGeoPositionOptions} from "./IGeoPositionOptions";

/**
 * @classdesc
 * A control for Geopositioning
 * This control is not one of the default controls of a map. To style this control
 * use css selector `.ol-geo-position`
 *
 * @see https://openlayers.org/en/latest/examples/geolocation.html and
 * @see https://openlayers.org/en/latest/examples/custom-controls.html
 */
class GeoPositionControl extends Control {
    private static enabledCssClass: string = "ol-geo-position-true";
    private static disabledCssClass: string = "ol-geo-position-false";
    private static tooltipTitle: string = "Standort bestimmen";

    protected element: HTMLElement;
    private layer: Layer;
    private map: Map;
    private geolocation: Geolocation;
    /**
     * @constructor
     * @extends {Control}
     * @param {IGeoPositionOptions} optOptions Control options.
     */
    constructor(optOptions?: IGeoPositionOptions) {
        const options: IGeoPositionOptions = optOptions || {};

        const className = (options.className !== undefined) ? options.className : "ol-geo-position";
        const cssClasses = className + " " + CLASS_UNSELECTABLE + " " + CLASS_CONTROL;
        // renders to "ol-geo-position ol-unselectable ol-control"

        const button = document.createElement("button");
        button.innerHTML = '<span class="\oi oi-target\"></span>';
        button.className = GeoPositionControl.disabledCssClass;
        button.title = GeoPositionControl.tooltipTitle;
        button.type = "button";

        const element = document.createElement("div");
        element.className = cssClasses; // "ol-geo-position ol-unselectable ol-control";
        element.appendChild(button);

        super({
            element: element as HTMLElement,
            target: options.target as HTMLElement,
        });

        // listen(button, EventType.CLICK, this.watchPosition, this);
        button.addEventListener(EventType.CLICK, this.watchPosition.bind(this), false);
        this.element = button;
    }

    /**
     * Gets called when initialised class and gets the map instance which is assigned to a member
     * @param map
     */
    public setMap(map) {
        Control.prototype.setMap.call(this, map);

        if (map) {
            this.map = map;
        }
    }

    /**
     * watchPosition of GPS Location
     */
    protected watchPosition() {
        const self = this;
        const view = this.map.getView();

        if (!view) {
            return;
        } // no view no fun

        if (this.geolocation !== undefined) {
            this.geolocation.setTracking(false);
            this.layer.setVisible(false);
            this.map.removeLayer(this.layer);
            this.map.render(); // request map render to finally get rid of the layer
            this.element.className = GeoPositionControl.disabledCssClass;
            this.geolocation = undefined;
            return;
        }

        this.geolocation = new Geolocation({
            projection: view.getProjection(),
            // enableHighAccuracy must be set to true to have the heading value.
            trackingOptions: {
                enableHighAccuracy: true,
            },
        });
        this.geolocation.setTracking(true);

        const accuracyFeature = new Feature();
        this.geolocation.on("change:accuracyGeometry", () => {
            accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
        });

        const positionFeature = new Feature();
        positionFeature.setStyle(new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: "rgba(166, 206, 23, 0.6)",
                }),
                radius: 8,
                stroke: new Stroke({
                    color: "rgba(166, 206, 23, 0.8)",
                    width: 2,
                }),
            }),
        }));

        let GeoPositionPointCoordinates;
        this.geolocation.on("change:position", () => {
            const coordinates = self.geolocation.getPosition();
            GeoPositionPointCoordinates = coordinates ? new Point(coordinates) : null;
            positionFeature.setGeometry(GeoPositionPointCoordinates);

            view.animate({
                center: GeoPositionPointCoordinates.getCoordinates(),
                duration: 700,
            });

            // view.centerOn(GeoPositionPointCoordinates.getCoordinates(), this._map.getSize(), [570, 500]);
        });

        this.layer = new VectorLayer({
            map: this.map,
            source: new VectorSource({
                features: [accuracyFeature, positionFeature],
            }),
        });

        this.element.className = GeoPositionControl.enabledCssClass;
    }
}

export default GeoPositionControl;
