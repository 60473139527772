import {IFeatureEventSubscriber} from "../IFeatureEventSubscriber";
import {MapEventType} from "../MapEventObserver";
// import {FeatureStyleFunction, StyleFunction} from "openlayers";
// import Vector from "ol/layer/Vector";
import {DMap} from "../DMap";
import {Vector} from "ol/layer";
import Feature from "ol/Feature";
import Style, {StyleFunction} from "ol/style/Style";

export abstract class AInteractionHandler implements IFeatureEventSubscriber {

    protected dmap: DMap;
    protected layer: Vector;
    protected last: Map<Feature, Style[]>;
    protected interactionStyles: Style[];
    protected mapEvent: MapEventType;
    protected eventFeatures: Set<Feature>;

    /**
     * Creates an instance
     * @param mapEvent
     * @param dmap
     * @param layer
     */
    public constructor(mapEvent: MapEventType, dmap: DMap, layer: Vector, styles: Style[] = []) {
        this.dmap = dmap;
        this.layer = layer;
        this.mapEvent = mapEvent;
        this.last = new Map<Feature, Style[]>();
        this.interactionStyles = styles;
        this.eventFeatures = new Set<Feature>();
    }

    /**
     * Registers a vector layer at an interaction layer
     */
    public register() {
        this.dmap.subscribeFeatureEvent(this.mapEvent, this);
    }

    /**
     * Unregisters a vector layer at an interaction layer
     */
    public unregister() {
        this.dmap.unsubscribeFeatureEvent(this.mapEvent, this);
    }

    /**
     * Sets a DMap instance
     * @param dmap
     */
    public setMap(dmap: DMap): void {
        this.dmap = dmap;
    }

    /**
     * Checks if a layer is registrated
     * @param layer
     */
    public hasLayer(layer: Vector) {
        return this.layer === layer;
    }

    /**
     * Called on event start
     */
    public eventStart(): void {
        // this.eventFeatures = new Set<ol.Feature>();
    }

    /**
     * Called on each feature by event
     * @param layer
     * @param feature
     */
    public addByEvent(layer: Vector, feature: Feature): void {
        if (this.hasLayer(layer)) {
            this.eventFeatures.add(feature);
        }
    }

    /**
     * Called on event end
     * @param coordinate
     */
    public eventEnd(coordinate: [number, number]): void {
        const toAdd: Feature[] = Array.from(this.eventFeatures);
        // remove from existing
        const toRemove: Feature[] = [];
        this.last.forEach((value: Style[], key: Feature) => {
            if (toAdd.indexOf(key) === -1) {
                toRemove.push(key);
            }
        });
        this.interactionOffMulti(coordinate, toRemove);
        this.interactionOnMulti(coordinate, toAdd);
        this.eventFeatures.clear();
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOnMulti(coordinate: [number, number], features: Feature[]): boolean {
        let added = false;
        for (const feature of features) {
            if (this.interactionOn(coordinate, feature)) {
                added = true;
            }
        }
        return added;
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOffMulti(coordinate: [number, number], features: Feature[]): boolean {
        let removed = false;
        for (const feature of features) {
            if (this.interactionOff(coordinate, feature)) {
                removed = true;
            }
        }
        return removed;
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOn(coordinate: [number, number], feature: Feature): boolean {
        if (!this.last.has(feature)) {
            const styles: (Style | Style[] | StyleFunction) = feature.getStyle();
            this.last.set(feature, styles instanceof Array ? styles : [styles] as Style[]);
            // change styles
            // feature.setStyle();
            return true;
        }
        return false;
    }

    /**
     * Removes a given features from an interaction layer
     * @param layer
     * @param feature
     */
    protected interactionOff(coordinate: [number, number], feature: Feature): boolean {
        if (this.last.has(feature)) {
            feature.setStyle(this.last.get(feature));
            this.last.delete(feature);
            return true;
        }
        return false;
    }
}
