import {DMap} from "./DMap";
import Vector from "ol/layer/Vector";
import CanvasImmediateRenderer from "ol/render/canvas/Immediate";
import WebGLImmediateRenderer from "ol/render/webgl/Immediate";
import {Styles} from "./Styles";
import RenderEvent from "ol/render/Event";
import WebGLContext from "ol/webgl/Context";

export class MaskLayer {
    protected dmap: DMap;
    protected layer: Vector;
    protected colorMask: string;
    protected colorFeature: string;
    protected blurRadius: number;

    /**
     * Creates an instance
     * @param dmap
     * @param layer
     * @param config
     */
    public constructor(dmap: DMap, layer: Vector, config: any = null) {
        this.dmap = dmap;
        this.layer = layer;
        this.colorMask = config.colorMask;
        this.colorFeature = config.colorFeature;
        this.blurRadius = config.blurRadius;
        this.layer.on("precompose", this.onPrecompose.bind(this));
        this.layer.on("postcompose", this.onPostcompose.bind(this));
    }

    /**
     * Handles the "precompose" event
     * @param e
     */
    protected onPrecompose(e: RenderEvent) {
        e.context.save();
    }

    /**
     * Handles the "postcompose" event
     * @param e
     */
    protected onPostcompose(e: RenderEvent) {
        if (e.vectorContext instanceof CanvasImmediateRenderer) {
            this.maskWithCanvasRenderer(e);
        } else if (e.vectorContext instanceof WebGLImmediateRenderer) {
        //     this.maskWithWebGLRenderer(e); // TODO new CanvasImmediateRenderer in maskWithWebGLRenderer
        }
        e.context.restore();
    }

    /**
     * Creates a mask view
     * @param renderer
     * @param context
     */
    protected maskWithCanvasRenderer(e: RenderEvent) {
        const cloned: HTMLCanvasElement = e.context.canvas.cloneNode(true) as HTMLCanvasElement;
        const ctx = cloned.getContext("2d");
        const maskRenderer = new CanvasImmediateRenderer(
            ctx,
            (e.vectorContext as any).pixelRatio_,
            (e.vectorContext as any).extent_,
            (e.vectorContext as any).transform_,
            (e.vectorContext as any).viewRotation_);
        if (ctx.filter) {
            ctx.filter = "blur(" + this.blurRadius + "px)"; // TODO replace filter: This is an experimental technology
        }
        const style = Styles.toStyle({fill: {color: this.colorFeature}})[0];
        for (const feature of this.layer.getSource().getFeatures()) {
            maskRenderer.drawFeature(feature, style);
        }
        ctx.globalCompositeOperation = "source-out";
        ctx.fillStyle = this.colorMask;
        ctx.fillRect(-this.blurRadius, -this.blurRadius,
            ctx.canvas.width + 2 * this.blurRadius, ctx.canvas.height + 2 * this.blurRadius);
        e.context.drawImage(cloned, 0, 0);
        cloned.remove();
    }

    /**
     * Creates a mask view
     * @param renderer
     * @param context
     */
    protected maskWithWebGLRenderer(e: RenderEvent) {
        const cloned: HTMLCanvasElement = e.context.canvas.cloneNode(true) as HTMLCanvasElement;
        const ctx = cloned.getContext("2d");
        const maskRenderer = new WebGLImmediateRenderer(
            (ctx as any) as WebGLContext,
            [0, 0], // TODO center
            0, // TODO resolution
            (e.vectorContext as any).viewRotation_,
            [0, 0], // TODO size
            (e.vectorContext as any).extent_,
            (e.vectorContext as any).pixelRatio_,
        );
        if (ctx.filter) {
            ctx.filter = "blur(" + this.blurRadius + "px)"; // TODO replace filter: This is an experimental technology
        }
        const style = Styles.toStyle({fill: {color: this.colorFeature}})[0];
        for (const feature of this.layer.getSource().getFeatures()) {
            maskRenderer.drawFeature(feature, style);
        }
        ctx.globalCompositeOperation = "source-out";
        ctx.fillStyle = this.colorMask;
        ctx.fillRect(-this.blurRadius, -this.blurRadius,
            ctx.canvas.width + 2 * this.blurRadius, ctx.canvas.height + 2 * this.blurRadius);
        e.context.drawImage(cloned, 0, 0);
        cloned.remove();
    }
}
