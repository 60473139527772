import {DMap} from "./DMap";

const activated: string = "active";
const attrSourceUuid: string = "data-sourceUuid";

export class BackgroundSwitcher {
    private container: HTMLElement;
    private dmap: DMap;

    public constructor(container: HTMLElement, dmap: DMap) {
        this.container = container;
        this.dmap = dmap;
    }

    public addSource(uuid: string, title: string) {
        const sw = this;
        const button = document.createElement("button");
        button.classList.add("lbm-background-switcher-button");
        button.textContent = title;
        button.setAttribute(attrSourceUuid, uuid);
        button.addEventListener(
            "click",
            (e: Event) => {
                sw.showSource((e.currentTarget as HTMLElement).getAttribute(attrSourceUuid));
            });
        this.container.appendChild(button);
        this.showSource(uuid);
    }

    public setVisibles(uuid: string[]): void {
        this.showSource(uuid[0]);
    }

    public getVisibles(): string[] {
        for (const elm of Array.from(this.container.children)) {
            if (elm.classList.contains(activated)) {
                return [elm.getAttribute(attrSourceUuid)];
            }
        }
        return [];
    }

    private showSource(uuid: string) {
        for (const item of Array.from(this.container.children)) {
            const uuidAtt = item.getAttribute(attrSourceUuid);
            if (uuidAtt === uuid && !item.classList.contains(activated)) {
                item.classList.add(activated);
                this.dmap.showSource(uuidAtt);
            } else if (uuidAtt !== uuid && item.classList.contains(activated)) {
                item.classList.remove(activated);
                this.dmap.hideSource(uuidAtt);
            }
        }
    }
}
