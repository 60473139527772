import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style, {StyleLike} from "ol/style/Style";
import {DMap} from "../DMap";
import {Styles} from "../Styles";
import {ASource, ATTRIBUTION_KEY, PropertyType, SourceType} from "./ASource";
import {IChangedSource} from "./IChangedSource";
import VectorTileLayer from "ol/layer/VectorTile";
import {Utils} from "../geom/Utils";
import {IVectorPrintOptions} from "../print/IVectorPrintOptions";
import {StylesToMb3} from "../StylesToMb3";

export class SourceVector extends ASource {
    protected mapLayer: Vector;

    public constructor(dmap: DMap, uuid: string, tiled: boolean) {
        super(dmap, uuid);
        if (tiled) {
            // TODO init
            this.mapLayer = new VectorTileLayer();
        } else {
            this.mapLayer = new Vector();
        }
    }

    public getPrintOptions(options: any, extent: number[] = null): IVectorPrintOptions {
        const features: Feature[] = this.mapLayer.getSource().getFeatures(); // getFeaturesInExtent(extent);
        if (features.length === 0) {
            return null;
        }
        const fc = [];
        for (const feature of features) {
            const origFeature: any = new GeoJSON().writeGeometryObject(feature.getGeometry());
            const tmpStyles = feature.getStyle() ? feature.getStyle() : this.mapLayer.getStyle();
            if (tmpStyles) {
                const list: Style[] = tmpStyles instanceof Array ? tmpStyles : [tmpStyles] as Style[];
                for (const style of list) {
                    const feat = list.length > 1 ? Utils.merge({}, origFeature) : origFeature;
                    feat.style = StylesToMb3.toSingleStyle(style, false);
                    fc.push(feat);
                }
            }
        }
        return {
            attribution: this.mapLayer.getSource().get(ATTRIBUTION_KEY),
            geometries: fc,
            opacity: 1,
            sourceId: this.getUuid(),
            type: "GeoJSON+Style",
        };
    }

    public getType(): SourceType {
        return SourceType.vector;
    }

    public getUrl() {
        return null;
    }

    public registerInfo(name: string): void {
        //
    }

    public getMapLayer(): Vector {
        return this.mapLayer;
    }

    public setStyle(style: StyleLike) {
        this.mapLayer.setStyle(style);
    }

    public clear(fast?: boolean): void {
        this.mapLayer.getSource().clear(fast);
    }

    public addFeature(feature: Feature): void {
        this.mapLayer.getSource().addFeature(feature);
    }

    public addFeatures(features: Feature[]): void {
        this.mapLayer.getSource().addFeatures(features);
    }

    public fromOptions(options: any) {
        super.fromOptions(options);
        const vectorOptions: any = {};
        if (options.format) {
            vectorOptions.format = options.format === "GeoJSON" ? new GeoJSON() : null;
        }
        if (options.url) {
            vectorOptions.url = options.url;
        }
        if (options.styles && options.styles.default) {
            this.mapLayer.setStyle(Styles.toStyle(options.styles.default));
        }

        if (this.mapLayer instanceof VectorTileLayer) {
            // TODO init
            // (this.mapLayer as VectorTileLayer).setSource(new VectorTile(vectorOptions));
            // this.mapLayer.getSource().on("tileloadstart", this.sourceLoadStart.bind(this));
            // this.mapLayer.getSource().on("tileloadend", this.sourceLoadEnd.bind(this));
            // this.mapLayer.getSource().on("tileloaderror", this.sourceLoadError.bind(this));
        } else {
            this.mapLayer.setSource(new VectorSource(vectorOptions));
            // TODO events
        }
        this.setAttribution(options);
        this.addLayerToMap(options.position);
        this.resetState(this.dmap.getScale(), [], null);
    }

    public resetState(scale: number, itemNames: string[], visible: boolean): IChangedSource {
        const changed = super.resetState(scale, itemNames, visible);
        if (changed !== null) {
            this.mapLayer.set(PropertyType.visibleLayers, this.lastState.getRealVisibles());
            this.sourceChanged(changed);
        }
        return changed;
    }
}
