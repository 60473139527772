import Image from "ol/layer/Image";
import Tile from "ol/layer/Tile";
import ImageWMS from "ol/source/ImageWMS";
import TileWMS from "ol/source/TileWMS";
import {DMap} from "../DMap";
import {ASource, ATTRIBUTION_KEY, SourceType} from "./ASource";
import {IChangedSource} from "./IChangedSource";
import {IWmsFeatureInfoOptions} from "./FeatureInfo/IWmsFeatureInfoOptions";
import {IWmsPrintOptions} from "../print/IWmsPrintOptions";

export class SourceWms extends ASource {
    protected mapLayer: Tile | Image;
    protected featureInfoOptions: IWmsFeatureInfoOptions;

    public constructor(dmap: DMap, uuid: string, tiled: boolean = false) {
        super(dmap, uuid);
        if (tiled) {
            this.mapLayer = new Tile();
            this.mapLayer.setSource(new TileWMS());
            this.mapLayer.getSource().on("tileloadstart", this.sourceLoadStart.bind(this));
            this.mapLayer.getSource().on("tileloadend", this.sourceLoadEnd.bind(this));
            this.mapLayer.getSource().on("tileloaderror", this.sourceLoadError.bind(this));
        } else {
            this.mapLayer = new Image();
            this.mapLayer.setSource(new ImageWMS());
            this.mapLayer.getSource().on("imageloadstart", this.sourceLoadStart.bind(this));
            this.mapLayer.getSource().on("imageloadend", this.sourceLoadEnd.bind(this));
            this.mapLayer.getSource().on("imageloaderror", this.sourceLoadError.bind(this));
        }
    }

    public getPrintOptions(extent: number[]): IWmsPrintOptions {
        return {
            attribution: this.mapLayer.getSource().get(ATTRIBUTION_KEY),
            changeAxis: false,
            maxResolution: null,
            minResolution: null,
            opacity: 1,
            order: null,
            sourceId: this.getUuid(),
            type: this.getType(),
            url: this.getUrl(),
        };
    }

    public getType(): SourceType {
        return SourceType.wms;
    }

    public getFeatureInfoOptions(): IWmsFeatureInfoOptions {
        return this.featureInfoOptions;
    }

    public resetState(scale: number, itemNames: string[], visible: boolean): IChangedSource {
        const changed = super.resetState(scale, itemNames, visible);
        if (changed !== null) {
            this.resetParams();
            this.sourceChanged(changed);
        }
        return changed;
    }

    public fromOptions(options: any) {
        this.setAttribution(options);
        (this.mapLayer.getSource() as TileWMS | ImageWMS).setUrl(options.url);
        const params: any = (this.mapLayer.getSource() as TileWMS | ImageWMS).getParams();
        super.fromOptions(options);
        params.FORMAT = options.format;
        params.VERSION = options.version;
        if (options.sld) {
            params.SLD = options.sld;
        } else if (options.sld_body) {
            params.SLD_BODY = options.sld_body;
        }
        (this.mapLayer.getSource() as TileWMS | ImageWMS).updateParams(params);
        this.resetState(this.dmap.getScale(), [], null);
        this.resetParams();
        // params.LAYERS = this.layers;
        this.addLayerToMap(options.position);
        this.registerFeatureInfo();
    }

    public getUrl() {
        const names = this.lastState.getRealVisibles();
        return (this.mapLayer.getSource() as ImageWMS | TileWMS).getGetFeatureInfoUrl(
            this.dmap.getCenter(), this.dmap.getResolution(), this.dmap.getCurrentSrs(), {
                LAYERS: names,
                REQUEST: "GetMap",
            });
    }

    public getInfoUrl(coordinates: [number, number]): string {
        const layers = this.getRealInfoables(this.dmap.getScale());
        if (layers.length > 0) {
            return (this.mapLayer.getSource() as ImageWMS | TileWMS).getGetFeatureInfoUrl(
                coordinates, this.dmap.getResolution(), this.dmap.getCurrentSrs(), {
                    FEATURE_COUNT: this.featureInfoOptions.maxCount ? this.featureInfoOptions.maxCount : 1,
                    INFO_FORMAT: this.featureInfoOptions.format,
                    INFO_LAYERS: layers,
                    LAYERS: layers,
                });
        } else {
            return null;
        }
    }

    protected resetParams() {
        const params: any = (this.mapLayer.getSource() as TileWMS | ImageWMS).getParams();
        params.LAYERS = this.lastState.getRealVisibles();
        (this.mapLayer.getSource() as TileWMS | ImageWMS).updateParams(params);
        if (this.mapLayer.getVisible() === true && this.lastState.getRealVisibles().length === 0) {
            this.mapLayer.setVisible(false);
            this.mapLayer.getSource().refresh();
        } else if (this.mapLayer.getVisible() === false && this.lastState.getRealVisibles().length > 0) {
            this.mapLayer.getSource().refresh();
            this.mapLayer.setVisible(true);
        }
    }
}
