import {ARoute, ConnType} from "./ARoute";
import {RoutePoint} from "./RoutePoint";

export class UserRoute extends ARoute {
    protected properties: { [key: string]: boolean | string };
    protected connections: string[];

    public isRequestValid() {
        for (const point of this.points) {
            if (!point || !point.getCoordinates()) {
                return false
            }
        }
        return true;
    }

    public reset() {
        super.reset();
        this.connections = [ConnType.bicycle];
    }

    public setCalculated(data: any) {
        this.calculated = data;
    }

    public parametersForCalculate(): any {
        let last: RoutePoint = null;
        const points = {
            points: this.points.map((p) => {
                return p.getCoordinates().join(" ")
                    + (p.getType() === ConnType.bus && p.getStation() !== null ? "|" + p.getStation() : "");
            }).join(",")
        }
        return {...points, ...this.options};
    }
}
