import {DMap} from "./DMap";
import MapBrowserEvent from "ol/MapBrowserEvent";
import {IMapEventSubscriber} from "./IMapEventSubscriber";
import {MapEventType} from "./MapEventObserver";

export class MapEventHandler {
    protected dmap: DMap;
    protected eventType: MapEventType;
    protected handlerF: (e: MapBrowserEvent) => void;
    protected dragging: IMapEventSubscriber[];
    protected draggingSpatialCase: IMapEventSubscriber[];
    protected currentDragging: IMapEventSubscriber[];
    protected notdragging: IMapEventSubscriber[];
    protected notdraggingSpatialCase: IMapEventSubscriber[];
    protected currentNotdragging: IMapEventSubscriber[];

    public constructor(dmap: DMap, eventType: MapEventType) {
        this.dmap = dmap;
        this.eventType = eventType;
        this.dragging = [];
        this.notdragging = [];
        this.draggingSpatialCase = [];
        this.notdraggingSpatialCase = [];
    }

    /**
     * Subscribes an item
     * @param subscriber
     * @param byDragging
     */
    public subscribe(subscriber: IMapEventSubscriber, byDragging: boolean): void {
        if (byDragging) {
            const subscribers = new Set(this.dragging);
            if (!subscribers.has(subscriber)) {
                subscribers.add(subscriber);
                this.dragging = Array.from(subscribers);
            }
        } else {
            const subscribers = new Set(this.notdragging);
            if (!subscribers.has(subscriber)) {
                subscribers.add(subscriber);
                this.notdragging = Array.from(subscribers);
            }
        }
        if (this.notdragging.length !== 0 || this.dragging.length !== 0) {
            this.activate();
        }
        this.setCurrent();
    }

    /**
     * Unsubscribes an item
     * @param subscriber
     * @param byDragging
     */
    public unsubscribe(subscriber: IMapEventSubscriber, byDragging: boolean) {
        if (byDragging) {
            const subscribers = new Set(this.dragging);
            if (subscribers.has(subscriber)) {
                subscribers.delete(subscriber);
                this.dragging = Array.from(subscribers);
            }
        } else {
            const subscribers = new Set(this.notdragging);
            if (subscribers.has(subscriber)) {
                subscribers.delete(subscriber);
                this.notdragging = Array.from(subscribers);
            }
        }
        if (this.notdragging.length === 0 && this.dragging.length === 0) {
            this.deactivate();
        }
        this.setCurrent();
    }

    /**
     * Event listener
     * @param e
     */
    public onEvent(e: MapBrowserEvent) {
        if (e.dragging) {
            for (const subscr of this.currentDragging) {
                subscr.onEvent(e);
            }
        } else {
            for (const subscr of this.currentNotdragging) {
                subscr.onEvent(e);
            }
        }
    }

    public setSpecialCase(byDragging: boolean, subscriber: IMapEventSubscriber[]) {
        if (byDragging) {
            this.draggingSpatialCase = [];
            for(const item of subscriber) {
                if (this.dragging.indexOf(item) !== 0) {
                    this.draggingSpatialCase.push(item);
                }
            }
        } else {
            this.notdraggingSpatialCase = [];
            for(const item of subscriber) {
                if (this.notdragging.indexOf(item) !== 0) {
                    this.notdraggingSpatialCase.push(item);
                }
            }
        }
        this.setCurrent();
    }

    public removeSpecialCase(byDragging: boolean) {
        if (byDragging) {
            this.draggingSpatialCase = [];
        } else {
            this.notdraggingSpatialCase = [];
        }
        this.setCurrent();
    }

    protected setCurrent() {
        if (this.draggingSpatialCase.length > 0) {
            this.currentDragging = this.draggingSpatialCase;
        } else {
            this.currentDragging = this.dragging;
        }
        if (this.notdraggingSpatialCase.length > 0) {
            this.currentNotdragging = this.notdraggingSpatialCase;
        } else {
            this.currentNotdragging = this.notdragging;
        }
    }

    /**
     * Activates event listening
     */
    private activate(): void {
        if (!this.handlerF) {
            this.handlerF = this.onEvent.bind(this);
            this.dmap.getMap().on(this.eventType, this.handlerF);
        }
    }

    /**
     * Deactivates event listening
     */
    private deactivate(): void {
        if (this.handlerF) {
            this.dmap.getMap().un(this.eventType, this.handlerF);
            this.handlerF = null;
        }
    }
}
