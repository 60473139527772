import {IFeatureEventSubscriber} from "./IFeatureEventSubscriber";
import {FeatureEventObserver} from "./FeatureEventObserver";
import {IMapEventSubscriber} from "./IMapEventSubscriber";
import {MapEventHandler} from "./MapEventHandler";
import {DMap} from "./DMap";

export enum MapEventType {
    singleclick = "singleclick",
    pointermove = "pointermove",
    contextmenu = "contextmenu",
}

export class MapEventObserver {
    protected dmap: DMap;
    protected mapEvents: Map<MapEventType, MapEventHandler>;
    protected featureEvents: Map<MapEventType, FeatureEventObserver>;

    constructor(dmap: DMap) {
        this.dmap = dmap;
        this.mapEvents = new Map<MapEventType, MapEventHandler>();
        this.featureEvents = new Map<MapEventType, FeatureEventObserver>();
    }

    public subscribeFeatureEvent(eventType: MapEventType, subscriber: IFeatureEventSubscriber): void {
        if (!this.featureEvents.has(eventType)) {
            this.featureEvents.set(eventType, new FeatureEventObserver(this.dmap, eventType));
        }
        this.subscribeMapEvent(eventType, this.featureEvents.get(eventType), false);
        this.featureEvents.get(eventType).subscribe(subscriber);
    }

    public unsubscribeFeatureEvent(eventType: MapEventType, subscriber: IFeatureEventSubscriber): void {
        if (this.featureEvents.has(eventType)) {
            this.featureEvents.get(eventType).unsubscribe(subscriber);
            if (this.featureEvents.get(eventType).count() === 0) {
                this.unsubscribeMapEvent(eventType, this.featureEvents.get(eventType), false);
                this.featureEvents.delete(eventType);
            }
        }
    }

    public subscribeMapEvent(eventType: MapEventType, subscriber: IMapEventSubscriber, byDragging: boolean): void {
        if (!this.mapEvents.has(eventType)) {
            this.mapEvents.set(eventType, new MapEventHandler(this.dmap, eventType));
        }
        this.mapEvents.get(eventType).subscribe(subscriber, byDragging);
    }

    public unsubscribeMapEvent(eventType: MapEventType, subscriber: IMapEventSubscriber, byDragging: boolean): void {
        if (this.mapEvents.has(eventType)) {
            this.mapEvents.get(eventType).unsubscribe(subscriber, byDragging);
        }
    }

    public setSpecialCase(eventType: MapEventType, subscriber: IMapEventSubscriber[]) {
        this.mapEvents.get(eventType)
    }

    public removeSpecialCase(eventType: MapEventType, subscriber: IMapEventSubscriber[] = []) {

    }
}
