import {IChartData} from "./IChartData";
import {Routing} from "../map/routing/Routing";
import {ITargetable} from "../route/ITargetable";

declare var Chart: any;

interface ICanvasSize {
    width: number;
    height: number;
}

interface IGradientColorOffset {
    offset: number;
    color: string;
}

export interface ILBMChartOptions extends ITargetable{
    gradientColors?: IGradientColorOffset[];
}

const GradientColorsDefault: IGradientColorOffset[] = [
    {offset: 0.0, color: "#A6CE18"},
    {offset: 1.0, color: "#8EAF16"},
];

export class LBMChart {

    private static instance: LBMChart;

    public static create(options: ILBMChartOptions) {
        if (!LBMChart.instance) {
            LBMChart.instance = new LBMChart(options);
        }
        return LBMChart.instance;
    }

    public static getInstance() {
        return LBMChart.instance;
    }

    private chart: Chart;
    private options: ILBMChartOptions;

    private constructor(options: ILBMChartOptions) {
        const defaults = {
            target: "elevationprofile",
            gradientColors: GradientColorsDefault,
        };
        this.options = {...defaults, ...options};
        // @ts-ignore
        Chart.plugins.register(this.verticalLineHoverPlugin());
        if (Routing.getInstance()) {
            document.getElementById(this.options.target).addEventListener("mouseout", (e: Event) => {
                Routing.getInstance().removeTempPoint();
            });
        }
    }

    //
    // public getElementId(): string {
    //     return this.elementId;
    // }
    //
    // public setElementId(id: string): void {
    //     this.elementId = id;
    // }

    public getElement(): HTMLCanvasElement {
        return document.getElementById(this.options.target) as HTMLCanvasElement;
    }

    public getOptions(): ILBMChartOptions {
        return this.options;
    }

    //
    // public setOptions(options: ILBMChartOptions): void {
    //     this.options = options;
    // }

    public getChartContext(contextDimension = "2d"): CanvasRenderingContext2D {
        return this.getElement().getContext(contextDimension) as CanvasRenderingContext2D;
    }

    public renderData(data: IChartData): any {
        this.destroyChart();
        const ctx = this.getChartContext();
        data.fillColor = this.createChartLinearGradient(ctx, this.getOptions().gradientColors);
        this.clearChart();
        this.chart = new Chart(ctx, this.createChartConfig(data));
        const elm = document.getElementById(this.options.target).closest(".--js-route-elevationprofile");
        if (elm) {
            if (data.route.distanceValue === null || data.route.distanceValue == "null") {
                if (!elm.classList.contains("hide")) {
                    elm.classList.add("hide");
                }
            } else {
                elm.classList.remove("hide");
            }
        }
    }

    public destroyChart(): void {
        if (!!this.chart) {
            this.clearChart();
            this.chart.clear();
            this.chart.destroy();
            this.chart = undefined;
        }
    }

    public getAsBase64resizedImageForPDFPrint(template: string, canvasSize: ICanvasSize): string {
        const originSize = this.getElementStyleSize();
        this.setElementStyleSize(canvasSize, "cm");
        const base64Chart = this.getAsBase64PNGImage();
        this.setElementStyleSize(originSize, "px");
        return base64Chart;
    }

    //
    // public setOnHoverCallback(callback: (item) => void): void {
    //     if (typeof callback === "function") {
    //         this.options.onHoverCallback = callback;
    //     }
    // }

    // public onHover(item: any): void {
    //     if (item && item.index) {
    //         Routing.getInstance().setTempPoint(item.index);
    //     }
    //     // return this.options.onHoverCallback(args);
    // }
    //
    // public setOnMouseOutCallback(callback: () => any): void {
    //     if (typeof callback === "function") {
    //         this.options.onMouseOutCallback = callback;
    //     }
    // }
    //
    // public getOnMouseOutCallback(args: any): () => {} {
    //     return this.options.onMouseOutCallback(args);
    // }

    protected createChartConfig(data: IChartData): { type: string, data: object, options: object } {
        const self = this;
        const config = {
            type: "line",
            data: {
                labels: data.profile,
                datasets: [{
                    label: "",
                    pointStyle: "line", // style of the point
                    pointRadius: 0, // point radius in px
                    pointBorderWidth: 0, // the width of the point border
                    pointBorderColor: data.colors,
                    pointBackgroundColor: data.colors,
                    backgroundColor: data.fillColor,
                    fill: true,
                    // borderColor: "rgba(255, 0, 0, 1)",
                    borderWidth: 0, // the line width in px
                    steppedLine: false,
                    showLine: true, // draw line for dataset
                    spanGaps: true, // draw lines between points with no or null data
                    lineTension: 0.4,
                    data: data.profile,
                }],
            },
            options: {
                responsive: true,
                animation: {
                    duration: 0, // general animation time
                },
                customLine: {
                    color: "black",
                },
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            borderDash: [5, 5],
                        },
                        offset: true,
                        scaleLabel: {
                            display: true,
                            labelString: "Kilometer (km)",
                        },
                        ticks: {
                            beginAtZero: true,
                            autoSkip: false,
                            autoSkipPadding: false,
                            callback(index, value: number, values) {
                                const distance = Math.round(Number(data.route.distanceValue));
                                const labelDistance = distance / 4;
                                const breakpoint = parseInt((values.length / 4).toFixed(), 10);

                                if (value === 0) {
                                    return 0;
                                }
                                if (value === breakpoint) {
                                    return labelDistance.toFixed();
                                }
                                if (value === breakpoint * 2) {
                                    return (labelDistance * 2).toFixed();
                                }
                                if (value === breakpoint * 3) {
                                    return (labelDistance * 3).toFixed();
                                }
                                if (value === values.length - 1) {
                                    return distance.toFixed();
                                }
                            },
                            maxRotation: 0,
                            minRotation: 0,
                            precision: 0,
                            padding: 0,
                        },
                        type: "category",
                    }],
                    yAxes: [{
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            borderDash: [5, 5],
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Meter ü. NN",
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                },
                tooltips: {
                    enabled: true, // set to false if you dont want to show the value in label
                    animationDuration: 0, // duration of animations when hovering an item
                    intersect: false,
                    callbacks: {}
                }
            },
        };
        if (Routing.getInstance()) {
            config.options.tooltips.callbacks = {
                label(item) {
                    if (item && item.index) {
                        Routing.getInstance().setTempPoint(item.index);
                    }
                    return "";//item.value;
                }
            };
        }
        return config;
    }

    protected clearChart(): void {
        this.getChartContext().clearRect(0, 0, this.getCanvasSize().width, this.getCanvasSize().height);
    }

    protected getAsBase64PNGImage(imageType = "image/png"): string {
        return this.getElement().toDataURL(imageType);
    }

    protected getElementStyleSize(): ICanvasSize {
        return {
            height: parseInt(this.getElement().style.height, 10),
            width: parseInt(this.getElement().style.width, 10),
        };
    }

    protected setElementStyleSize(size: ICanvasSize, measure: string = "px"): void {
        if (!size) {
            return;
        }
        this.getElement().style.width = size.width + measure;
        this.getElement().style.height = size.height + measure;
    }

    protected createChartLinearGradient(
        ctx: CanvasRenderingContext2D,
        gradientColorStops: IGradientColorOffset[]): CanvasGradient {
        const gradient = ctx.createLinearGradient(0, 0, 0, this.getCanvasSize().height);
        gradientColorStops.forEach((colorstop: IGradientColorOffset) => {
            gradient.addColorStop(colorstop.offset, colorstop.color);
        });
        return gradient;
    }

    protected getCanvasSize(): ICanvasSize {
        const ctx = this.getChartContext();
        return {width: ctx.canvas.width, height: ctx.canvas.height};
    }

    protected verticalLineHoverPlugin(chart: any, options: any) {
        return {
            title: "verticalLineHover",
            afterDraw(chart, options) {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const activePoint = chart.controller.tooltip._active[0];
                    const ctx = chart.ctx;
                    const x = activePoint.tooltipPosition().x;
                    const topY = chart.scales["y-axis-0"].top;
                    const bottomY = chart.scales["y-axis-0"].bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = "#a8cc2f";
                    ctx.stroke();
                    ctx.restore();
                }
            },
        };
    }
}
