import Geometry from "ol/geom/Geometry";
import Polygon, {fromExtent} from "ol/geom/Polygon";
import Projection from "ol/proj/Projection";
import {ProjectionLike} from "ol/proj";

export class Geom {
    protected geometry: Geometry = null;
    protected projection: Projection|ProjectionLike = null;

    constructor(geometry: Geometry, projection: Projection|ProjectionLike) {
        this.geometry = geometry;
        this.projection = projection;
    }

    public getGeom(): Geometry {
        return this.geometry;
    }

    public getProj(): Projection|ProjectionLike {
        return this.projection;
    }

    public getExtent(projection: Projection|ProjectionLike): [number, number, number, number] {
        if (this.projection !== projection) {
            // const g: geom.Geometry = (this.geometry as any).clone();
            // const t: geom.Geometry = g.transform(this.projection, projection)
            // return t.getExtent();
            return (this.geometry as any).clone().transform(this.projection, projection).getExtent();
        } else {
            return (this.geometry as any).clone().getExtent();
        }
    }

    public getPolygonForExtent(projection: Projection|ProjectionLike) {
        return fromExtent(this.getExtent(projection));
    }
}
