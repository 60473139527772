import {IMapPrintJob} from "./IMapPrintJob";
import {DMap} from "../DMap";
import {ILayerPrintOptions} from "./ILayerPrintOptions";
import {SourceType} from "../source/ASource";
import {IWmsPrintOptions} from "./IWmsPrintOptions";
import {IVectorPrintOptions} from "./IVectorPrintOptions";
import {Routing} from "../routing/Routing";
import {IPrintProperties} from "./IPrintProperties";
import {DownloadAnchor} from "../../common/DownloadAnchor";
import {Translation} from "../../Translation";
import {HTTP_METHOD, HttpClient} from "../../client/service/HttpClient";
import {IDrivingInstructions} from "./IDrivingInstructions";
import {Eu} from "../../client/service/Eu";
import {RoutePlanner} from "../../route/RoutePlanner";

export enum PrintType {
    route = "route",
    map = "map",
}

export class MapPrint {

    /**
     * Creates a singleton instance
     * @param dmap
     * @param options
     */
    public static create(dmap: DMap, options: { [key: string]: any }) {
        if (MapPrint.instance === null) {
            MapPrint.instance = new MapPrint(dmap, options);
        }
        return MapPrint.instance;
    }

    /**
     * Gets the existing singleton instance
     */
    public static getInstance(): MapPrint | null {
        return MapPrint.instance;
    }

    private static instance: MapPrint = null;

    protected dmap: DMap;
    private options: any;
    private origSize: any;
    private printUrlPdf: string;
    private printUrlOptions: string;
    private env: string;

    private constructor(dmap: DMap, options: { [key: string]: any }) {
        this.options = options;
        this.dmap = dmap;
        this.printUrlPdf = options.printUrlPdf;
        this.printUrlOptions = options.printUrlOptions;
        this.env = options.env;
        this.loadInfo()
            .then((data: any) => {
                this.origSize = data;
                this.initEvents();
            })
            .catch((errors) => {
                console.error(errors);
            });
    }

    private initEvents() {
        const elm = document.getElementById(this.options.target.select);
        let selected = true;
        for (const template of this.options.templates) {
            const option = Eu.create("option", [], {value: template.name}, Translation.getInstance().trans(template.title));
            if (selected) {
                option.setAttribute("selected", null);
            }
            elm.append(option);
            selected = false;
        }
        document.getElementById(this.options.target.button).addEventListener("click", (e: MouseEvent) => {
            this.print((document.getElementById(this.options.target.select) as HTMLSelectElement).value);
        });
    }

    /**
     * Gets the hoehenprofil size
     * @param templateName
     */
    public getHoehenprofilSize(templateName: string): { width: number, height: number } {
        const t = this.origSize[templateName];
        if (t && t.hoehenprofil && t.hoehenprofil.width > 0 && t.hoehenprofil.height > 0) {
            return {width: t.hoehenprofil.width, height: t.hoehenprofil.height};
        }
        return null;
    }

    /**
     * Prints a print job
     * @param templateName
     */
    public print(templateName: string) {
        this.calculatePrintOptions(templateName);
    }

    /**
     * Identifies layers for print
     * @param extent
     */
    private identifyLayers(extent: number[]): ILayerPrintOptions[] {
        let layers: ILayerPrintOptions[] = [];
        const list = this.dmap.listLayers();
        let num = 0;
        for (const item of list) {
            const names = item.getSourceState().getRealVisibles();
            if (names.length > 0 && item.getType() === SourceType.wms) {
                const layeropts: IWmsPrintOptions = item.getPrintOptions(extent) as IWmsPrintOptions;
                layeropts.order = num;
                layers.push(layeropts);
                num++;
            } else if (item.getType() === SourceType.vector && item.getUuid() === "pois") {
                const layeropts: IVectorPrintOptions = item.getPrintOptions(extent) as IVectorPrintOptions;
                if (layeropts !== null) {
                    layers.push(layeropts);
                    num++;
                }
            }
        }
        const routing = Routing.getInstance();
        if (routing) {
            const routingLayers: IVectorPrintOptions[] = routing.getPrintLayers(extent);
            if (routingLayers.length > 0) {
                layers = layers.concat(routingLayers);
            }
        }
        return layers;
    }

    /**
     * Calculates the print options
     * @param templateName
     */
    private calculatePrintOptions(templateName: string) {
        const routePlanner = RoutePlanner.getInstance();
        const canvasSize = this.getHoehenprofilSize(templateName);
        const imgBase64 = routePlanner.getElevationProfile() ?
            routePlanner.getElevationProfile().getAsBase64resizedImageForPDFPrint(templateName, canvasSize) : null;

        const templateObj = this.origSize[templateName];
        const imageSizePx: { height: number, width: number } = this.getPxImageSize(templateObj);
        let realExtent: { height: number, width: number };
        let realCenter: { x: number; y: number };
        let hoehprof: string = "";

        const routing: Routing = Routing.getInstance();
        if (routing && routing.getRouteExtent()) {
            const ex = routing.getCommonExtent();
            realExtent = {
                height: (ex[3] - ex[1]) * 1.1,
                width: (ex[2] - ex[0]) * 1.1,
            };
            realCenter = {
                x: (ex[0] + ex[2]) / 2,
                y: (ex[1] + ex[3]) / 2,
            };
            hoehprof = "legendpage_image.png";
        } else {
            const mapExtTmp = this.dmap.getPaddedExtent();
            realExtent = {
                height: mapExtTmp[3] - mapExtTmp[1],
                width: mapExtTmp[2] - mapExtTmp[0],
            };
            const centerTmp = this.dmap.getPaddedCenter();
            realCenter = {
                x: centerTmp[0],
                y: centerTmp[1],
            };
        }
        const imageK = imageSizePx.width / imageSizePx.height;
        let imgExtent: { height: number, width: number };
        // const imageSize = this.getPxImageSize();
        const realK = realExtent.width / realExtent.height;
        if (imageK < realK) {
            imgExtent = {
                height: realExtent.width / imageK, // realExtent.height,
                width: realExtent.width, // realExtent.height * imageK,
            };
        } else {
            imgExtent = {
                height: realExtent.height, // realExtent.width / imageK,
                width: realExtent.height * imageK, // realExtent.width,
            };
        }
        const ext = [
            realCenter.x - imgExtent.width / 2,
            realCenter.y - imgExtent.height / 2,
            realCenter.x + imgExtent.width / 2,
            realCenter.y - imgExtent.height / 2,
        ];
        const trans = Translation.getInstance();
        const printProps = routePlanner.getPrintProperties();
        const legendsH = routePlanner.getLegends();
        const legends = legendsH ? [legendsH] : [[]];
        const pj: IMapPrintJob = {
            center: realCenter,
            extent: imgExtent,
            height: Math.round(imageSizePx.height),
            hoehenprofil: hoehprof,
            image: imgBase64,
            layers: this.identifyLayers(ext), // layersOptions,
            extra: printProps as IPrintProperties,
            // mapDpi: 90.714,
            quality: this.options.quality,
            rotation: this.options.rotation,
            scale_select: Math.round(imgExtent.width / (templateObj.map.width / 100)),
            template: templateName,
            width: Math.round(imageSizePx.width),
            legends: legends
        };
        const copyright = [];
        for (const layerOpt of pj.layers) {
            if (layerOpt.attribution) {
                for (const attr of layerOpt.attribution) {
                    if (copyright.indexOf(attr.title) === -1) {
                        copyright.push(attr.title);
                    }
                }
                delete layerOpt.attribution;
            }
        }
        printProps.datasource = trans.trans("print_template_datasource_label") + ": " + copyright.join(", ");
        if (pj.extra.route_logo) {
            if (pj.extra.route_logo.length > 0) {
                pj.route_logo_2 = {
                    url: pj.extra.route_logo[pj.extra.route_logo.length - 1]
                };
            }
            if (pj.extra.route_logo.length > 1) {
                pj.route_logo_1 = {
                    url: pj.extra.route_logo[pj.extra.route_logo.length - 2]
                };
            }
            delete pj.extra.route_logo;
        }

        if (this.options.driving_instructions && this.options.driving_instructions.templates
            && this.options.driving_instructions.templates.indexOf(templateName) !== -1
            && pj.extra.route_instructions && pj.extra.route_instructions.printurl) {
            const url = pj.extra.route_instructions.printurl;
            const events = this.dmap.getMapActivity().getEvents("print");
            HttpClient.sendData(url, null, HTTP_METHOD.GET, "json", events)
                .then((data: any) => {
                    const instr: IDrivingInstructions = data;
                    delete pj.extra.route_instructions;
                    pj.route_instructions = instr;
                    this.downloadPdf(pj);
                })
                .catch((errors) => {
                });
        } else {
            this.downloadPdf(pj);
        }
    }

    /**
     * Gets the image size for a given template
     * @param templateObj
     */
    private getPxImageSize(templateObj: any): { height: number, width: number } {
        return {
            height: Math.round(templateObj.map.height * this.options.quality / 2.54),
            width: Math.round(templateObj.map.width * this.options.quality / 2.54),
        };
    }

    /**
     * Downloads a created pdf
     * @param printJob
     */
    private downloadPdf(printJob: IMapPrintJob): void {
        const params = {
            data: printJob,
            template: printJob.template,
            quality: "" + printJob.quality,
            scale_select: "" + printJob.scale_select,
            rotation: "" + printJob.rotation
        };
        new DownloadAnchor()
            .create(this.printUrlPdf)
            .addFields(params)
            .submit();
    }

    /**
     * Loads print information
     */
    private loadInfo() {
        const tmpls = [];
        for (const tmpl of this.options.templates) {
            tmpls.push(tmpl.name);
        }
        let url = this.printUrlOptions + "?templates=" + encodeURIComponent(tmpls.join(","));
        if (this.env === "dev") {
            url = "/tunnel?url=" + encodeURIComponent(url);
        }
        const events = this.dmap.getMapActivity().getEvents("print");
        return HttpClient.sendData(url, null, HTTP_METHOD.GET, "json", events);
    }
}
