import {ConnType} from "./ARoute";

export class RoutePoint {
    private coordinates: number[];
    private title: string;
    private station: string;
    private type: ConnType;
    private connection: ConnType;

    public constructor(point: number[], label: string, type: ConnType, connection: ConnType, station: string) {
        this.coordinates = point;
        this.title = label;
        this.type = type;
        this.connection = connection;
        this.station = station;
    }

    public resetValue() {
        this.coordinates = null;
        this.title = null;
        this.station = null;
    }

    public getCoordinates() {
        return this.coordinates;
    }

    public setCoordinates(point: number[]) {
        this.coordinates = point;
    }

    public getTitle() {
        return this.title;
    }

    public setTitle(title:string) {
        this.title = title;
    }

    public getConnection() {
        return this.connection;
    }

    public setConnection(connection: ConnType) {
        this.connection = connection;
    }

    public getType() {
        return this.type;
    }

    public setType(type: ConnType) {
        this.type = type;
    }

    public getStation() {
        return this.station;
    }

    public setStation(station: string) {
        this.station = station;
    }

    public toOptions() {
        return {
            coordinates: this.coordinates,
            title: this.title,
            type: this.type,
            station: this.station,
            connection: this.connection
        }
    }

    public static fromOptions(options: { [key: string]: any }) {
        return new RoutePoint(
            options.coordinates ? options.coordinates : null,
            options.title ? options.title : null,
            options.type ? options.type : (options.station ? ConnType.bus : ConnType.bicycle),
            options.connection ? options.connection : ConnType.bicycle, //(options.station ? ConnType.bus : ConnType.bicycle),
            options.station ? options.station : null,
        );
    }
}
