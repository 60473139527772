import {IRouteComponents} from "./IRouteComponents";
import {ARoute} from "./ARoute";
import {RoutePlanner} from "./RoutePlanner";
import {IRouteProperties} from "./IRouteProperties";
import {Translation} from "../Translation";
import {Eu} from "../client/service/Eu";

export class RouteComponents {
    private static instance: RouteComponents;

    public static create(options: IRouteComponents, planner: RoutePlanner) {
        if (!RouteComponents.instance) {
            RouteComponents.instance = new RouteComponents(options, planner);
        }
        return RouteComponents.instance;
    }

    private options: IRouteComponents;
    private planner: RoutePlanner;

    private constructor(options: IRouteComponents, planner: RoutePlanner) {
        this.options = options;
        this.planner = planner;
        this.initEvents();
    }

    private initEvents() {
        this.initReverseRouteEvent();
        this.initShare();
        this.initDownload();
    }

    private initReverseRouteEvent() {
        if (!this.options.reverseRouteTarget || !this.options.reverseRouteTarget) {
            return;
        }
        const elm = document.getElementById(this.options.reverseRouteTarget);
        if (!elm) {
            return;
        }
        elm.addEventListener("click", (e: Event) => {
            this.planner.reverseRoute();
        });
    }

    private initShare() {
        if (!this.options.shareRoute || !this.options.shareRoute.button) {
            return;
        }
        const elm = document.getElementById(this.options.shareRoute.button);
        if (!elm) {
            return;
        }
        elm.addEventListener("click", (e: Event) => {
            if (elm.getAttribute('aria-expanded') !== "true") {
                // console .log(e);
                const input: HTMLInputElement = (document.getElementById(this.options.shareRoute.input) as HTMLInputElement);
                this.planner.getShareLink()
                    .then((url: string) => {
                        input.value = url;
                })
                .catch((er: string) => {
                    input.value = er;
                })

            }
            // todo set to input focusout: to hide the expanded aria with it.
            e.preventDefault();
        });
    }

    private initDownload() {
        if (!this.options.download || !this.options.download.button) {
            return;
        }
        const elm = document.getElementById(this.options.download.button);
        if (!elm) {
            return;
        }
        elm.addEventListener("click", (e: Event) => {
            if (elm.getAttribute("aria-expanded") === "true") {
                (document.getElementById('confirm-term1') as HTMLInputElement).checked = false;
                (document.getElementById('confirm-term2') as HTMLInputElement).checked = false;
                document.querySelector(".--js-download-confirm").classList.remove("hidden");
                document.querySelector(".--js-download-links").classList.remove("hidden");
            }
        });
        Eu.eventAdd("change", "#confirm-term1,#confirm-term2", (e: Event) => {
            if ((document.getElementById('confirm-term1') as HTMLInputElement).checked === true
                && (document.getElementById('confirm-term2') as HTMLInputElement).checked === true) {
                document.querySelector(".--js-download-confirm").classList.add("hidden");
                document.querySelector(".--js-download-links").classList.remove("hidden");
            }
        });
    }

    private setDownload(route: ARoute) {
        if (!this.options.download || !this.options.download.button) {
            return;
        }
        const croute: IRouteProperties = route.getCalculated();
        const visible = !(croute.totalDistanceValue === null || croute.totalDistanceValue === 0);
        const button = document.getElementById(this.options.download.button);
        if (button) {
            this.setElementVisible(button.closest(".--js-downloads"), visible);
        }
        const inputId = document.getElementById("route-download-id") as HTMLInputElement;
        if (inputId) {
            inputId.value = route.getCalculated().id;
        }
        const inputType = document.getElementById("route-download-type") as HTMLInputElement;
        if (inputType) {
            inputType.value = route.getCalculated().type;
        }
    }

    private setInstructions(route: ARoute) {
        if (!this.options.instructionsTarget || !this.options.instructionsTarget) {
            return;
        }
        let elm = document.getElementById(this.options.instructionsTarget);
        if (!elm) {
            return;
        }
        (elm as any).href = route.getCalculated().instructions.url;
    }

    private setSteckbrief(route: ARoute) {
        if (!this.options.steckbriefTarget || !this.options.steckbriefTarget) {
            return;
        }
        const elm = document.getElementById(this.options.steckbriefTarget);
        if (!elm) {
            return;
        }
        (elm as any).href = route.getCalculated().steckbrief;
    }

    public setFromRoute(route: ARoute) {
        this.setDownload(route);
        this.setInstructions(route);
        this.setSteckbrief(route);
        this.setRouteProperties(route);
    }

    public showAdditionalInformation(show: boolean) {
        let elm = document.getElementById("additional-information") as HTMLElement;
        if (!elm) {
            return;
        }
        if (show) {
            elm.classList.remove(...["hide"]);
        } else {
            elm.classList.add(...["hide"]);
        }
    }

    private setRouteProperties(route: ARoute) {
        if (!this.options.properties) {
            return;
        }
        const croute: IRouteProperties = route.getCalculated();
        // cyclepath
        let elm = document.getElementById(this.options.properties.description) as HTMLElement;
        if (elm) {
            elm.textContent = croute.description;
        }
        // cyclepath
        elm = document.getElementById(this.options.properties.title) as HTMLElement;
        if (elm) {
            elm.textContent = croute.name;
        }
        // cyclepath
        elm = document.getElementById(this.options.properties.other) as HTMLElement;
        if (elm) {
            let rows = [];
            const t = Translation.getInstance();
            const row = (transKey: string, value: string | number) => {
                return `${t.trans(transKey)}: ${value}`;
            };
            if (Math.round(croute.totalDistanceValue) > 0) {
                rows.push(row("cyclepath.index.distance", Math.round(croute.totalDistanceValue) + " " + croute.totalDistanceUom));
            }
            if (croute.charakter) {
                rows.push(row("cyclepath.index.charakter", croute.charakter.join(", ")));
            }
            rows.push(row("cyclepath.index.start", croute.start));
            rows.push(row("cyclepath.index.destination", croute.end));
            if (croute.subroutes.length === 0) {
                rows.push(`${t.trans("cyclepath.index.up_and_down")}
                : ${croute.heightUp} ${croute.heightUom} / ${croute.heightDown} ${croute.heightUom}`);
            }
            elm.innerHTML = rows.join("<br>");
        }
        // routeplanner
        elm = document.getElementById(this.options.properties.distance) as HTMLElement;
        if (elm) {
            const visible = !(croute.totalDistanceValue === null || croute.totalDistanceValue === 0);
            this.setElementVisible(elm.closest(".--js-route-information"), visible);
            elm.textContent = Math.round(croute.totalDistanceValue) + " " + croute.totalDistanceUom;
        }
        // routeplanner
        elm = document.getElementById(this.options.properties.ascending) as HTMLElement;
        if (elm) {
            elm.textContent = Math.round(croute.heightUp) + " " + croute.heightUom;
        }
        // routeplanner
        elm = document.getElementById(this.options.properties.descending) as HTMLElement;
        if (elm) {
            elm.textContent = Math.round(croute.heightDown) + " " + croute.heightUom;
        }
    }

    private setElementVisible(elm: HTMLElement, visible: boolean) {
        if (!elm) {
            return;
        }
        if (!visible) {
            if (!elm.classList.contains("hide")) {
                elm.classList.add("hide");
            }
        } else {
            elm.classList.remove("hide");
        }
    }
}
