export class SourceState {
    private scale: number;
    private names: string[];
    private realVisibles: string[];
    private visibles: string[];
    private infoables: string[];

    public constructor() {
        this.names = [];
        this.visibles = [];
        this.realVisibles = [];
        this.infoables = [];
    }

    public addName(value: string) {
        if (this.names.indexOf(value) === -1) {
            this.names.push(value);
        }
    }

    public getNames(): string[] {
        return this.names;
    }

    public setNames(value: string[]) {
        this.names = value;
    }

    public getScale(): number {
        return this.scale;
    }

    public setScale(value: number) {
        this.scale = value;
    }

    public addRealVisible(name: string) {
        if (this.realVisibles.indexOf(name) === -1) {
            this.realVisibles.push(name);
        }
    }

    public getRealVisibles(): string[] {
        return this.realVisibles;
    }

    public setRealVisibles(value: string[]) {
        this.realVisibles = value;
    }

    public addVisible(name: string) {
        if (this.visibles.indexOf(name) === -1) {
            this.visibles.push(name);
        }
    }

    public getVisibles(): string[] {
        return this.visibles;
    }

    public setVisibles(value: string[]) {
        this.visibles = value;
    }

    public addInfoable(name: string): void {
        if (this.infoables.indexOf(name) === -1) {
            this.infoables.push(name);
        }
    }

    public getInfoables(): string[] {
        return this.infoables;
    }

    public setInfoables(value: string[]) {
        this.infoables = value;
    }
}
