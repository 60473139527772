import {DMap} from "../DMap";

import {MapEventType} from "../MapEventObserver";
import {IFeatureEventSubscriber} from "../IFeatureEventSubscriber";
import Feature from "ol/Feature";
import {Vector} from "ol/layer";

export abstract class AMultiFeatureEventHandler implements IFeatureEventSubscriber {
    protected dmap: DMap;
    protected mapEvent: MapEventType;
    protected eventFeatures: Map<Vector, Set<Feature>>;

    /**
     * Creates an instance
     * @param dmap
     * @param styles
     */
    public constructor(dmap: DMap, mapEvent: MapEventType) {
        this.dmap = dmap;
        this.mapEvent = mapEvent;
        this.eventFeatures = new Map<Vector, Set<Feature>>();
    }

    /**
     * Sets a DMap instance
     * @param dmap
     */
    public setMap(dmap: DMap): void {
        this.dmap = dmap;
    }

    /**
     * Checks if a layer is registrated
     * @param layer
     */
    public hasLayer(layer: Vector): boolean {
        return this.eventFeatures.has(layer);
    }

    /**
     * Registers a vector layer at an interaction layer
     * @param layer
     * @param interactionStyle
     */
    public register(layer: Vector) {
        if (!this.eventFeatures.has(layer)) {
            if (this.eventFeatures.size === 0) {
                this.dmap.subscribeFeatureEvent(this.mapEvent, this);
            }
            this.eventFeatures.set(layer, new Set<Feature>());
        }
    }

    /**
     * Unregisters a vector layer at an interaction layer
     * @param layer
     */
    public unregister(layer: Vector) {
        if (this.eventFeatures.has(layer)) {
            this.eventFeatures.delete(layer);
            if (this.eventFeatures.size === 0) {
                this.dmap.unsubscribeFeatureEvent(this.mapEvent, this);
            }
        }
    }

    /**
     * Called on event start
     */
    public eventStart(): void {
        this.clearEventFeatures();
    }

    /**
     * Called on each feature by event
     * @param layer
     * @param feature
     */
    public addByEvent(layer: Vector, feature: Feature): void {
        this.eventFeatures.get(layer).add(feature);
    }

    /**
     * Called on event end
     * @param coordinate
     */
    public eventEnd(coordinate: [number, number]): void {
        this.clearEventFeatures();
    }

    /**
     * Clears all features in eventFeatures
     */
    protected clearEventFeatures() {
        for (const layer of Array.from(this.eventFeatures.keys())) {
            this.eventFeatures.get(layer).clear();
        }
    }
}
