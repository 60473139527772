import {AItem} from "./AItem";

export class Item extends AItem {

    protected parent: AItem = null;

    public itemsCount(): number {
        return 0;
    }

    public getParent() {
        return this.parent;
    }

    public setParent(parent: AItem) {
        this.parent = parent;
    }
}
