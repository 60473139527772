import {IMbLegend} from "./IMbLegend";
import {RoutePlanner} from "./RoutePlanner";
import {route_type} from "./ARoute";
import {IRouteSet} from "./IRouteSet";

export class RouteLegends {
    private target: HTMLElement;
    private legends: { [key: string]: IRouteSet };
    private current: string;
    private routeplanner: RoutePlanner;

    public constructor(target: HTMLElement, legends: { [key: string]: IRouteSet }, routeplanner: RoutePlanner = null) {
        this.target = target;
        this.legends = legends;
        this.routeplanner = routeplanner;
        this.initEvents();
    }

    private getNext(next: boolean = false) {
        for (const name of Object.keys(this.legends)) {
            if (name === this.current) {
                next = true;
            } else if (next) {
                return name;
            }
        }
        return this.getNext(true);
    }

    public setVisible(visible: boolean) {
        const elm = this.target.closest(".--js-route-legends");
        if (!elm) {
            return;
        }
        if (!visible) {
            elm.classList.add("hide");
        } else {
            elm.classList.remove("hide");
        }

    }

    private initEvents() {
        const switchLegend: () => void = () => {
            const active = this.target.querySelector(".active");
            if (active) {
                active.classList.remove("active");
            }
            const next = this.getNext();
            this.current = next;
            this.target.querySelector('[data-id="' + next + '"]').classList.add("active");
            if (this.routeplanner) {
                this.routeplanner.setRouteView(route_type[this.current]);
            }
        };
        if (!this.current) {
            switchLegend();
        }
        this.target.addEventListener("click", (e: Event) => {
            switchLegend();
        });
    }

    public getLegendsTitle(): string {
        return  this.legends[this.current].title;
    }

    public getLegends(): IMbLegend[] {
        const legendSet = this.legends[this.current];
        let legends = [];
        if (legendSet && legendSet.legends) {
            for (const legend of legendSet.legends) {
                legends.push(
                    {
                        url: legend.img_abs,
                        layerName: legend.title,
                        // sourceName: item.title
                    }
                );
            }
        }
        return legends;
    }
}
