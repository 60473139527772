import {OverlayTemplate} from "./OverlayTemplate";

export class RegionTemplate extends OverlayTemplate {
    public getNames(): string[] {
        return ["file", "title", "teaser"];
    }

    public render(o: any): string {
        const text = `
            <div class="region-info">
                <div class="o-image-wrap">
                    <img class="o-image" src="${this.options.baseUrl}${this.options.path.regions}/${o.file}" />
                    <a class="ol-popup-closer"></a>
                </div>
                <p class="o-title">${o.title}</p>
                <p class="o-teaser">${o.teaser}</p>
            </div>`;
        return text;
    }
}
