import Feature from "ol/Feature";
import {Circle, Image} from "ol/style";
import AtlasManager, {Options as AtlasManagerOptions} from "ol/style/AtlasManager";
import CircleStyle, {Options as CircleOptions} from "ol/style/Circle";
import Fill, {Options as FillOptions} from "ol/style/Fill";
import Icon, {Options as IconOptions} from "ol/style/Icon";
import RegularShape, {Options as RegularShapeOptions} from "ol/style/RegularShape";
import Stroke, {Options as StrokeOptions} from "ol/style/Stroke";
import Style, {Options as StyleOptions} from "ol/style/Style";
import Text, {Options as TextOptions} from "ol/style/Text";
import TextPlacement from "ol/style/TextPlacement";
import Geometry from "ol/geom/Geometry";
import {Utils} from "./geom/Utils";
import {StyleFont} from "./StyleFont";

export class Styles {

    public static guessPropertyName(style: Style): string[] {
        // Lookahead and Lookbehind assertions are not supported in FF
        // const myRe = RegExp("(?<=\\$\{)([^\}]+)(?=\})", "g");
        const myRe = RegExp("(\\$\{[^\}]+\})", "g");
        const res = [];
        if (style.getText() && style.getText().getText().indexOf("${") !== -1) {
            const tmp = style.getText().getText();
            const pos = tmp.indexOf("${");
            if (pos !== -1) {
                let arr;
                /* tslint:disable:no-conditional-assignment */
                while ((arr = myRe.exec(tmp)) !== null) {
                    if (res.indexOf(arr[0]) === -1) {
                        // Lookahead and Lookbehind assertions are not supported in FF
                        // res.push(arr[0]);
                        res.push(Styles.nameForPattern(arr[0] as string));
                    }
                }
                /* tslint:enable:no-conditional-assignment */
            }
        }
        return res;
    }

    /**
     * Gets pattern for name
     * @param name
     */
    public static patternForName(name: string) {
        return "${" + name + "}";
    }

    /**
     * Gets pattern for name
     * @param name
     */
    public static nameForPattern(pattern: string) {
        return pattern.substr(2, pattern.length - 3);
    }

    //
    // /**
    //  * Sets z-index for given styles
    //  * @param styles
    //  * @param zIndex
    //  */
    // public static setZindex(styles: Style | Style[], zIndex: number): Style | Style[] {
    //     if (styles instanceof Array) {
    //         const styleList = [];
    //         for (const style of styles) {
    //             styleList.push(Styles.setZindex(style, zIndex));
    //         }
    //     } else {
    //         const style = (styles as Style).clone();
    //         style.setZIndex(zIndex);
    //         return style;
    //     }
    // }

    /**
     * Replaces a style text with a property value (pattern: "${" + propertyName + "}")
     * @param feature
     * @param propertyNames
     * @param styles
     */
    public static textFromFeature(feature: Feature | any,
                                  propertyNames: string[],
                                  styles: Style | Style[]): Style[] {
        if (styles instanceof Array) {
            const styleList = [];
            for (const style of styles) {
                styleList.push(Styles.textFromFeature(feature, propertyNames, style)[0]);
            }
            return styleList;
        } else if (styles instanceof Style) {
            const style = styles.clone();
            for (const propName of propertyNames) {
                const pattern = Styles.patternForName(propName);
                if (style.getText() && style.getText().getText().indexOf(pattern) !== -1) {
                    const text = style.getText().getText();
                    if (feature instanceof Feature) {
                        style.getText().setText(text.replace(pattern, feature.get(propName)));
                    } else {
                        style.getText().setText(text.replace(pattern, feature[propName]));
                    }
                }
            }
            return [style];
        }
    }

    /**
     * Gets feature styles as an array
     * @param feature
     */
    public static styleAsArray(feature: Feature): Style[] {
        return feature.getStyle() instanceof Array ? feature.getStyle() as Style[] : [feature.getStyle() as Style];
    }

    /**
     * Creates a style object from a string json representation
     * @param options
     */
    public static toStyle(options: any): Style[] {
        if (options instanceof Array) {
            const res: Style[] = [];
            for (const styleOpts of options) {
                const styles = Styles.toStyle(styleOpts);
                for (const style of styles) {
                    res.push(style);
                }
            }
            return res;
        } else {
            const styles: StyleOptions = {};
            Styles.toSimple(styles, "geometry", options);
            Styles.toFill(styles, "fill", options);
            Styles.toImage(styles, options);
            Styles.toStroke(styles, "stroke", options);
            Styles.toText(styles, options);
            Styles.toSimple(styles, "zIndex", options);
            return [new Style(styles)];
        }
    }

    /**
     * Creates a style object from a string json representation
     * @param options
     */
    public static createOptions(styles: Style | Style[]): any[] {
        if (styles instanceof Array) {
            const res: any[] = [];
            for (const style of styles) {
                const opts = Styles.createOptions(style);
                res.push(opts[0]);
            }
            return res;
        } else {
            const style: Style = styles;
            const options = {};
            if (typeof style.getGeometry() === "string" || style.getGeometry() instanceof String) {
                Styles.fromSimple(style.getGeometry(), "geometry", options);
            } else if (style.getGeometry() instanceof Geometry) {
                window.console.warn("not yet supported");
            }
            Styles.fromFill(style.getFill(), options);
            Styles.fromImage(style.getImage(), options);
            Styles.fromStroke(style.getStroke(), options);
            Styles.fromText(style.getText(), options);
            Styles.fromSimple(style.getZIndex(), "zIndex", options);
            return [options];
        }
    }

    private static toAtlasManager(style: any, options: any): void {
        if (options.atlasManager) {
            const opts = options.atlasManager;
            const tmp: AtlasManagerOptions = {};
            Styles.toSimple(tmp, "initialSize", opts);
            Styles.toSimple(tmp, "maxSize", opts);
            Styles.toSimple(tmp, "space", opts);
            style.atlasManager = new AtlasManager(tmp);
        }
    }

    private static toImage(style: any, options: any): void {
        if (options.image) {
            style.image = Styles.getIcon(options.image);
            if (!style.image) {
                style.image = Styles.getCircle(options.image);
            }
            if (!style.image) {
                style.image = Styles.getRegularShape(options.image);
            }
        }
    }

    private static getIcon(options: any): Icon {
        if (options.icon) {
            const opts = options.icon;
            const tmp: IconOptions = {};
            Styles.toSimple(tmp, "anchor", opts);
            Styles.toSimple(tmp, "anchorOrigin", opts);
            Styles.toSimple(tmp, "anchorXUnits", opts);
            Styles.toSimple(tmp, "anchorYUnits", opts);
            Styles.toSimple(tmp, "color", opts);
            Styles.toSimple(tmp, "crossOrigin", opts);
            Styles.toSimple(tmp, "img", opts);
            Styles.toSimple(tmp, "offset", opts);
            Styles.toSimple(tmp, "offsetOrigin", opts);
            Styles.toSimple(tmp, "opacity", opts);
            Styles.toSimple(tmp, "scale", opts);
            Styles.toSimple(tmp, "rotateWithView", opts);
            Styles.toSimple(tmp, "rotation", opts);
            Styles.toSimple(tmp, "size", opts);
            Styles.toSimple(tmp, "imgSize", opts);
            if (opts.src) {
                tmp.src = Utils.pathToUri(opts.src);
            }
            return new Icon(tmp);
        } else {
            return null;
        }
    }

    private static getRegularShape(options: any): RegularShape {
        if (options.regularShape) {
            const opts = options.regularShape;
            const tmp: RegularShapeOptions = {
                points: Styles.getValue("points", opts),
            };
            Styles.toFill(tmp, "fill", opts);
            Styles.toStroke(tmp, "stroke", opts);
            Styles.toSimple(tmp, "radius", opts);
            Styles.toSimple(tmp, "radius1", opts);
            Styles.toSimple(tmp, "radius2", opts);
            Styles.toSimple(tmp, "angle", opts);
            Styles.toSimple(tmp, "rotation", opts);
            Styles.toSimple(tmp, "rotateWithView", opts);
            // Styles.addAtlasManager(style, opts);
            return new RegularShape(tmp);
        } else {
            return null;
        }
    }

    private static getCircle(options: any): Circle {
        if (options.circle) {
            const opts = options.circle;
            const tmp: CircleOptions = {
                radius: Styles.getValue("radius", opts),
            };
            Styles.toFill(tmp, "fill", opts);
            Styles.toStroke(tmp, "stroke", opts);
            // Styles.addAtlasManager(style, opts);
            return new Circle(tmp);
        } else {
            return null;
        }
    }

    private static toText(styles: any, options: any): void {
        if (options.text) {
            const opts = options.text;
            const tmp: TextOptions = {};
            if (opts.font && opts.font.split(" ").length > 1) {
                const font = StyleFont.parseToValid(opts.font);
                if (font) {
                    tmp.font = font;
                }
            }
            Styles.toSimple(tmp, "offsetX", opts);
            Styles.toSimple(tmp, "offsetY", opts);
            Styles.toSimple(tmp, "scale", opts);
            Styles.toSimple(tmp, "rotateWithView", opts);
            Styles.toSimple(tmp, "rotation", opts);
            Styles.toSimple(tmp, "text", opts);
            Styles.toSimple(tmp, "textBaseline", opts);
            Styles.toSimple(tmp, "padding", opts);
            if (opts.placement) {
                if (opts.placement === TextPlacement.LINE) {
                    tmp.placement = TextPlacement.LINE;
                } else if (opts.placement === TextPlacement.POINT) {
                    tmp.placement = TextPlacement.POINT;
                }
            }
            Styles.toFill(tmp, "fill", opts);
            Styles.toStroke(tmp, "stroke", opts);
            if (tmp.placement) {
                if (tmp.placement === TextPlacement.LINE) {
                    Styles.toStroke(tmp, "maxAngle", opts);
                } else if (tmp.placement === TextPlacement.POINT) {
                    Styles.toFill(tmp, "backgroundFill", opts);
                    Styles.toStroke(tmp, "backgroundStroke", opts);
                    Styles.toSimple(tmp, "textAlign", opts);
                }
            }
            styles.text = new Text(tmp);
        }
    }

    private static toFill(style: any, key: string, options: any): void {
        if (options[key]) {
            const opts = options[key];
            const tmp: FillOptions = {};
            Styles.toSimple(tmp, "color", opts);
            style[key] = new Fill(tmp);
        }
    }

    private static toStroke(style: any, key: string, options: any): void {
        if (options[key]) {
            const opts = options[key];
            const tmp: StrokeOptions = {};
            Styles.toSimple(tmp, "color", opts);
            Styles.toSimple(tmp, "lineCap", opts);
            Styles.toSimple(tmp, "lineJoin", opts);
            Styles.toSimple(tmp, "lineDash", opts);
            Styles.toSimple(tmp, "lineDashOffset", opts);
            Styles.toSimple(tmp, "miterLimit", opts);
            Styles.toSimple(tmp, "width", opts);
            style[key] = new Stroke(tmp);
        }
    }

    private static toSimple(style: any, key: string, options: any): void {
        if (options[key]) {
            style[key] = options[key];
        }
    }

    private static getValue(key: string, options: any): any {
        return options[key] ? options[key] : null;
    }

    private static fromImage(image: Image, opts: any): void {
        if (image) {
            opts.image = {};
            if (image instanceof CircleStyle) {
                Styles.fromCircle(image, opts.image);
            } else if (image instanceof Icon) { // TODO ilse if  or only if
                Styles.fromIcon(image, opts.image);
            } else if (image instanceof RegularShape) { // TODO ilse if  or only if
                Styles.fromRegularShape(image, opts.image);
            }
        }
    }

    private static fromCircle(circle: CircleStyle, opts: any): void {
        if (circle) {
            opts.circle = {};
            Styles.fromSimple(circle.getRadius(), "radius", opts.circle);
            Styles.fromFill(circle.getFill(), opts.circle);
            Styles.fromStroke(circle.getStroke(), opts.circle);
            // TODO fromAtlasManager Styles.fromAtlasManager(style, opts);
        }
    }

    private static fromIcon(icon: Icon, opts: any): void {
        if (icon) {
            opts.icon = {};
            Styles.fromSimple(icon.getAnchor(), "anchor", opts.icon);
            Styles.fromSimple(icon.getColor(), "color", opts.icon);
            Styles.fromSimple(icon.getOpacity(), "opacity", opts.icon);
            Styles.fromSimple(icon.getScale(), "scale", opts.icon);
            Styles.fromSimple(icon.getRotateWithView(), "rotateWithView", opts.icon);
            Styles.fromSimple(icon.getRotation(), "rotation", opts.icon);
            Styles.fromSimple(icon.getSize(), "size", opts.icon);
            Styles.fromSimple(icon.getImageSize(), "imgSize", opts.icon);
            Styles.fromSimple(icon.getSrc(), "src", opts.icon);
            // TODO options ??? :
            // Styles.fromSimple(icon.getOrigin(), "anchorOrigin",  opts.icon);
            // Styles.fromSimple(icon.getOrigin(), "crossOrigin",  opts.icon);
            // Styles.fromSimple(icon.get(), "anchorXUnits",  opts.icon);
            // Styles.fromSimple(icon.get(), "anchorYUnits",  opts.icon);
            // Styles.fromSimple(icon.getOrigin(), "offset",  opts.icon);
            // Styles.fromSimple(icon.get(), "offsetOrigin",  opts.icon);
        }
    }

    private static fromRegularShape(shape: RegularShape, optsa: any): void {
        if (shape) {
            optsa.regularShape = {};
            const opts = optsa.regularShape;
            Styles.fromFill(shape.getFill(), "fill", opts);
            Styles.fromStroke(shape.getStroke(), "stroke", opts);
            Styles.fromSimple(shape.getRadius(), "radius", opts);
            // Styles.fromSimple(shape.getRadius, "radius1", opts);
            Styles.fromSimple(shape.getRadius2(), "radius2", opts);
            Styles.fromSimple(shape.getAngle(), "angle", opts);
            Styles.fromSimple(shape.getRotation(), "rotation", opts);
            Styles.fromSimple(shape.getRotateWithView(), "rotateWithView", opts);
        }
    }

    private static fromText(text: Text, opts: any): void {
        if (text) {
            opts.text = {};
            // TODO ??? set font as CSS 'font', default: "10px sans-serif"
            Styles.fromSimple(text.getFont(), "font", opts.text);
            Styles.fromSimple(text.getOffsetX(), "offsetX", opts.text);
            Styles.fromSimple(text.getOffsetY(), "offsetY", opts.text);
            Styles.fromSimple(text.getScale(), "scale", opts.text);
            Styles.fromSimple(text.getRotateWithView(), "rotateWithView", opts.text);
            Styles.fromSimple(text.getRotation(), "rotation", opts.text);
            Styles.fromSimple(text.getText(), "text", opts.text);
            Styles.fromSimple(text.getTextBaseline(), "textBaseline", opts.text);
            Styles.fromSimple(text.getPadding(), "padding", opts.text);
            if (text.getPlacement()) {
                if (text.getPlacement() === TextPlacement.LINE) {
                    // opts.text.placement = "line";
                    Styles.fromSimple(text.getPlacement(), "placement", opts.text);
                    Styles.fromSimple(text.getMaxAngle(), "maxAngle", opts.text);
                } else if (opts.placement === TextPlacement.POINT) {
                    Styles.fromSimple(text.getPlacement(), "placement", opts.text);
                    Styles.fromFill(text.getBackgroundFill(), opts.text, "backgroundFill");
                    Styles.fromStroke(text.getBackgroundStroke(), opts.text, "backgroundStroke");
                    Styles.fromSimple(text.getTextAlign(), "textAlign", opts.text);
                } else {
                    Styles.fromSimple(text.getPlacement(), "placement", opts.text);
                }
            }
            Styles.fromFill(text.getFill(), opts.text);
            Styles.fromStroke(text.getStroke(), opts.text);
        }
    }

    private static fromStroke(stroke: Stroke, opts: any, key: string = "stroke"): void {
        if (stroke) {
            opts[key] = {};
            // const stroke = style.getStroke();
            if (stroke.getColor()) {
                opts[key].color = stroke.getColor().toString();
            }
            Styles.fromSimple(stroke.getLineCap(), "lineCap", opts[key]);
            Styles.fromSimple(stroke.getLineJoin(), "lineJoin", opts[key]);
            Styles.fromSimple(stroke.getLineDash(), "lineDash", opts[key]);
            Styles.fromSimple(stroke.getLineDashOffset(), "lineDashOffset", opts[key]);
            Styles.fromSimple(stroke.getMiterLimit(), "miterLimit", opts[key]);
            Styles.fromSimple(stroke.getWidth(), "width", opts[key]);
        }
    }

    private static fromFill(fill: Fill, opts: any, key: string = "fill"): void {
        if (fill && fill.getColor()) {
            opts[key] = {color: fill.getColor().toString()};
        }
    }

    private static fromSimple(value: any, key: string, options: any) {
        if (value !== undefined && value !== null) {
            options[key] = value;
        }
    }
}
