import {IRouteFormOptions} from "./IRouteFormOptions";
import {UserRouteOptions} from "./UserRouteOptions";
import {Translation} from "../Translation";
import {Utils} from "../common/Utils";
import {HTTP_METHOD, HttpClient} from "../client/service/HttpClient";
import {RoutePlanner} from "./RoutePlanner";
import {RoutePoint} from "./RoutePoint";
import {PointType} from "../map/routing/IRoutePoint";
import Sortable from "sortablejs";
import {ConnType} from "./ARoute";
import {IUserRouteOptions} from "./IUserRouteOptions";

export class RouteForm {

    private static instance: RouteForm;

    public static create(options: IRouteFormOptions) {
        if (!RouteForm.instance) {
            RouteForm.instance = new RouteForm(options);
        }
        return RouteForm.instance;
    }

    public static getInstance() {
        return RouteForm.instance;
    }

    private userRouteOptions: UserRouteOptions;
    private target: HTMLElement;
    private datepicker: any;
    private timepicker: any;
    private typing: { delay: number; timer: number; };
    private addressUrl: string;

    private constructor(options: IRouteFormOptions) {
        this.userRouteOptions = new UserRouteOptions(options.userroute.traffic_type);
        this.target = document.getElementById(options.target) as HTMLElement;
        this.addressUrl = options.addressUrl;
        this.initPicker();
        this.initSortable();
        this.initForm();
    }

    public setRouteOptions(options: IUserRouteOptions) {
        this.userRouteOptions.setOptions(options);
    }

    private initPicker() {
        this.typing = {
            delay: 350,
            timer: -1
        };
        this.datepicker = ($('#date') as any).Zebra_DatePicker({
            format: 'd.m.Y',
            show_icon: false,
            show_clear_date: false,
            show_select_today: false,
            default_position: 'below',
            offset: [-200, 0],
            readonly_element: true,
            disable_time_picker: true,
            direction: true,
            first_day_of_week: 1,
            days: Translation.getInstance().trans("map.index.days").split(","),
            months: Translation.getInstance().trans("map.index.months").split(",")
        });
        this.timepicker = ($('#time') as any).Zebra_DatePicker({
            format: 'H:i',
            disabled_dates: true,
            enabled_seconds: false,
            readonly_element: true,
            show_icon: false,
            direction: true,
            default_position: 'below', show_clear_date: false,
            lang_clear_date: Translation.getInstance().trans("map.index.remove_time"),
            offset: [0, -10],
        });
        (this.target.querySelector("#date") as HTMLInputElement).value = Utils.currentDate();
        (this.target.querySelector("#time") as HTMLInputElement).value = Utils.currentTime();
    }

    private initSortable() {
        const self = this;
        const sortElm = this.target.querySelector('#targets') as HTMLElement;
        const sortable = new Sortable(sortElm, {
            handle: '.input-icon-drag',
            ghostClass: 'ghost',
            animation: 150,
            filter: '',
            onEnd: function (/**Event*/ e) {
                if (e.oldIndex !== e.newIndex) {
                    self.initFields();
                    RoutePlanner.getInstance().movePoint(e.oldIndex, e.newIndex);
                }
            }
        });
    }

    private initFields() {
        const nodes = this.listInputFormGroup();
        let n = 0;
        nodes.forEach((node: HTMLElement) => {
            const input: HTMLInputElement = node.querySelector('input');
            if (n === 0) {
                input.placeholder = Translation.getInstance().trans("map.index.start_point");
            } else if (n === nodes.length - 1) {
                input.placeholder = Translation.getInstance().trans("map.index.end_point");
            } else {
                input.placeholder = Translation.getInstance().trans("map.index.via_point") + "#" + n;
            }
            n++;
        });
    }

    private initForm() {
        const self = this;
        const elm = document.getElementById("targets");
        this.resetForm();
        if (!this.addressUrl) {
            return;
        }
        elm.addEventListener("keyup", (e: KeyboardEvent) => {
            if (!(e.target as HTMLElement).classList.contains("--js-target")) {
                return;
            }
            const delay = 400;
            (e.target as any).dataset.timestamp = new Date().getTime();
            setTimeout(() => {
                if (new Date().getTime() - parseInt((e.target as any).dataset.timestamp) < delay) {
                    return;
                }
                // const idx = this.findIdx((e.target as HTMLElement).closest('.--js-autocomplete-form'));
                // const title = RoutePlanner.getInstance().getPointTitle(idx);
                // if (title === null || title.trim() !== (e.target as HTMLInputElement).value.trim()) {
                self.handlerAutocomplete(e.target as HTMLInputElement);
                // }
            }, delay);
        });

        elm.addEventListener("click", (e: MouseEvent) => {
            // const btn = (e.target as HTMLElement).closest('button.button-connection') as HTMLButtonElement;
            if (self.setConnection(e)) {
                e.preventDefault();
            } else if (self.selectAutocomplete(e)) {
                e.preventDefault();
            } else if (self.removePoint(e)) {
                e.preventDefault();
            }
        });
        const form = document.querySelector("#form");
        form.querySelector(".--js-add-target").addEventListener("click", (e: MouseEvent) => {
            RoutePlanner.getInstance().addViaPoint();
        });

        form.querySelector('.--js-reset').addEventListener("click", function (e: MouseEvent) {
            e.preventDefault();
            RoutePlanner.getInstance().resetRoute();
            const routingSetting = form.querySelector('.routing-settings');
            if (!routingSetting.classList.contains('disabled')) {
                routingSetting.classList.add('disabled');
                self.hideAutocomplete();
            }
        });

        form.querySelector('.--js-submit').addEventListener("click", function (e: MouseEvent) {
            e.preventDefault();
            RoutePlanner.getInstance().calculateRoute();
        });

        elm.addEventListener("focus", (e: FocusEvent) => {
            self.hideAutocomplete();
        }, true);

        elm.addEventListener("blur", (e: FocusEvent) => {
            self.checkValue(e);
        }, true);
    }

    private checkValue(e: FocusEvent) {
        const elm = e.target as HTMLElement;
        if (elm.classList.contains("--js-target")) {
            const idx = this.findIdx(elm.closest(".--js-autocomplete-form"));
            RoutePlanner.getInstance().checkPointValue(this.pointType(idx), idx, (elm as HTMLInputElement).value);
        }
    }

    public getOptions(): { [key: string]: any } {
        const types: string[] = [];
        if ((this.target.querySelector("#transportation_2") as HTMLInputElement).checked) {
            types.push((this.target.querySelector("#transportation_2") as HTMLInputElement).value);
        }
        if ((this.target.querySelector("#transportation_1") as HTMLInputElement).checked) {
            types.push((this.target.querySelector("#transportation_1") as HTMLInputElement).value);
        }
        const transp: { [key: string]: any } = {
            transportation: {
                date: (this.target.querySelector("#date") as HTMLInputElement).value,
                time: (this.target.querySelector("#time") as HTMLInputElement).value,
                types: types
            }
        };
        return {...transp, ...{filter: this.userRouteOptions.options}};
    }

    public setStart(point: RoutePoint) {
        const elm = document.querySelectorAll("#targets .form-group")[0] as HTMLElement;
        this.setInputProps(elm, point);
    }

    public setEnd(point: RoutePoint) {
        const nodes = this.listInputFormGroup();
        const elm = nodes[nodes.length - 1] as HTMLElement;
        this.setInputProps(elm, point);
    }

    public setVia(point: RoutePoint, idx: number) {
        const nodes = this.listInputFormGroup();
        if (idx === nodes.length - 1) {
            const via = this.cloneTemplate();
            nodes[idx].insertAdjacentElement("beforebegin", via);
            this.initFields();
            this.setInputProps(via, point);
        } else {
            const elm = nodes[idx] as HTMLElement;
            this.setInputProps(elm, point);
        }
    }

    private setInputProps(formGroupElm: HTMLElement, point: RoutePoint) {
        const input = formGroupElm.querySelector("input") as HTMLInputElement;
        input.value = point && point.getTitle() || "";
        input.dataset.type = point.getType();
        const btn = formGroupElm.querySelector('button[data-type="' + point.getConnection() + '"]') as HTMLButtonElement;
        this.validateCoordinates(input, point);
        this.switchConnection(btn);
    }

    private validateCoordinates(input: HTMLInputElement, point: RoutePoint) {
        if (!this.target.classList.contains("was-validated")) {
            (this.target as HTMLFormElement).checkValidity();
            this.target.classList.add("was-validated");
        }
        if (point.getCoordinates() === null) {
            input.setCustomValidity("no coordinates");
        } else {
            input.setCustomValidity("");
        }
    }

    public removeStart() {
        const elm = document.querySelectorAll("#targets .form-group")[0] as HTMLElement;
        const input = elm.querySelector("input") as HTMLInputElement;
        input.value = "";
    }

    public removeEnd() {
        const nodes = this.listInputFormGroup();
        const elm = nodes[nodes.length - 1] as HTMLElement;
        const input = elm.querySelector("input") as HTMLInputElement;
        input.value = "";
    }

    public removeVia(idx: number) {
        const nodes = document.querySelectorAll("#targets .form-group");
        if (idx !== 0 && idx < nodes.length - 1) {
            nodes[idx].remove();
        }
    }

    private cloneTemplate(): HTMLElement {
        const tmplStr: string = document.querySelector("#form-autocomplete-template").innerHTML;
        const tmpl = new DOMParser().parseFromString(tmplStr, "text/html").body.firstChild as HTMLElement;
        const icon = tmpl.querySelector(".input-icon-drag");
        if (icon) {
            (icon as any).title = Translation.getInstance().trans("map.index.move");
        }
        return tmpl;
    }

    public resetForm() {
        const elm = document.getElementById("targets");
        elm.innerHTML = "";
        elm.append(this.cloneTemplate());
        elm.append(this.cloneTemplate());
        this.initFields();
        (this.target.querySelector("#date") as HTMLInputElement).value = Utils.currentDate();
        (this.target.querySelector("#time") as HTMLInputElement).value = Utils.currentTime();
        if (this.target.classList.contains("was-validated")) {
            this.target.classList.remove("was-validated");
        }
    }

    private handlerAutocomplete(elm: HTMLInputElement) {
        const val = elm.value.trim();
        // return if not defined or empty
        if (!val || val.length === 0) {
            return;
        }
        const reqId = Math.random().toString(36).substr(2, 9);
        elm.setAttribute("reqId", reqId);
        const data = {
            q: val,
            stations: elm.dataset.type === ConnType.bus,
            format: "raw"
        };
        const events = {
            onloadstart: () => {
                document.getElementById("loader").style.display = "block";
            },
            onloadend: () => {
                document.getElementById("loader").style.display = "none";
            }
        };
        const listEml = elm.closest(".--js-autocomplete-form").querySelector('.--js-target-results') as HTMLElement;
        return HttpClient.sendData(this.addressUrl, data, HTTP_METHOD.GET, "json", events)
            .then((response: any) => {
                this.renderAddresses(listEml, response);
            })
            .catch((errors) => {
                console.error(errors);
            });
    }

    private renderAddresses(resultListElm: HTMLElement, results) {
        resultListElm.innerHTML = "";
        resultListElm.classList.add("show");
        let resultListHtml = '';
        for (const result of results) {
            if (!result.coordinate) {
                continue;
            }
            resultListHtml +=
                '<li class="list__item p-2 --js-list-item"' +
                ' data-position="' + result.coordinate.toString() + '"' +
                ' data-srid="' + result.srid + '"' +
                ' data-station="' + result.station + '"' +
                ' data-addition="' + result.addition + '"' +
                ' data-transport="' + result.station + '"' +
                ' data-label="' + result.label + '"' +
                '><a class="list__item__link">' +
                '<span class="list__item__addition__icon ' + result.icon + '" title="' + result.icon_title + '"></span> ' + result.label +
                '<span class="list__item__addition__left"> ' + result.leftaddition + '</span>' +
                '<span class="list__item__addition">' + result.addition + '</span>' +
                '</a></li>'
            ;
        }
        resultListElm.innerHTML = resultListHtml ? '<ul class="list-unstyled p-0 target-result-list">' + resultListHtml + '</ul>' : "";
    }

    private setConnection(e: MouseEvent) {
        const btn = (e.target as HTMLElement).closest('button.button-connection') as HTMLButtonElement;
        if (!btn) {
            return false;
        }
        const hasBefore = btn.classList.contains("active");
        if (!hasBefore) {
            const selfNode = btn.closest(".--js-autocomplete-form") as HTMLElement;
            const idx = this.findIdx(selfNode);
            if (idx !== null) {
                RoutePlanner.getInstance().changeConnection(idx, btn.dataset.type as ConnType);
            }
        }
        return true;
    }

    private switchConnection(btn: HTMLButtonElement) {
        btn.closest(".--js-connection").querySelectorAll("button")
            .forEach((elm: HTMLButtonElement) => {
                elm.classList.remove("active");
            });
        btn.classList.add("active");
        const routingSetting = this.target.querySelector('.routing-settings');
        if (btn.closest("#targets").querySelectorAll("button[data-type=bus].active").length > 0) {
            if (routingSetting.classList.contains('disabled')) {
                routingSetting.classList.remove('disabled');
                this.hideAutocomplete();
            }
        } else {
            if (!routingSetting.classList.contains('disabled')) {
                routingSetting.classList.add('disabled');
                this.hideAutocomplete();
            }
        }
    }

    private hideAutocomplete() {
        this.target.querySelectorAll('.--js-target-results')
            .forEach((elm: HTMLElement) => {
                if (elm.classList.contains('show')) {
                    elm.classList.remove('show');
                }
            });
    }

    private selectAutocomplete(e: MouseEvent) {
        const item = (e.target as HTMLElement).closest('.list__item') as HTMLLIElement;
        if (!item) {
            return false;
        }
        const coord = item.dataset.position.split(",").map((val: string) => {
            return parseFloat(val);
        });
        const selfNode = item.closest(".--js-autocomplete-form") as HTMLElement;
        // const nodes = document.querySelectorAll("#targets .form-group");
        const idx = this.findIdx(selfNode);
        if (idx !== null) {
            const pointType = this.pointType(idx);
            item.closest(".--js-target-results").classList.remove("show");
            RoutePlanner.getInstance().resetPoint(coord, item.dataset.label, item.dataset.station, pointType, idx);
        }
        return true;
    }

    public removePoint(e: MouseEvent) {
        const via = (e.target as HTMLElement).closest('.--js-remove-target') as HTMLLIElement;
        if (!via) {
            return false;
        }
        const selfNode = via.closest(".--js-autocomplete-form") as HTMLElement;
        const idx = this.findIdx(selfNode);
        if (idx !== null) {
            const type = idx === 0 ? PointType.start : (idx === this.inputFormGroupCount() - 1 ? PointType.end : PointType.via);
            RoutePlanner.getInstance().removePoint(type, idx);
        }
    }

    private findIdx(inputFormGroup: HTMLElement) {
        let idx = 0;
        const nodes = this.listInputFormGroup();
        for (; idx < nodes.length; idx++) {
            if (nodes[idx] === inputFormGroup) {
                return idx;
            }
        }
        return null;
    }

    private inputFormGroupCount() {
        return this.listInputFormGroup().length;
    }

    private listInputFormGroup() {
        return document.querySelectorAll("#targets .form-group");
    }

    private pointType(idx: number) {
        const nodesLength = this.inputFormGroupCount();
        return idx === 0 ? PointType.start : (idx === nodesLength - 1 ? PointType.end : PointType.via);
    }
}
