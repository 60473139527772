import {ARoute} from "./ARoute";
import {IRouteProperties} from "./IRouteProperties";
import {UrlHelper} from "../client/service/UrlHelper";

export class RadisRoute extends ARoute {
    private revert: boolean = false;

    public constructor(url: string, crs: string) {
        super(url, crs);
        const siteUrl = UrlHelper.URLForStr(window.location.href);
        this.url = UrlHelper.joinQs(url, siteUrl.searchParams);
    }

    public isRequestValid() {
        return this.url ? true : false;
    }

    public setOptions(options: { [key: string]: any }) {
        // no option supported
        this.options = {};
    }

    public parametersForCalculate(): any {
        // no option supported
        return {};
    }

    public setCalculated(data: IRouteProperties): void {
        this.calculated = data;
        const crs = data.simple.crs.properties.name;
        const features = data.simple.features;
        const start = this.transform(features[0].geometry.coordinates[0], crs);
        this.setStart(this.createRoutePoint(start, this.crs, data.start));
        const lastFeature = features[features.length - 1];
        const end = this.transform(lastFeature.geometry.coordinates[lastFeature.geometry.coordinates.length - 1], crs);
        this.setEnd(this.createRoutePoint(end, this.crs, data.end));
    }

    public reverseRoute(): void {
        super.reverseRoute();
        this.url = this.getReverseUrl();
    }

    public movePoint(from: number, to: number): void {
        // only 2 points: start and end -> always reverse
        if (from !== to) {
            this.reverseRoute();
        }
    }

    private getReverseUrl(): string {
        let spl = this.url.split("?");
        const qs = spl.length === 1 ? "" : spl[1];
        const p = new URLSearchParams(qs);
        const hash = qs.split("#");
        let reverseVal = "false";
        if (p.has("revert")) {
            reverseVal = p.get("revert");
            p.delete("reverse");
        }
        p.set("revert", (!(reverseVal === "true")) + "");
        return spl[0] + "?" + p.toString() + (hash.length === 2 ? "#" + hash[1] : "");
    }
}
