import {SourceVector} from "./SourceVector";
import {AudioRouteHighlicht} from "../interaction/AudioRouteHighlicht";
import {ClusterHighlight} from "../cluster/ClusterHighlight";
import {MapEventType} from "../MapEventObserver";
import {DMap} from "../DMap";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import {OverlayTemplate} from "../OverlayTemplate";
import {OlPopup} from "../OlPopup";
import {Styles} from "../Styles";
import {AudioRouteSelect} from "../interaction/AudioRouteSelect";
import {ObjectEvent} from "ol/Object";
import {AudioVector} from "./AudioVector";

export class Audio {

    protected dmap: DMap;
    protected layer: AudioVector;
    protected hightlichter: AudioRouteHighlicht;
    protected selector: AudioRouteSelect;
    protected styles: any;

    public constructor(dmap: DMap, layer: AudioVector, options: any, overlay: OverlayTemplate, popup: OlPopup = null) {
        this.dmap = dmap;
        this.layer = layer;
        this.styles = Styles.toStyle(options.styles.default);
        try {
            this.hightlichter = new AudioRouteHighlicht(
                MapEventType.pointermove,
                this.dmap,
                this.layer.getMapLayer(),
                Styles.toStyle(options.styles.highlight)
            ); // , this.styleHandler);
            this.hightlichter.register();
        } catch (e) {
            console.error(e);
        }
        this.selector = new AudioRouteSelect(
            MapEventType.singleclick,
            this.dmap,
            this.layer.getMapLayer(),
            overlay,
            popup
        ); // , this.styleHandler);
        this.selector.register();
        this.layer.getMapLayer().setStyle((feature: Feature) => {
            feature.setStyle(this.styles);
            return feature.getStyle() as (Style | Style[]);
        });
        // this.layerPropertyChangedF = this.layerPropertyChanged.bind(this);
        this.layer.getMapLayer().on("propertychange", this.layerPropertyChanged.bind(this));
    }

    public layerPropertyChanged(e: ObjectEvent) {
        if (this.selector) {
            this.selector.hidePopup();
        }
    }

    //
    // public fromOptions(options: any) {
    //     super.fromOptions(options);
    // }

//     this.getLayer().setStyle((feature: Feature) => {
//     feature.setStyle(this.styleHandler.getFeatureStyle(feature));
//     return feature.getStyle() as (Style | Style[]);
// });
}
