import {OverlayTemplate} from "../OverlayTemplate";
import {Translation} from "../../Translation";

export class AudioTemplate extends OverlayTemplate {
    public getNames(): string[] {
        return ["title", "link"];
    }

    public render(o: any): string {
        return `<p class="o-title"><strong>${o.title}</strong></p>
        <audio preload controls src="${o.link}"></audio>`;
    }
}
