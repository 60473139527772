import {ASource} from "./ASource";

export abstract class AItem {
    protected name: string;
    protected title: string;
    protected visible: boolean;
    protected infoable: boolean;
    protected maxScale: number;
    protected minScale: number;

    public getName(): string {
        return this.name;
    }

    public hasName(): boolean {
        return this.name && this.name !== "";
    }

    public setName(value: string) {
        this.name = value;
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(value: string) {
        this.title = value;
    }

    public getInfoable(): boolean {
        return this.infoable;
    }

    public setInfoable(info: boolean): void {
        this.infoable = info;
    }

    public getVisible(): boolean {
        return this.visible;
    }

    public setVisible(value: boolean) {
        this.visible = value;
    }

    public getMaxScale(): number {
        return this.maxScale;
    }

    public setMaxScale(value: number) {
        this.maxScale = value;
    }

    public getMinScale(): number {
        return this.minScale;
    }

    public setMinScale(value: number) {
        this.minScale = value;
    }

    public isScaleInRange(scale: number): boolean {
        return scale >= this.minScale && (this.minScale === Infinity || scale <= this.maxScale);
    }

    public isVisibleForScale(scale: number) {
        return this.visible === true && this.isScaleInRange(scale) === true;
    }

    public fromOptions(options: any): void {
        this.name = options.hasOwnProperty("name") ? options.name : "";
        this.title = options.hasOwnProperty("title") ? options.title : "";
        this.visible = !!options.visible;
        this.maxScale = options.hasOwnProperty("maxScale") ? options.maxScale : Infinity;
        this.minScale = options.hasOwnProperty("minScale") ? options.minScale : 0;
        this.infoable = options.hasOwnProperty("queryable") ? options.queryable : null;
    }
    //
    // public getVisibles(name: string[] = []): string[] {
    //     if (this.hasName() && this.visible) {
    //         name.push(this.name);
    //     }
    //     return name;
    // }
    //
    // public getRealVisibles(scale: number, name: string[] = []): string[] {
    //     if (this.hasName() && this.isVisibleForScale(scale)) {
    //         name.push(this.name);
    //     }
    //     return name;
    // }
    //
    // public getRealInvisibles(scale: number, name: string[] = []): string[] {
    //     if (this.hasName() && !this.isVisibleForScale(scale)) {
    //         name.push(this.name);
    //     }
    //     return name;
    // }
    //
    // public getInfoName(scale: number, name: string[] = []): string[] {
    //     if (this.infoable && this.getRealVisibles(scale).length > 0) {
    //         name.push(this.name);
    //     }
    //     return name;
    // }
}
