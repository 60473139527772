import {DMap} from "../DMap";
import {MapEventType} from "../MapEventObserver";
import {AMultiFeatureEventHandler} from "./AMultiFeatureEventHandler";
import Feature from "ol/Feature";
import {Vector} from "ol/layer";
import Style from "ol/style/Style";

export abstract class AMulitFeatureInteraction extends AMultiFeatureEventHandler {

    protected layer: Vector;
    protected interactionLayer: Map<Vector, Style[]>;
    protected last: Map<Vector, Map<Feature, Feature>>;

    /**
     * Creates an instance
     * @param dmap
     * @param styles
     */
    public constructor(dmap: DMap, mapEvent: MapEventType, interactionLayer: Vector) {
        super(dmap, mapEvent);
        this.layer = interactionLayer;
        this.interactionLayer = new Map<Vector, Style[]>();
        this.last = new Map<Vector, Map<Feature, Feature>>();
    }
    //
    // /**
    //  * Called on event start
    //  */
    // public eventStart(): void {
    //     super.eventStart();
    // }
    //
    // /**
    //  * Called on each feature by event
    //  * @param layer
    //  * @param feature
    //  */
    // public addByEvent(layer: Vector, feature: Feature): void {
    //     this.eventFeatures.get(layer).add(feature);
    // }

    /**
     * Called on event end
     * @param coordinate
     */
    public eventEnd(coordinate: [number, number]): void {
        this.last.forEach((features: Map<Feature, Feature>, layer: Vector) => {
            const lastFeatures: Set<Feature> = new Set<Feature>(features.keys());
            this.removeFeatures(
                layer, Array.from(lastFeatures).filter((x: Feature) => !this.eventFeatures.get(layer).has(x)));
            this.addFeatures(
                layer, Array.from(this.eventFeatures.get(layer)).filter((x: Feature) => !lastFeatures.has(x)));
            this.eventFeatures.get(layer).clear();
        });
    }

    /**
     * Registers a vector layer at an interaction layer
     * @param layer
     * @param interactionStyle
     */
    public register(layer: Vector, interactionStyle: Style[] = null) {
        super.register(layer);
        if (!this.interactionLayer.has(layer)) {
            this.interactionLayer.set(layer, interactionStyle);
            this.last.set(layer, new Map<Feature, Feature>());
        }
    }

    /**
     * Unregisters a vector layer at an interaction layer
     * @param layer
     */
    public unregister(layer: Vector) {
        super.unregister(layer);
        if (this.interactionLayer.has(layer)) {
            this.removeFeatures(layer, Array.from(this.last.get(layer).keys()));
            this.interactionLayer.delete(layer);
            this.last.delete(layer);
        }
    }

    /**
     * Adds features into an interaction layer
     * @param layer
     * @param features
     */
    public addFeatures(layer: Vector, features: Feature[]): void {
        for (const feature of features) {
            this.highlichtOn(layer, feature);
        }
    }

    /**
     * Removes features from an interaction layer
     * @param layer
     * @param features
     */
    public removeFeatures(layer: Vector, features: Feature[]) {
        for (const feature of features) {
            this.highlichtOff(layer, feature);
        }
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected highlichtOn(layer: Vector, feature: Feature, useFeatureStyle: boolean = false): boolean {
        if (this.last.has(layer) && !this.last.get(layer).has(feature)) {
            this.last.get(layer).set(feature, feature.clone());
            if (!useFeatureStyle) {
                this.last.get(layer).get(feature).setStyle(this.interactionLayer.get(layer));
            }
            this.layer.getSource().addFeature(this.last.get(layer).get(feature));
            return true;
        }
        return false;
    }

    /**
     * Removes a given features from an interaction layer
     * @param layer
     * @param feature
     */
    protected highlichtOff(layer: Vector, feature: Feature): boolean {
        if (this.last.has(layer) && this.last.get(layer).has(feature)) {
            this.layer.getSource().removeFeature(this.last.get(layer).get(feature));
            this.last.get(layer).delete(feature);
            return true;
        }
        return false;
    }
}
