import {OlMap} from "../OlMapping";
import DragZoom from "ol/interaction/DragZoom";
import {AControl} from "./AControl";
import {DMap} from "../DMap";

export class DragZoomControl extends AControl {

    private dragzoom: DragZoom;
    private button: HTMLButtonElement;

    constructor(dmap: DMap, dragboxStyle: string) {
        super(dmap, "lbm-drag-zoom");
        this.init( dragboxStyle);
    }

    public init(dragzoomClass: string): void {
        if (!this.getMap()) {
            this.setMap(this.dmap.getMap());
            this.button = document.createElement("button");
            // this.button.id = "button-drag-zoom-control";
            this.button.type = "button";
            this.button.title = "Ausschnitt aufziehen";
            // this.button.innerText = "OO";
            this.button.classList.add("lbm-drag-zoom-button", "oi", "oi-magnifying-glass");
            this.button.addEventListener("click", this.buttonClick.bind(this), false);
            this.dragzoom = new DragZoom({
                className: dragzoomClass,
                condition: () => {
                    return true;
                },
            });
            this.dragzoom.on("boxend", this.deactivate.bind(this));
            this.element.classList.add("drag-zoom", "ol-unselectable", "ol-control");
            this.element.appendChild(this.button);
            (this.getMap().getViewport().querySelector(".ol-overlaycontainer-stopevent") as HTMLElement)
                .appendChild(this.element);
            // this.getMap().addControl(this);
        }
    }

    private buttonClick(e) {
        if (!(e.target as HTMLButtonElement).classList.contains("active")) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    private activate() {
        this.button.classList.add("active");
        this.getMap().addInteraction(this.dragzoom);
    }

    private deactivate() {
        this.button.classList.remove("active");
        this.getMap().removeInteraction(this.dragzoom);
    }
}
