import Event from "ol/events/Event";
import {DMap} from "../DMap";

export enum ViewChange {
    any = "any",
    center = "center",
    resolution = "resolution",
    rotation = "rotation",
}

export class ViewChangeListener {
    private dmap: DMap;
    private activated: boolean = false;
    private viewChangedF: any;
    private callback: Map<ViewChange, Set<(e: Event) => void>>;
    private lastCenter: number[];
    private lastResolution: number;
    private lastRotation: number;

    public constructor(dmap: DMap) {
        this.dmap = dmap;
        this.callback = new Map();
        this.activate();
    }

    public subscribe(type: ViewChange, callback: (e: Event) => void) {
        if (!this.callback.has(type)) {
            this.callback.set(type, new Set());
        }
        if (!this.callback.get(type).has(callback)) {
            this.callback.get(type).add(callback);
        }
    }

    public unsubscribe(type: ViewChange, callback: (e: Event) => void) {
        if (this.callback.has(type) && this.callback.get(type).has(callback)) {
            this.callback.get(type).delete(callback);
            if (this.callback.get(type).size === 0) {
                this.callback.delete(type);
            }
        }
    }

    public activate() {
        if (!this.activated) {
            this.viewChangedF = this.viewChanged.bind(this);
            this.dmap.getMap().getView().on("propertychange", this.viewChangedF);
            this.activated = true;
            this.setState();
        }
    }

    public deactivate() {
        if (this.activated) {
            this.dmap.getMap().getView().un("propertychange", this.viewChangedF);
            this.viewChangedF = null;
            this.activated = false;
        }
    }

    private listCallback(type: ViewChange): ((e: Event) => void)[] {
        return this.callback.has(type) ? Array.from(this.callback.get(type)) : [];
    }

    private publishChange(types: ViewChange[], e: Event) {
        for (const type of types) {
            for (const callback of this.listCallback(type)) {
                callback(e);
            }
        }
    }

    private viewChanged(e: Event) {
        const changes = [];
        if ((this.callback.has(ViewChange.resolution) || this.callback.has(ViewChange.any))
            && this.isResolutionChanged() && this.dmap.getResolutions().indexOf(this.dmap.getResolution()) !== -1) {
            changes.push(ViewChange.resolution);
        }
        if ((this.callback.has(ViewChange.center) || this.callback.has(ViewChange.any)) && this.isCenterChanged()) {
            changes.push(ViewChange.center);
        }
        if ((this.callback.has(ViewChange.rotation) || this.callback.has(ViewChange.any)) && this.isRotationChanged()) {
            changes.push(ViewChange.rotation);
        }
        if (changes.length > 0) {
            changes.push(ViewChange.any);
            this.publishChange(changes, e);
            this.setState();
        }
    }

    private setState() {
        this.lastCenter = this.dmap.getCenter();
        this.lastResolution = this.dmap.getMap().getView().getResolution();
        this.lastRotation = this.dmap.getMap().getView().getRotation();
    }

    private isCenterChanged(): boolean {
        return this.lastCenter[0] !== this.dmap.getCenter()[0]
            || this.lastCenter[1] !== this.dmap.getCenter()[1];
    }

    private isResolutionChanged(): boolean {
        return this.lastResolution !== this.dmap.getMap().getView().getResolution();
    }

    private isRotationChanged(): boolean {
        return this.lastRotation !== this.dmap.getMap().getView().getRotation();
    }
}
