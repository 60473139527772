import Point from "ol/geom/Point";
import Projection from "ol/proj/Projection";
import {Geom} from "./Geom";
import {ProjectionLike} from "ol/proj";

export class DPoint extends Geom {
    public constructor(coordinates: number[], projection: Projection|ProjectionLike) {
        super(new Point(coordinates), projection);
    }

    public getCoordinates(projection: Projection|ProjectionLike): [number, number] {
        if (this.projection !== projection) {
            return (this.geometry as any).clone().transform(this.projection, projection).getCoordinates();
        } else {
            return (this.geometry as any).clone().getCoordinates();
        }
    }
}
