import {AInteractionHandler} from "./AInteractionHandler";
import Feature from "ol/Feature";
import {Vector} from "ol/layer";
import {MapEventType} from "../MapEventObserver";
import {DMap} from "../DMap";
import {OverlayTemplate} from "../OverlayTemplate";
import {OlPopup} from "../OlPopup";

export class AudioRouteSelect extends AInteractionHandler {

    protected overlay: OverlayTemplate;
    protected popup: OlPopup;
    protected lastContent: string;

    /**
     * Creates an instance
     * @param mapEvent
     * @param dmap
     * @param layer
     * @param overlay
     * @param popup
     */
    public constructor(mapEvent: MapEventType, dmap: DMap, layer: Vector, overlay: OverlayTemplate, popup: OlPopup = null) {
        super(mapEvent, dmap, layer, []);
        this.overlay = overlay;
        this.setPopup(popup);

    }

    public eventEnd(coordinate: [number, number]): void {
        if (this.eventFeatures.size === 0) {
            this.hidePopup();
        }
        super.eventEnd(coordinate);
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOn(coordinate: [number, number], feature: Feature): boolean {
        if (!this.last.has(feature)) {
            this.showPopup(coordinate, feature);
            return true;
        }
        return false;
    }

    /**
     * Removes a given features from an interaction layer
     * @param layer
     * @param feature
     */
    protected interactionOff(coordinate: [number, number], feature: Feature): boolean {
        if (this.last.has(feature)) {
            this.last.delete(feature);
            this.lastContent = "";
            this.popup.hide();
            return true;
        } else {
            this.lastContent = "";
            this.popup.hide();
        }
        return false;
    }

    public hidePopup() {
        this.last.clear();
        this.lastContent = "";
        let $audio = $('audio', this.popup.getContent());
        if ($audio.length) {
            $audio.get(0).remove();
        }
        this.popup.hide();
    }

    protected showPopup(coordinate: [number, number], feature: Feature) {
        const o: any = {};
        const keys = feature.getKeys();
        for (const name of this.overlay.getNames()) {
            if (keys.indexOf(name) > -1) {
                o[name] = feature.get(name);
            }
        }
        this.lastContent = '<div class="poi-popup">' + this.overlay.render(o) + "</div>";
        this.popup.setContent(this.lastContent);
        this.popup.show(coordinate);
    }

    private setPopup(popup: OlPopup = null) {
        // if (popup !== null) {
        //     this.popup = popup;
        // } else {
            this.popup = this.dmap.createPopup();
        // }
    }
}
