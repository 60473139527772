import {IChangedSource} from "./IChangedSource";
import {Progress} from "./Progress";
import Point from "ol/geom/Point";
import {numberName, pointTypeName} from "../routing/Routing";
import {DMap} from "../DMap";
import {IRequestEvents} from "../../client/service/IRequestEvents";

declare var showLoader: (show: boolean) => void;

export class MapActivity {

    public static create(dmap: DMap = null): MapActivity {
        if (!MapActivity.instance) {
            MapActivity.instance = new MapActivity(dmap);
        }
        return MapActivity.instance;
    }

    private static instance: MapActivity;
    private progress: Progress;
    private dmap: DMap;

    private constructor(dmap: DMap) {
        this.dmap = dmap;
        const elm = document.getElementsByClassName("lbm-progressbar");
        if (elm.length === 1) {
            this.progress = new Progress(elm[0] as HTMLElement);
        }
    }

    public getEvents(idx: string): IRequestEvents {
        return  {
            onloadstart: () => {
                this.dmap.getMapActivity().sourceLoadStart(idx);},
            onloadend: () => {
                this.dmap.getMapActivity().sourceLoadEnd(idx);}
        };
    }

    public getProgress() {
        return this.progress;
    }

    public sourceLoadStart(layerName: string) {
        if (this.progress) {
            this.progress.addLoading();
        }
    }

    public sourceLoadEnd(layerName: string) {
        if (this.progress) {
            this.progress.addLoaded();
        }
    }

    public sourceLoadError(layerName: string) {
        if (this.progress) {
            this.progress.addLoaded();
        }
    }

    public visibilityChanged(uuid: string, changed: IChangedSource): void {
        this.dmap.getTargetElement().dispatchEvent(
            new CustomEvent("serviceChanged",
                {
                    detail: {
                        activity: changed,
                    },
                }));
    }
}
