import {DMap} from "../DMap";
import Vector from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import {ClusterStyle} from "./ClusterStyle";
import {ClusterHighlight} from "./ClusterHighlight";
import {MapEventType} from "../MapEventObserver";
import Style from "ol/style/Style";
import {AInteractionHandler} from "../interaction/AInteractionHandler";
import {ClusterSelect} from "./ClusterSelect";
import {OverlayTemplate} from "../OverlayTemplate";
import WKT from "ol/format/WKT";
import {OlPopup} from "../OlPopup";
import Feature from "ol/Feature";
import Event from "ol/events/Event";
import {ObjectEvent} from "ol/Object";
import {SourceVector} from "../source/SourceVector";
import {ViewChange} from "../source/ViewChangeListener";
import {HTTP_METHOD, HttpClient} from "../../client/service/HttpClient";

export let clustered: ClusteredVector;

enum GeoFormat {
    geojson = "geojson",
    wkt = "wkt",
}

export class ClusteredVector {

    protected dmap: DMap;
    protected points: SourceVector;
    protected timeout: number = 200;
    protected clusretedUrl: string;
    protected featureInfoUrl: string;
    protected distancePxl: number;
    protected styleHandler: ClusterStyle;
    protected handler: Set<AInteractionHandler>;
    protected highlighter: ClusterHighlight;
    protected selector: ClusterSelect;
    protected clusteringFormat: string;
    protected activated: boolean;
    protected mapChangedF: any;
    protected layerPropertyChangedF: any;
    protected lastEvent: number;

    /**
     * Creates an instance
     * @param dmap
     * @param styles
     */
    public constructor(dmap: DMap, layer: SourceVector, options: any, overlay: OverlayTemplate, popup: OlPopup = null) {
        this.dmap = dmap;
        this.clusretedUrl = options.url;
        this.featureInfoUrl = options.featureinfoUrl;
        this.distancePxl = options.distancePxl ? options.distancePxl : 40;
        this.points = layer;
        this.styleHandler = new ClusterStyle(options.styles);
        this.highlighter = new ClusterHighlight(MapEventType.pointermove, this.dmap, this, this.styleHandler);
        this.highlighter.register();
        this.highlighter.usePointer(this.dmap.getTargetElement());
        this.clusteringFormat = options.clusteringFormat;

        this.selector = new ClusterSelect(MapEventType.singleclick, this.dmap, this, overlay, popup);
        this.selector.register();
        this.setFeatureStyle();
        clustered = this;
        this.activated = false;
        this.activate();
    }

    public isMinResolution() {
        return this.dmap.getMinResolution() === this.dmap.getMap().getView().getResolution()
            && this.points.getSourceState().getRealVisibles().length > 0;
    }

    public activate() {
        if (!this.activated) {
            this.mapChangedF = this.onMapChange.bind(this);
            // this.dmap.getMap().getView().on("change", this.mapChangedF);
            this.dmap.getViewChangeListener().subscribe(ViewChange.any, this.mapChangedF);
            this.layerPropertyChangedF = this.layerPropertyChanged.bind(this);
            this.getLayer().on("propertychange", this.layerPropertyChangedF);
            this.activated = true;
        }
    }

    public deactivate() {
        if (this.activated) {
            // this.dmap.getMap().getView().un("change", this.mapChangedF);
            this.dmap.getViewChangeListener().unsubscribe(ViewChange.any, this.mapChangedF);
            this.mapChangedF = null;
            this.getLayer().un("propertychange", this.layerPropertyChangedF);
            this.layerPropertyChangedF = null;
            this.activated = false;
        }
    }

    public layerPropertyChanged(e: ObjectEvent) {
        if (this.selector) {
            this.selector.hidePopup();
        }
        this.load(new Date().getTime());
    }

    public addInteraction(handler: AInteractionHandler) {
        if (!this.handler) {
            this.handler = new Set<AInteractionHandler>();
        }
        this.handler.add(handler);
    }

    public getLayer(): Vector {
        return this.points.getMapLayer();
    }

    public loadInfo(ids: string[]) {
        const url = this.featureInfoUrl + "?ids=" + encodeURIComponent(ids.join(","));
        const events = this.dmap.getMapActivity().getEvents(this.points.getUuid());
        return HttpClient.sendData(url, null, HTTP_METHOD.GET, "json", events);
    }

    protected setLayerStyle() {
        if (this.styleHandler.getPropName()) {
            this.getLayer().setStyle((feature: Feature) => {
                return this.styleHandler.getFeatureStyle(feature);
            });
        } else {
            this.getLayer().setStyle(this.styleHandler.getStyle(["default", "default"], true));
        }
    }

    protected setFeatureStyle() {
        if (this.styleHandler.getPropName()) {
            this.getLayer().setStyle((feature: Feature) => {
                feature.setStyle(this.styleHandler.getFeatureStyle(feature));
                return feature.getStyle() as (Style | Style[]);
            });
        } else {
            this.getLayer().setStyle((feature: Feature) => {
                feature.setStyle(this.styleHandler.getStyle(["default", "default"], true));
                return feature.getStyle() as (Style | Style[]);
            });
        }
    }

    protected onMapChange(e: Event) {
        const timestamp = new Date().getTime();
        this.lastEvent = timestamp;
        window.setTimeout(() => {
            this.checkMapChange(e, timestamp);
        }, this.timeout);
    }

    protected checkMapChange(e: Event, timestamp) {
        if (this.selector) {
            this.selector.hidePopup();
        }
        if (this.lastEvent === timestamp) {
            this.points.resetState(this.dmap.getScale(), [], null);
            this.load(timestamp);
        }
    }

    protected load(timestamp: number) {
        this.lastEvent = timestamp;
        if (this.points.getSourceState().getRealVisibles().length > 0) {
            const extent = this.dmap.getExtent();
            const eps = this.distancePxl * this.dmap.getMap().getView().getResolution();
            const format = GeoFormat.wkt;
            const url = this.clusretedUrl + "?bbox=" + (extent.join(",")) + "&eps=" + eps + "&num=1&typeNames="
                + this.points.getSourceState().getRealVisibles().join(",") + "&format=" + this.clusteringFormat;
            const events = this.dmap.getMapActivity().getEvents(this.points.getUuid());
            HttpClient.sendData(url, null, HTTP_METHOD.GET, "text", events)
                .then((data: any) => {
                    if (this.lastEvent === timestamp) {
                        this.getLayer().getSource().clear();
                        this.addFeatures(format, data);
                    }
                })
                .catch((errors) => {
                    console.error(errors);
                });
        } else {
            this.getLayer().getSource().clear();
        }
    }

    protected addFeatures(format: GeoFormat, content: string) {
        if (format === GeoFormat.geojson) {
            this.getLayer().getSource().addFeatures(new GeoJSON().readFeatures(JSON.parse(content)));
        } else if (format === GeoFormat.wkt) {
            const data = JSON.parse(content);
            const reader = new WKT();
            for (const row of data.data) {
                const f = reader.readFeature(row[2], {
                    // const f = reader.readFeature("POINT(" + row[2].join(" ") + ")", { // wkt round
                    dataProjection: "EPSG:25832",
                    featureProjection: "EPSG:25832",
                });
                const aggr: any = {};
                for (const type of Object.keys(row[0])) {
                    aggr[type] = [];
                    for (const item of row[0][type]) {
                        aggr[type].push({
                            id: item[0],
                            title: item[1],
                        });
                    }
                }
                f.set("aggregation", aggr);
                f.set("count", row[1]);
                this.getLayer().getSource().addFeature(f);
            }
        }
    }
}
