import {IPostProcessorOptions} from "./IPostProcessorOptions";

export abstract class PostProcessor {
    protected options: IPostProcessorOptions;

    public constructor(options: IPostProcessorOptions[]) {
        this.options = this.translateTemplate(options);
    }

    public abstract process(data: string): string;

    protected abstract translateTemplate(options: IPostProcessorOptions[]): IPostProcessorOptions[];
}
