import {IStyleFontOptions} from "./IStyleFontOptions";

export class StyleFont {

    public static parseToValid(text: string): string {
        const fo = StyleFont.parse(text);
        if (!fo) {
            return null;
        }
        return StyleFont.toString(fo);
    }

    public static parse(text: string): IStyleFontOptions {
        const p = new StyleFont();
        p.font = {family: null, size: null};
        text = text.trim();
        text = text.replace(/\s+/g, " ");
        text = text.replace(/\s?,\s?/g, ",");
        const chunks = text.split(" ");
        let pos = p.findSizePosition(chunks);
        if (pos === -1) {
            window.console.warn("The mandatory font parameter 'font-size' is not found");
            return null;
        }
        if (!p.setFamily(chunks, pos + 1)) {
            window.console.warn("The mandatory font parameter 'font-family' is not found");
            return null;
        }
        p.setSizeAndLength(chunks[pos]);
        pos--;
        while (pos > -1) {
            pos = p.findAndSetWeight(chunks, pos);
            if (pos <= -1) {
                break;
            }
            pos = p.findAndSetStyle(chunks, pos);
            if (pos <= -1) {
                break;
            }
            pos--;
        }
        return p.font;
    }

    public static isValid(font: IStyleFontOptions) {
        return !(font.family === null || font.size === null);
    }

    public static toString(font: IStyleFontOptions) {
        if (!StyleFont.isValid(font)) {
            return null;
        }
        const r = [font.family, font.lineHeight ? (font.size + "/" + font.lineHeight) : font.size];
        if (font.weight) {
            r.push(font.weight);
        }
        if (font.style) {
            r.push(font.style);
        }
        return r.reverse().join(" ");
    }

    protected static angles: string[] = ["deg", "grad", "rad", "turn"];
    protected static style: string[] = ["normal", "italic", "oblique"];
    protected static size: string[] = // , "inherit"]; inherit is not supported
        ["xx-small", "x-small", "small", "medium", "large", "x-large", "xx-large", "smaller, larger"];
    protected static weight: string[] = ["normal", "bold", "bolder", "lighter"];
    protected static variant: string[] = [/*variant-caps*/"normal", "small-caps", "all-small-caps", "petite-caps",
        "all-petite-caps", "unicase", "titling-caps", /*variant-numeric*/ "ordinal", "slashed-zero", "lining-nums",
        "oldstyle-nums", "proportional-nums", "tabular-nums", "diagonal-fractions", "stacked-fractions",
        /*variant-alternates not supported*//*variant-ligatures*/ "none", "common-ligatures", "no-common-ligatures",
        "discretionary-ligatures", "no-discretionary-ligatures", "historical-ligatures", "no-historical-ligatures",
        "contextual", "no-contextual"];

    protected font: IStyleFontOptions;

    protected findSizePosition(chunks: string[]): number {
        let i = chunks.length - 1;
        while (i > -1) {
            const text = chunks[i];
            if (text.search(/^\d/) > -1) {
                return i;
            }
            for (const key of StyleFont.size) {
                if (text.startsWith(key)) {
                    return i;
                }
            }
            i--;
        }
        return i;
    }

    protected setFamily(chunks: string[], pos: number): boolean {
        const family = chunks.slice(pos).join(" ");
        if (family) {
            this.font.family = family;
            return true;
        } else {
            return false;
        }
    }

    protected setSizeAndLength(text: string): void {
        const chunks = text.split("/");
        if (chunks.length === 2) {
            this.font.size = chunks[0];
            this.font.lineHeight = chunks[1];
        } else {
            this.font.size = chunks[0];
        }
    }

    protected findAndSetWeight(chunks: string[], pos: number): number {
        if (this.font.weight) {
            return pos;
        }
        for (const key of StyleFont.weight) {
            if (chunks[pos] === key) {
                this.font.weight = key;
                return pos - 1;
            }
        }
        if (chunks[pos].search(/^\d+(\.\d*)?$/) > -1 || chunks[pos].search(/^(\d+)?\.\d*$/) > -1) {
            this.font.weight = chunks[pos];
            return pos - 1;
        }
        return pos;
    }

    protected isStyleKey(text: string) {
        for (const key of StyleFont.weight) {
            if (text.startsWith(key)) {
                return true;
            }
        }
        return false;
    }

    protected findAndSetStyle(chunks: string[], pos: number): number {
        if (this.font.style) {
            return pos;
        }
        if (pos - 1 > -1 && this.isStyleKey(chunks[pos - 1]) && this.isAngle(chunks[pos])) {
            this.font.weight = chunks[pos - 1] + " " + chunks[pos];
            return pos - 1 - 1;
        } else if (this.isStyleKey(chunks[pos])) {
            this.font.weight = chunks[pos];
            return pos - 1;
        }
        return pos;
    }

    protected isAngle(text: string) {
        return text.search(/^\d+(\.\d+)?(deg|grad|rad|turn)$/) > -1;
    }

    protected findAndSetVariant(chunks: string[], pos: number) {
        for (const key of StyleFont.variant) {
            if (chunks[pos] === key) {
                if (!this.font.variant) {
                    this.font.variant = key;
                } else {
                    this.font.variant += " " + key;
                }
                return true;
            }
        }
    }
}
