import {DMap} from "../DMap";
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import {MapEventType} from "../MapEventObserver";
import {ClusterStyle} from "./ClusterStyle";
import Feature from "ol/Feature";
import {Styles} from "../Styles";
import {AInteractionHandler} from "../interaction/AInteractionHandler";
import {ClusteredVector} from "./ClusteredVector";

export class ClusterHighlight extends AInteractionHandler {

    protected styleHandler: ClusterStyle;
    protected target: Element;
    protected customized: ClusteredVector;
    protected groups: Feature[];

    public constructor(mapEvent: MapEventType,
                       dmap: DMap, cusomized: ClusteredVector,
                       styleHandler: ClusterStyle) {
        super(mapEvent, dmap, cusomized.getLayer());
        this.customized = cusomized;
        this.styleHandler = styleHandler;
        this.groups = [];
    }

    public usePointer(elm: Element) {
        this.target = elm;
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOn(coordinate: [number, number], feature: Feature): boolean {
        if (!this.last.has(feature)) {
            const count = feature.get("count");
            this.last.set(feature, Styles.styleAsArray(feature));
            if (feature.get("count") === 1) {
                this.showPointer(true);
                for (const type of Object.keys(feature.get("aggregation"))) {
                    feature.setStyle(this.getStyleForSingle(feature, type));
                    break;
                }
            } else if (feature.get("count") > 1 && this.customized.isMinResolution()) {
                this.showPointer(true);
                this.interactionMulti(feature);
            } else if (feature.get("count") > 1) {
                this.showPointer(true);
                this.interactionGroups(feature);
            }
            return true;
        }
        return false;
    }

    /**
     * Removes a given features from an interaction layer
     * @param layer
     * @param feature
     */
    protected interactionOff(coordinate: [number, number], feature: Feature): boolean {
        if (this.last.has(feature)) {
            this.showPointer(false);
            feature.setStyle(this.last.get(feature));
            this.removeGroup();
            this.last.delete(feature);
            return true;
        }
        return false;
    }

    protected showPointer(show: boolean) {
        if (this.target) {
            (this.target as HTMLElement).style.cursor = show ? "pointer" : "";
        }
    }

    protected getStyleForSingle(feature: Feature, type: string = null): Style[] {
        return this.styleHandler.getStyle(["highlight", "default"], true).concat(this.last.get(feature))
            .concat(this.getTextStyleForSingle(feature, this.styleHandler.getStyle(["highlight", "tooltip"], true)));
    }

    protected getStyleSingleGroup(): Style[] {
        return this.styleHandler.getStyle(["highlight", "default"], true)
            .concat(this.styleHandler.getStyle(["highlight", "group"], true));
    }

    protected interactionMulti(feature: Feature) {
        const radiusPxl = 40;
        const radiusStep = radiusPxl * 1.1 * this.dmap.getMap().getView().getResolution();
        const numAtCircleStep = 6;
        let num = 0;
        let radius = 0 + radiusStep;
        let numAtCircle = 0 + numAtCircleStep;
        let numAngle = 0;
        const aggregation: { [type: string]: any[] } = feature.get("aggregation");
        all:
            for (const type of Object.keys(aggregation)) {
                for (const item of aggregation[type]) {
                    if (num >= feature.get("count")) {
                        break all;
                    } else if (numAtCircle === numAngle) {
                        numAngle = 0;
                        radius += radiusStep;
                        numAtCircle += numAtCircleStep;
                    }
                    const angle = numAngle * 2 * Math.PI / numAtCircle;
                    const typeFeature = new Feature(feature.clone().getGeometry());
                    (typeFeature.getGeometry() as Point)
                        .translate(radius * Math.cos(angle), radius * Math.sin(angle));
                    const hgStyle = this.styleHandler.getStyle(["highlight", type], true);
                    const style: Style[] = this.styleHandler.getStyle(["default", type], true);
                    typeFeature.setStyle(style);
                    this.groups.push(typeFeature);
                    num++;
                    numAngle++;
                }
            }
        this.addGroup();
    }

    protected getTextStyleForSingle(feature: Feature, styles): Style[] {
        if (feature.get("count") !== 1) {
            return [];
        }
        let propNames = [];
        for (const style of styles) {
            const propName = Styles.guessPropertyName(style);
            if (propName.length) {
                propNames = propNames.concat(propName);
            }
        }
        if (propNames.length === 0) {
            return [];
        } else {
            // const withText = [];
            const aggregation = feature.get("aggregation");
            for (const poitype of Object.keys(aggregation)) {
                return Styles.textFromFeature(aggregation[poitype][0], propNames, styles);
            }
        }
    }

    protected interactionGroups(feature: Feature) {
        const st = this.getStyleSingleGroup();
        feature.setStyle(this.setZindex(st, 1));
        const radiusPxl = 40;
        const radiusStep = radiusPxl * 1.1 * this.dmap.getMap().getView().getResolution();
        const numAtCircleStep = 6;
        let typeNum = 0;
        let radius = 0 + radiusStep;
        let numAtCircle = 0 + numAtCircleStep;
        let numAngle = 0;
        const aggregation: { [type: string]: any[] } = feature.get("aggregation");
        all:
            for (const type of Object.keys(aggregation)) {
                if (typeNum >= feature.get("count")) {
                    break all;
                } else if (numAtCircle === numAngle) {
                    numAngle = 0;
                    radius += radiusStep;
                    numAtCircle += numAtCircleStep;
                }
                const angle = numAngle * 2 * Math.PI / numAtCircle;
                const typedFeature = new Feature(feature.clone().getGeometry());
                (typedFeature.getGeometry() as Point)
                    .translate(radius * Math.cos(angle), radius * Math.sin(angle));
                const hgStyle = this.styleHandler.getStyle(["highlight", "default"], true);
                const style: Style[] = this.styleHandler.getStyle(["default", type], true);
                const text = this.setTextToStyle(
                    aggregation[type].length + "", this.styleHandler.getStyle(["highlight", "grouped"], true));
                const styles = hgStyle.concat(style, text);
                typedFeature.setStyle(this.setZindex(styles, 1));
                this.groups.push(typedFeature);
                typeNum++;
                numAngle++;
            }
        this.addGroup();
    }

    protected addGroup() {
        for (const f of this.groups) {
            try {
                this.layer.getSource().addFeature(f);
            } catch (e) {
                //
            }
        }
    }

    protected removeGroup() {
        for (const f of this.groups) {
            try {
                this.layer.getSource().removeFeature(f);
            } catch (e) {
                //
            }
        }
        this.groups = [];
    }

    private setTextToStyle(txt: string, styles: Style[]): Style[] {
        for (const style of styles) {
            if (style.getText()) {
                style.getText().setText(txt);
            }
            return styles;
        }
    }

    private setZindex(styles: Style[], zIndex: number): Style[] {
        for (const style of styles) {
            style.setZIndex(zIndex);
        }
        return styles;
    }
}
