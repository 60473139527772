import {OverlayTemplate} from "../OverlayTemplate";
import {Translation} from "../../Translation";

export class PoiTemplate extends OverlayTemplate {
    public getNames(): string[] {
        return ["id", "title", "strasse", "ort", "plz", "link", "link_type", "homepage", "add_connection"];
    }

    public render(o: any): string {
        if (o instanceof Array) {
            let text = "";
            for (const item of o) {
                text += this.render(item);
            }
            return text;
        } else {
            let text = `<p class="o-title"><strong>${o.title}</strong></p>`;
            if (o.strasse && o.strasse.length > 1) {
                text += `<p class="o-strasse">${o.strasse}</p>`;
            }
            if (o.ort && o.ort.length > 0) {
                text += `<p class="o-ort">${o.ort}</p>`;
            }
            if (o.link && o.link.length > 4) {
                const title = Translation.getInstance().trans("poi_link_" + o.link_type);
                text += `<p class="o-site"><a target="_blank" href="${o.link}"> ${title}</a></p>`;
            }
            if (o.homepage && o.homepage.length > 4
                && (o.homepage.indexOf("http") === 0 || o.homepage.indexOf("www") === 0)) {
                const url = o.homepage.indexOf("http") === 0 ? o.homepage : "http://" + o.homepage;
                const title = Translation.getInstance().trans("poi_link_website");
                text += `<p class="o-site"><a target="_blank" href="${url}"> ${title}</a></p>`;
            }
            if (o.add_connection) {
                const url = o.title
                    ? "https://www.rmv.de/auskunft/bin/jp/query.exe/dn?REQ0JourneyStopsS0G=" + o.title
                    + "&REQ0JourneyStopsS0A=23&REQ0HafasUnsharpSearch=1&REQ0HafasRealTimeMode=8"
                    : "https://www.rmv.de/c/de/fahrplanauskunft";
                const title = Translation.getInstance().trans("poi_link_connection");
                text += `<p><a target="_blank" href="${url}">${title}</a></p>`;
            }
            return text;
        }
    }
}
