import {MapEventType} from "../MapEventObserver";
import {DMap} from "../DMap";
import {OlPopup} from "../OlPopup";
import Vector from "ol/layer/Vector";
import {numberName, pointTypeName, Routing} from "./Routing";
import {AMultiFeatureEventHandler} from "../interaction/AMultiFeatureEventHandler";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Translation} from "../../Translation";

export class RoutingPoiSelect extends AMultiFeatureEventHandler {
    protected popup: OlPopup;
    protected routingPoints: Vector;
    protected pois: Vector;
    protected routing: Routing;

    public constructor(mapEvent: MapEventType,
                       dmap: DMap,
                       routingPoints: Vector,
                       pois: Vector,
                       routing: Routing,
                       popup: OlPopup = null) {
        super(dmap, mapEvent);
        this.routingPoints = routingPoints;
        this.pois = pois;
        this.routing = routing;
        this.setPopup(popup);
        this.register(this.pois);
        this.register(this.routingPoints);
    }

    /**
     * Called on event end
     * @param coordinate
     */
    public eventEnd(coordinate: [number, number]): void {
        this.showPopup(coordinate);
        super.eventEnd(coordinate);
    }

    /**
     * Adds features into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected showPopup(coordinate: [number, number]): void {
        let text: string;
        const container = document.createElement("div");
        found:
        for (const layer of Array.from(this.eventFeatures.keys())) {
            if (layer === this.routingPoints) {
                for (const feature of Array.from(this.eventFeatures.get(layer).values())) {
                    text = this.getRoutingPointMenu(feature);
                    break found;
                }
            } else if (layer === this.pois) {
                for (const feature of Array.from(this.eventFeatures.get(layer).values())) {
                    text = this.getPoiMenu(feature);
                    break found;
                }
            }
        }
        container.innerHTML = text ? text : this.getRoutingMenu(coordinate);
        container.classList.add("routing-menu");
        container.addEventListener("click", (e: MouseEvent) => {
            const t = e.target as HTMLElement;
            if (t.hasAttribute("data-action")) {
                const obj = this.unserialize(t.getAttribute("data-action"));
                switch (obj.action) {
                    case "start":
                    case "end":
                        this.routing.setPoint(obj.coordinates, obj.action, this.routing.getCurrentSrid(), null, true);
                        this.popup.hide();
                        break;
                    case "via":
                        this.routing.setPoint(obj.coordinates, obj.action,
                            this.routing.getCurrentSrid(), this.routing.getFeatureCount(obj.action) + 1, true);
                        this.popup.hide();
                        break;
                    case "round":
                        this.routing.makeRoundRoute(obj.coordinates);
                        this.popup.hide();
                        break;
                    case "remove":
                        this.routing.removePoint(obj.type, obj.num, true);
                    case "close":
                    default:
                        this.popup.hide();
                }
            }
        });
        this.popup.setContent([container]);
        this.popup.show(coordinate);
    }

    private getPoiMenu(feature: Feature): string {
        const coordinate = (feature.getGeometry() as Point).getCoordinates();
        const s1 = this.serialize({action: "start", coordinates: coordinate});
        const s2 = this.serialize({action: "end", coordinates: coordinate});
        const s3 = this.serialize({action: "via", coordinates: coordinate});
        const s4 = this.serialize({action: "close"});
        return `
            <p class="point-title">${Translation.getInstance().trans("route_menu_poi_as")}</p>
            <p class="point-item menu-item" data-action="${s1}">${Translation.getInstance().trans("route_menu_point_start")}</p>
            <p class="point-item menu-item" data-action="${s2}">${Translation.getInstance().trans("route_menu_point_end")}</p>
            <p class="point-item menu-item" data-action="${s3}">${Translation.getInstance().trans("route_menu_point_via")}</p>
            <p class="menu-item" data-action="${s4}">${Translation.getInstance().trans("route_menu_cancel")}</p>`;
    }

    private getRoutingMenu(coordinate: [number, number]): string {
        const s1 = this.serialize({action: "start", coordinates: coordinate});
        const s2 = this.serialize({action: "end", coordinates: coordinate});
        const s3 = this.serialize({action: "via", coordinates: coordinate});
        const s4 = this.serialize({action: "close"});
        const s5 = this.serialize({action: "round", coordinates: coordinate});
        return `
            <p class="point-title">${Translation.getInstance().trans("route_menu_point_as")}</p>
            <p class="point-item menu-item" data-action="${s1}">${Translation.getInstance().trans("route_menu_point_start")}</p>
            <p class="point-item menu-item" data-action="${s2}">${Translation.getInstance().trans("route_menu_point_end")}</p>
            <p class="point-item menu-item" data-action="${s3}">${Translation.getInstance().trans("route_menu_point_via")}</p>
            <p class="point-title menu-item" data-action="${s5}">${Translation.getInstance().trans("route_menu_roundroute")}</p>
            <p class="menu-item" data-action="${s4}">${Translation.getInstance().trans("route_menu_cancel")}</p>`;
    }

    private getRoutingPointMenu(feature: Feature): string {
        const s1 = this.serialize({action: "remove", type: feature.get(pointTypeName), num: feature.get(numberName)});
        const s2 = this.serialize({action: "close"});
        return `
            <p class="point-title">${Translation.getInstance().trans("route_menu_point")}</p>
            <p class="point-item menu-item" data-action="${s1}">${Translation.getInstance().trans("route_menu_point_remove")}</p>
            <p class="menu-item" data-action="${s2}">${Translation.getInstance().trans("route_menu_cancel")}</p>`;
    }

    private serialize(obj: any): string {
        return encodeURIComponent(JSON.stringify(obj));
    }

    private unserialize(text: string): any {
        return JSON.parse(decodeURIComponent(text));
    }

    private setPopup(popup: OlPopup = null) {
        if (popup !== null) {
            this.popup = popup;
        } else {
            this.popup = this.dmap.createPopup();
        }
    }
}
