import {SourceVector} from "./SourceVector";
import {IChangedSource} from "./IChangedSource";
import {PropertyType} from "./ASource";

export class TouristicRegions extends SourceVector {
    public resetState(scale: number, itemNames: string[], visible: boolean): IChangedSource {
        const changed = super.resetState(scale, itemNames, visible);
        if (changed !== null) {
            if (changed.realVisibles.length > 0 && this.visible === false) {
                this.mapLayer.setVisible(true);
                this.visible = this.mapLayer.getVisible();
            } else if (changed.realVisibles.length === 0 && this.visible === true) {
                this.mapLayer.setVisible(false);
                this.visible = this.mapLayer.getVisible();
            }
            this.mapLayer.set(PropertyType.visibleLayers, this.lastState.getRealVisibles());
            this.sourceChanged(changed);
        }
        return changed;
    }

    public isVisibleForScale(scale: number) {
        return this.isScaleInRange(scale) === true;
    }
}
