import Event from "ol/events/Event";
import {DMap} from "../DMap";
import {AControl} from "./AControl";
import {ViewChange} from "../source/ViewChangeListener";

export class ScaleLabel extends AControl {
    private activated: boolean;
    private resolutionChangedF: any;
    private content: HTMLElement;
    private prefix: string;

    public constructor(dmap: DMap) {
        super(dmap, "lbm-scale");
        this.content = document.createElement("div");
        this.content.classList.add("lbm-scale-label");
        this.element.appendChild(this.content);
        this.prefix = "Scale = 1 : ";
        this.activate();
    }

    public activate() {
        if (!this.activated) {
            this.resolutionChangedF = this.resolutionChanged.bind(this);
            // this.dmap.getViewChangeListener().subscribe(ViewChange.resolution, this.resolutionChangedF);
            this.dmap.getViewChangeListener().subscribe(ViewChange.any, this.resolutionChangedF);
            this.setValue();
            this.activated = true;
        }
    }

    public deactivate() {
        if (this.activated) {
            // this.dmap.getViewChangeListener().unsubscribe(ViewChange.resolution, this.resolutionChangedF);
            this.dmap.getViewChangeListener().unsubscribe(ViewChange.any, this.resolutionChangedF);
            this.resolutionChangedF = null;
            this.activated = false;
        }
    }

    protected resolutionChanged(e: Event) {
        window.setTimeout(() => {
            this.setValue();
        }, 400);
    }

    protected setValue() {
        this.content.innerText = `${this.prefix} ${this.dmap.getScale()}`;
    }
}
