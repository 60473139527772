import Style from "ol/style/Style";
import {Styles} from "../Styles";
import Feature from "ol/Feature";

export class ClusterStyle {

    protected propName: string[];
    protected styles: { [key: string]: { [key: string]: Style[] } };

    public constructor(styles: any) {
        this.setStyles(styles);
        this.propName = [];
        this.setPropName(this.styles);
    }

    public getPropName(): string[] {
        return this.propName;
    }

    public setStyles(styles: any) {
        this.styles = {};
        for (const type of Object.keys(styles)) {
            this.styles[type] = {};
            for (const styleType of Object.keys(styles[type])) {
                this.styles[type][styleType] = Styles.toStyle(styles[type][styleType]); // TODO
            }
        }
    }

    public setPropName(styles: any) {
        if (styles instanceof Array) { // Style[]
            for (const style of styles) { // Style
                // this.guessPropertyName(style);
                const props = Styles.guessPropertyName(style);
                for (const prop of props) {
                    if (this.propName.indexOf(prop) === -1) {
                        this.propName.push(prop);
                    }
                }
            }
        } else {
            for (const name of Object.keys(styles)) { // Style
                this.setPropName(styles[name]);
            }
        }
    }

    public getFeatureStyle(feature: Feature): Style[] {
        if (feature.get("count")) {
            if (feature.get("count") > 1) {
                return Styles.textFromFeature(feature, this.propName, this.styles.default.group);
            } else {
                for (const type of Object.keys(feature.get("aggregation"))) {
                    return this.styles.default[type];
                }
                return null;
            }
        } else {
            return this.styles.default.default;
        }
    }

    public getHighlightStyle(feature: Feature): (Style | Style[]) {
        if (feature.get("count")) {
            if (feature.get("count") > 1) {
                const styles = [];
                for (const style of this.styles.default.default) {
                    styles.push(Styles.textFromFeature(feature, this.propName, style.clone()));
                }
                return styles;
            } else {
                return this.styles.default.default;
            }
        } else {
            return this.styles.default.default;
        }
    }

    public getStyle(pointer: string[], clone: boolean): Style[] {
        const styles = this.findStyle(pointer, this.styles);
        const list = styles instanceof Array ? styles : [styles];
        if (clone) {
            const result = [];
            for (const style of list) {
                result.push(style.clone());
            }
            return result;
        } else {
            return list;
        }
    }

    private findStyle(pointer: string[], context: any): Style[] {
        if (pointer.length === 1) {
            return context[pointer[0]];
        } else if (pointer.length !== 1) {
            return this.findStyle(pointer.slice(1), context[pointer[0]]);
        } else {
            return null;
        }
    }

    //
    // private guessPropertyName(style: Style) {
    //     const myRe = RegExp("(?<=\\$\{)([^\}]+)(?=\})", "g");
    //     if (style.getText() && style.getText().getText().indexOf("${") !== -1) {
    //         const tmp = style.getText().getText();
    //         const pos = tmp.indexOf("${");
    //         if (pos !== -1) {
    //             let myArray;
    //             /* tslint:disable:no-conditional-assignment */
    //             while ((myArray = myRe.exec(tmp)) !== null) {
    //                 if (!this.propName) {
    //                     this.propName = [];
    //                 }
    //                 if (this.propName.indexOf(myArray[0]) === -1) {
    //                     this.propName.push(myArray[0]);
    //                 }
    //             }
    //             /* tslint:enable:no-conditional-assignment */
    //         }
    //     }
    // }
}
