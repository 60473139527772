/**
 * @author Paul Schmidt<panadium@gmx.de>.
 */

import {IRequestEvents} from "./IRequestEvents";
import {O2QS} from "./O2QS";
import {UrlHelper} from "./UrlHelper";

export enum HTTP_METHOD {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
    HEAD = "HEAD",
}

/**
 * Class Http. Ajax client.
 */
export class HttpClient {

    /**
     *
     * @param form
     * @param responseType
     * @param events
     * @param async
     * @param timeout
     * @param user
     * @param password
     */
    public static submitForm(
        form: HTMLFormElement,
        responseType: XMLHttpRequestResponseType = "document",
        events: IRequestEvents = null,
        async: boolean = true,
        timeout: number = -1,
        user?: string,
        password?: string) {
        return HttpClient.sendFormData(form.action, new FormData(form), form.method as HTTP_METHOD, responseType,
            events, async, timeout, user, password);
    }

    /**
     *
     * @param url
     * @param formData
     * @param method
     * @param responseType
     * @param events
     * @param async
     * @param timeout
     * @param user
     * @param password
     */
    public static sendFormData(
        url: string,
        formData: FormData,
        method: HTTP_METHOD = HTTP_METHOD.POST,
        responseType: XMLHttpRequestResponseType = "document",
        events: IRequestEvents = null,
        async: boolean = true,
        timeout: number = -1,
        user?: string,
        password?: string): Promise<any> { // : Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let timeoutId: { timeoutId: number } = {timeoutId: timeout};
            const xhr = HttpClient.createRequest(responseType, events, timeoutId, resolve, reject);
            if (method === HTTP_METHOD.GET) {
                xhr.open(method, UrlHelper.joinQs(url, new URLSearchParams(formData as any)), async, user, password);
                xhr.send();
            } else if (method === HTTP_METHOD.POST) {
                xhr.open(method, url, async, user, password);
                xhr.send(formData);
            } else {
                throw new Error("not yet supported");
            }
            HttpClient.setTimeout(timeoutId, xhr, reject);
        });
    }

    /**
     * Sends a data
     * @param url
     * @param method
     * @param responseType
     * @param data
     * @param useProgress
     * @param async
     * @param timeout
     * @param user
     * @param password
     */
    public static sendData(
        url: string,
        data: object = {},
        method: HTTP_METHOD = HTTP_METHOD.POST,
        responseType: XMLHttpRequestResponseType = "text",
        events: IRequestEvents = null,
        async: boolean = true,
        timeout: number = 0,
        user?: string,
        password?: string): Promise<any> { // @TODO
        return new Promise((resolve, reject) => {
            let timeoutId: { timeoutId: number } = {timeoutId: timeout};
            const xhr = HttpClient.createRequest(responseType, events, timeoutId, resolve, reject);
            if (method === HTTP_METHOD.GET) {
                xhr.open(method, UrlHelper.joinQs(url, new URLSearchParams(O2QS.toQS(data)) as any), async, user, password);
                xhr.send();
            } else if (method === HTTP_METHOD.POST) {
                xhr.open(method, url, async, user, password);
                // console .log(O2QS.toQS(data));
                xhr.send(O2QS.toQS(data));
            } else {
                throw new Error("not yet supported");
            }
            HttpClient.setTimeout(timeoutId, xhr, reject);
        });
    }

    /**
     * Sends a data
     * @param url
     * @param method
     * @param responseType
     * @param data
     * @param useProgress
     * @param async
     * @param timeout
     * @param user
     * @param password
     */
    public static sendContent(
        url: string,
        data: object = {},
        responseType: XMLHttpRequestResponseType = "text",
        events: IRequestEvents = null,
        async: boolean = true,
        timeout: number = 0,
        user?: string,
        password?: string): Promise<any> { // @TODO
        return new Promise((resolve, reject) => {
            let timeoutId: { timeoutId: number } = {timeoutId: timeout};
            const xhr = HttpClient.createRequest(responseType, events, timeoutId, resolve, reject);
            xhr.open(HTTP_METHOD.POST, url, async, user, password);
            xhr.send(JSON.stringify(data));
            HttpClient.setTimeout(timeoutId, xhr, reject);
        });
    }

    /**
     *
     * @param responseType
     * @param events
     * @param timeout
     * @param resolve
     * @param reject
     * @private
     */
    private static createRequest(
        responseType: XMLHttpRequestResponseType,
        events: IRequestEvents,
        timeout: { timeoutId: number },
        resolve: (value?: any) => void,
        reject: (reason?: any) => void) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = responseType;
        xhr.onreadystatechange = (e: Event) => {
            if (4 === xhr.readyState && timeout.timeoutId > 0) {
                window.clearTimeout(timeout.timeoutId);
            }
        };
        xhr.onload = () => {
            if (xhr.status === 200) {
                if (xhr.response && xhr.response.status && xhr.response.status === "error") {
                    reject(xhr.response.message);
                }
                resolve(xhr.response);
            } else {
                reject(new Error(xhr.statusText));
            }
        };
        xhr.onerror = () => {
            reject(new Error("XMLHttpRequest Error: " + xhr.statusText));
            console.error(xhr.statusText)
        };
        HttpClient.addEvents(xhr, events);
        return xhr;
    }

    /**
     *
     * @param xhr
     * @param events
     * @private
     */
    private static addEvents(xhr: XMLHttpRequest, events: IRequestEvents = null) {
        if (events !== null) {
            const eventNames = Object.keys(events);
            for (const name of eventNames) {
                switch (name) {
                    // case "onabort":
                    // case "onerror":
                    // case "onload":
                    case "onloadend":
                    case "onloadstart":
                        // case "onprogress":
                        // case "ontimeout":
                        xhr[name] = () => {
                            events[name]();
                        };
                }
            }
        }
        return xhr;
    }

    /**
     *
     * @param timeout
     * @param xhr
     * @param reject
     * @private
     */
    private static setTimeout(timeout: { timeoutId: number }, xhr: XMLHttpRequest, reject: (reason?: any) => void) {
        timeout.timeoutId = timeout.timeoutId > 0 ? window.setTimeout(
            () => {
                xhr.abort();
                reject(new Error("XMLHttpRequest timeout: " + xhr.statusText));
            },
            timeout.timeoutId,
        ) : 0;
    }

    /**
     * Clears a timeout worker for a given id
     * @param timeoutId
     */
    private static clearTimeout(timeoutId: number) {
        if (timeoutId > 0) {
            clearTimeout(timeoutId);
        }
    }
}
