import {SourceVector} from "./SourceVector";
import {HTTP_METHOD, HttpClient} from "../../client/service/HttpClient";
import GeoJSON from "ol/format/GeoJSON";

export class AudioVector extends SourceVector {
    public setUrl(url: string) {
        this.mapLayer.getSource().clear();
        if (url) {
            const events = this.dmap.getMapActivity().getEvents(this.getUuid());
            HttpClient.sendData(url, null, HTTP_METHOD.GET, "json", events)
                .then((data: any) => {
                    this.mapLayer.getSource().addFeatures(new GeoJSON().readFeatures(data));
                });
        }
    }
}
