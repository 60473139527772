import {Geom} from "./Geom";
import MultiPoint from "ol/geom/MultiPoint";
import Projection from "ol/proj/Projection";

export class DExtent extends Geom {
    public static fromArray(coordinates: number[], proj: Projection): DExtent {
        return new DExtent(
            new MultiPoint([[coordinates[0], coordinates[1]], [coordinates[2], coordinates[3]]]), proj);
    }
}
