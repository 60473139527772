import {Utils} from "../map/geom/Utils";
import {DMap, MODEL, SOURCE_POSITION} from "../map/DMap";
import {DExtent} from "../map/geom/DExtent";
import {DPoint} from "../map/geom/DPoint";
import ScaleLine from "ol/control/ScaleLine";
import {ISourceOptions} from "../map/ISourceOptions";
import Vector from "ol/layer/Vector";
import VectorSrc from "ol/source/Vector";
import FullScreen from "ol/control/FullScreen";
import GeoPositionControl from "../map/control/GeoPositionControl";
import {BackgroundSwitcher} from "../map/BackgroundSwitcher";
import {FeatureSelect} from "../map/FeatureSelect";
import {FeatureHighlight} from "../map/FeatureHighlight";
import {RegionTemplate} from "../map/RegionTemplate";
import {Routing} from "../map/routing/Routing";
import {ClusteredVector} from "../map/cluster/ClusteredVector";
import {Styles} from "../map/Styles";
import {PoiTemplate} from "../map/cluster/PoiTemplate";
import {OlPopup} from "../map/OlPopup";
import {SourceWms} from "../map/source/SourceWms";
import {MaskLayer} from "../map/MaskLayer";
import {SourceVector} from "../map/source/SourceVector";
import {DragZoomControl} from "../map/control/DragZoomControl";
import {ScaleLabel} from "../map/control/ScaleLabel";
import {MapPrint} from "../map/print/MapPrint";
import {DeeplinkHandler} from "../map/source/DeeplinkHandler";
import {IDeeplinkOpts} from "../map/source/IDeeplinkOpts";
import {Translation} from "../Translation";
import {IServiceTreeMap} from "../routeplanner/IServiceTreeMap";
import {TouristicRegions} from "../map/source/TouristicRegions";
import {Audio} from "../map/source/Audio";
import {AudioTemplate} from "../map/interaction/AudioTemplate";
import {Attribution, Zoom} from "ol/control";
import {Sidebar} from "../routeplanner/Sidebar";
import {IRouteLegend} from "../route/IRouteLegend";
import {RoutePlanner} from "../route/RoutePlanner";
import {AudioVector} from "../map/source/AudioVector";
import {RoutePoint} from "../route/RoutePoint";
import {IRoutePoint} from "../map/routing/IRoutePoint";
import {IDeeplinkHandlerOpts} from "../map/source/IDeeplinkHandlerOpts";
import {IConfiguration} from "../frontend";
import {ConnType} from "../route/ARoute";
import {IRoutePoints} from "../route/IRoutePoints";
import {IUserRouteOptions} from "../route/IUserRouteOptions";

let dmap = null;
let routingInstance: Routing = null;
let mapPrintInstance: MapPrint = null;
let deeplinkHandlerInstance: DeeplinkHandler = null;
let sidebar: Sidebar = null;
let routeplanner: RoutePlanner;

declare var definedRoute: { [name: string]: any };

export const getMap = () => {
    return dmap;
};

export class MapApp {

    public static initMap(config: any, currentUrl: string, deeplink: IDeeplinkOpts = null, routeId: string = null) {
        const startSrs = config.map.srsNames[0];
        const proj = Utils.createProjection(startSrs);
        const map = new DMap(config.map.target, getConfiguration());
        dmap = map;
        window["getMap"] = map;
        deeplinkHandlerInstance = MapApp.addDeeplinkHandler(map, currentUrl, config);
        if (deeplinkHandlerInstance && deeplink && deeplink.center) {
            map.setStartCenter(new DPoint(deeplink.center, Utils.createProjection(deeplink.srs)));
            map.setStartScale(deeplink.scale);
        } else if (config.map.startCenter && config.map.startScale) {
            map.setStartCenter(new DPoint(config.map.startCenter, proj));
            map.setStartScale(config.map.startScale);
        }

        map.setStartExtent(DExtent.fromArray(config.map.startExtent, proj));
        map.setMaxExtent(DExtent.fromArray(config.map.maxExtent, proj));
        map.setResolutions(Utils.resolutionsForScales(config.map.scales, proj.getUnits()).reverse());
        map.setView(startSrs);
        MapApp.addControl(map, config.map.control ? config.map.control : {});
        const popup = map.createPopup();
        for (const id of Object.keys(config.sources)) {
            MapApp.addSource(map, id, config.sources[id], popup, routeId);
        }
        MapApp.createBackgroundSwitcher(map, config, deeplinkHandlerInstance);

        MapApp.createFeatureHighlight(map, config);
        MapApp.createFeatureSelect(map, config);
        if (config.components) {
            MapApp.addComponents(map, config.components, popup);
        }
        if (config.sidebar && config.components.sidebarSynchronizer) {
            MapApp.addSourcesToDeeplink(map, deeplinkHandlerInstance, deeplink, config.sidebar.pois);
            MapApp.addSourcesToDeeplink(map, deeplinkHandlerInstance, deeplink, config.sidebar.expert);
        }
        if (deeplink) {
            deeplinkHandlerInstance.loadDeeplink(deeplink);
        }
    }

    public static addWmsLayer(map: DMap, id: string, options: any): string {
        const layer = new SourceWms(map, id, options.tiled);
        for (const attr of options.attribution ? options.attribution : []) {
            attr.title = Translation.getInstance().trans(attr.title);
        }
        layer.fromOptions(options);
        return null;
    }

    public static addVectorLayer(map: DMap, id: string, options: any): string {
        const layer = new SourceVector(map, id, options.tiled);
        layer.fromOptions(options);
        return null;
    }

    public static addTouristicRegionsLayer(map: DMap, id: string, options: any): string {
        const layer = new TouristicRegions(map, id, options.tiled);
        layer.fromOptions(options);
        return null;
    }

    public static addAudioRouteLayer(map: DMap, id: string, options: any, popup: OlPopup, routeId: string = null): string {
        const vlayer = new AudioVector(map, id, options.tiled);
        vlayer.fromOptions(options);
        const layer = new Audio(map,
            vlayer,
            options,
            MapApp.getTemplate(options.overlay),
            popup);
        return null;
    }

    public static addRouting(map: DMap, id: string, options: any, popup: OlPopup): any {
        routingInstance = Routing.create(
            map,
            map.getSource(options.lines) as Vector,
            map.getSource(options.points) as Vector,
            map.getSource(options.pois) as Vector,
            map.getSource(options.audio.source) as Vector,
            options.audio.url,
            options.styles,
            popup);
        return routingInstance;
    }

    public static addClustering(map: DMap, id: string, options: any, popup: OlPopup): any {
        const customizedLayer = new ClusteredVector(
            map,
            (map.getSource(options.layer) as Vector).get(MODEL),
            options,
            MapApp.getTemplate(options.overlay),
            popup);

        return null;
    }

    public static addMask(map: DMap, id: string, options: any): any {
        return new MaskLayer(map, map.getSource(options.layer) as Vector, options);
    }

    public static getTemplate(name: string) {
        switch (name) {
            case "RegionTemplate":
                return new RegionTemplate(getConfiguration());
            case "PoiTemplate":
                return new PoiTemplate(getConfiguration());
            case "AudioTemplate":
                return new AudioTemplate(getConfiguration());
            default:
                return null;
        }
    }

    public static addDeeplinkHandler(map: DMap, currentUrl: string, config: any): DeeplinkHandler {
        if (config.map.control && config.map.control.deeplink) {
            const options: IDeeplinkHandlerOpts = {
                saveUrl: config.map.control.deeplink.url,
                callUrl: currentUrl
            };
            return DeeplinkHandler.create(map, options);
        }
        return null;
    }

    private static addDeeplinkSources(dl: DeeplinkHandler, sources: { [key: string]: string[] }): void {
        if (dl) {
            dl.addSources(sources);
        }
    }

    private static createFeatureHighlight(map: DMap, config: any): FeatureHighlight {
        if (config.featureInteraction && config.featureInteraction.highlight) {
            const layer = new Vector({
                source: new VectorSrc({}),
            });
            map.addSource(layer, SOURCE_POSITION.overlay);
            layer.setStyle(Styles.toStyle(config.featureInteraction.highlight.style));
            const handler = new FeatureHighlight(map, layer);
            for (const id of config.featureInteraction.highlight.sources) {
                const source = map.getSource(id);
                if (source && config.sources[id].styles.highlight) {
                    handler.register(source as Vector, Styles.toStyle(config.sources[id].styles.highlight));
                }
            }
        }
        return null;
    }

    private static createFeatureSelect(map: DMap, config: any): FeatureSelect {
        if (config.featureInteraction && config.featureInteraction.select) {
            const layer = new Vector({
                source: new VectorSrc({}),
            });
            map.addSource(layer, SOURCE_POSITION.overlay);
            layer.setStyle(Styles.toStyle(config.featureInteraction.select.style));
            const handler = new FeatureSelect(map, layer);
            for (const id of config.featureInteraction.select.sources) {
                const source = map.getSource(id);
                if (source && config.sources[id].styles.select) {
                    handler.register(source as Vector, Styles.toStyle(config.sources[id].styles.select));
                    if (config.sources[id].overlay && MapApp.getTemplate(config.sources[id].overlay)) {
                        handler.addOverlay(source as Vector, MapApp.getTemplate(config.sources[id].overlay));
                    }
                }
            }
        }
        return null;
    }

    private static createBackgroundSwitcher(map: DMap, config: any, dl: DeeplinkHandler): BackgroundSwitcher {
        if (config.backgroundSwitcher) {
            const elm = document.createElement("div") as HTMLElement;
            elm.classList.add("lbm-background-switcher");
            map.getTargetElement().querySelector(".ol-viewport").appendChild(elm);
            const bgswitcher = new BackgroundSwitcher(elm, map);
            for (const uuid of config.backgroundSwitcher) {
                bgswitcher.addSource(uuid, config.sources[uuid].title);
            }
            if (dl) {
                dl.setSetVisibleBgSources((uuid: string[]) => {
                    bgswitcher.setVisibles(uuid);
                });
                dl.setGetVisibleBgSources(() => {
                    return bgswitcher.getVisibles();
                });
            }
            return bgswitcher;
        }
        return null;
    }

    private static addControl(map: DMap, controlOpts: any): void {
        if (controlOpts.zoom) {
            map.addControl(new Zoom());
        }
        if (controlOpts.fullscreen) {
            map.addControl(new FullScreen());
        }
        if (controlOpts.geoposition) {
            map.addControl(new GeoPositionControl());
        }
        if (controlOpts.scaleline) {
            map.addControl(new ScaleLine());
        }
        if (controlOpts.dragzoom) {
            map.addControl(new DragZoomControl(map, controlOpts.dragzoom.dragboxStyle));
        }
        if (controlOpts.print) {
            const options = {
                printUrlPdf: imported_options.Configuration.printUrlPdf,
                printUrlOptions: imported_options.Configuration.printUrlOptions,
                env: imported_options.Configuration.env,
            };
            mapPrintInstance = MapPrint.create(map, {...controlOpts.print, ...options});
        }
        if (controlOpts.scalelabel) {
            map.addControl(new ScaleLabel(map));
        }
        if (controlOpts.attribution) {
            const attr = new Attribution(controlOpts.attribution);
            map.addControl(attr);
        }
    }

    private static addSource(map: DMap, id: string, options: any, popup: OlPopup, routeId: string = null): string {
        options.title = Translation.getInstance().trans(options.title);
        for (const attr of options.attribution ? options.attribution : []) {
            attr.title = Translation.getInstance().trans(attr.title);
        }
        if (options.type === "WMS") {
            return MapApp.addWmsLayer(map, id, options);
        } else if (options.type === "VECTOR") {
            return MapApp.addVectorLayer(map, id, options);
        } else if (options.type === "TouristicRegions") {
            return MapApp.addTouristicRegionsLayer(map, id, options);
        } else if (options.type === "AudioRoute") {
            return MapApp.addAudioRouteLayer(map, id, options, popup, routeId);
        }
        return null;
    }

    private static setBasicOptions(map: DMap, options: ISourceOptions): any {
        const o: any = {};
        if (options.hasOwnProperty("opacity")) {
            o.opacity = options.opacity;
        }
        if (options.hasOwnProperty("visible")) {
            o.visible = options.visible;
        }
        if (options.hasOwnProperty("extent")) {
            o.extent = options.extent;
        }
        if (options.hasOwnProperty("minScale")) {
            o.minResolution = Utils.resolutionsForScales(
                [options.minScale], Utils.createProjection(map.getCurrentSrs()).getUnits())[0];
        }
        if (options.hasOwnProperty("maxScale")) {
            // make options.maxScale  max inclusive => options.maxScale + 1
            o.maxResolution = Utils.resolutionsForScales(
                [options.maxScale + 1], Utils.createProjection(map.getCurrentSrs()).getUnits())[0];
        }
        return o;
    }

    private static addSourcesToDeeplink(map: DMap, dlh: DeeplinkHandler, deeplink: IDeeplinkOpts, sidebarList: any[]) {
        if (sidebarList) {
            MapApp.synchronizeSideBar(map, sidebarList);
            if (dlh) {
                MapApp.addDeeplinkSources(dlh, MapApp.layersFromSideBar(sidebarList));
            }
        }
    }

    private static createMapEventHandler(mapElement: HTMLElement = document.getElementById('map')) {
        if (RoutePlanner.getInstance()) {
            mapElement.addEventListener("routingPointSetted", function (e: CustomEvent) {
                const point: IRoutePoint = e.detail.routing.point;
                if (!point) {
                    return;
                }
                const rp: RoutePoint = new RoutePoint(point.coordinates, point.coordinates.join(","), ConnType.bicycle, ConnType.bicycle, null);
                RoutePlanner.getInstance().setPoint(rp, point.type, point.idx);
            });
            mapElement.addEventListener("routingPointRemoved", function (e: CustomEvent) {
                const point = e.detail.routing.point;
                if (!point) {
                    return;
                }
                const rp: RoutePoint = new RoutePoint(point.coordinates, point.coordinates.join(","), ConnType.bicycle, ConnType.bicycle, null);
                RoutePlanner.getInstance().removePoint(point.type, point.idx);
            });
        }
        if (Sidebar.getInstance()) {
            mapElement.addEventListener("serviceChanged", function (e: CustomEvent) {
                if (e.detail.activity) {
                    Sidebar.getInstance().handleVisibility(e.detail.activity);
                }
            });
        }
    }

    private static addTranslation(translations: { [key: string]: string }) {
        Translation.getInstance().setData(translations);
    }

    public static addComponents(map: DMap, options: any, popup: OlPopup): void {
        for (const id of Object.keys(options)) {
            const componentOpts = options[id];
            if (componentOpts.type === "ROUTING") {
                MapApp.addRouting(map, id, componentOpts, popup);
            } else if (componentOpts.type === "CLUSTERING") {
                MapApp.addClustering(map, id, componentOpts, popup);
            } else if (componentOpts.type === "MASK") {
                MapApp.addMask(map, id, componentOpts);
            }
        }
    }

    public static addRoutePlanner(options: any, routeLegends: { [key: string]: IRouteLegend[] }, isExpertMode: boolean): any {
        if (options.mapapi.routeplanner) {
            let lh = new URL(window.location.href);
            const traffic_type = lh.searchParams.has("traffic_type") ? lh.searchParams.get("traffic_type") : null;
            const opts = {
                ...options.mapapi.routeplanner,
                ...{legends: routeLegends},
                ...{traffic_type: traffic_type},
                ...{
                    radisRouteUrl: options.radisRouteUrl,
                    userRouteUrl: options.userRouteUrl,
                    addressUrl: options.addressUrl
                }
            };
            // sidebarform = SidebarForm.create(traffic_type);
            routeplanner = RoutePlanner.create(opts);
            // todo legends
            // routeplanner.getRouting().setLegends(legends);
            // routeplanner.init();
        }

    }

    public static addSidebar(options: any, isExpertMode: boolean): any {
        if (options.sidebar) {
            sidebar = Sidebar.create({
                expertMode: isExpertMode,
                elementId: "map-sidebar"
            });
        }
    }

    public static synchronizeSideBar(map: DMap, sidebarItems: any[]): void {
        MapApp.initMapServiceTree(map, sidebarItems);
        const o = {};
        for (const sidebarItem of sidebarItems) {
            for (const item of sidebarItem.items) {
                if (item.refs.length > 0) {
                    for (const ref of item.refs) {
                        if (!o[ref.service]) {
                            o[ref.service] = [];
                        }
                        if (item.visible) {
                            o[ref.service] = o[ref.service].concat(ref.layers);
                        }
                    }
                }
            }
        }
        for (const key of Object.keys(o)) {
            map.showLayers(key, Array.from(new Set(o[key])));
        }
    }

    public static initMapServiceTree(map: DMap, sidebarItems: any[]): void {
        const service2tree = serviceTreeMap["service2tree"];
        const tree2service = serviceTreeMap["tree2service"];
        for (const sidebarItem of sidebarItems) {
            for (const item of sidebarItem.items) {
                if (item.refs.length > 0) {
                    for (const ref of item.refs) {
                        if (!service2tree[ref.service]) {
                            service2tree[ref.service] = {};
                        }
                        for (const l of ref.layers) {
                            if (!service2tree[ref.service][l]) {
                                service2tree[ref.service][l] = item.id;
                            }
                            if (!tree2service[item.id]) {
                                tree2service[item.id] = {};
                            }
                            if (!tree2service[item.id][ref.service]) {
                                tree2service[item.id][ref.service] = [];
                            }
                            tree2service[item.id][ref.service].push(l);
                        }
                    }
                }
            }
        }
    }

    public static layersFromSideBar(sidebarItems: any[]): { [key: string]: string[] } {
        const o = {};
        for (const sidebarItem of sidebarItems) {
            for (const item of sidebarItem.items) {
                if (item.refs.length > 0) {
                    for (const ref of item.refs) {
                        if (!o[ref.service]) {
                            o[ref.service] = [];
                        }
                        o[ref.service] = o[ref.service].concat(ref.layers);
                    }
                }
            }
        }
        for (const key of Object.keys(o)) {
            o[key] = Array.from(new Set(o[key]));
        }
        return o;
    }

    public static handleLocationHref(config: any = null, deeplink: IDeeplinkOpts = null) {
        const lh = new URL(window.location.href);
        if (lh.searchParams.has("layers")) {
            const findMinOfMaxScale = (source: string, layers: string[], scale) => {
                for (const name of layers) {
                    for (const layerConf of config.sources[source].layers) {
                        if (layerConf.name === name && layerConf.maxScale && (scale === null || scale > layerConf.maxScale)) {
                            scale = layerConf.maxScale;
                        }
                    }
                }
                return scale;
            };
            let toScale: number = null;
            for (const item of lh.searchParams.get("layers").split(";")) {
                const serviceSet = item.split(":");
                if (serviceSet.length === 2) {
                    const layers = serviceSet[1].split(",");
                    if (layers.length > 0) {
                        dmap.showLayers(serviceSet[0], layers);
                        toScale = findMinOfMaxScale(serviceSet[0], layers, toScale);
                    }
                }
            }
            if (toScale) {
                dmap.toScale(toScale);
            }
        }
        if (lh.searchParams.has("center")) {
            dmap.toCenter(lh.searchParams.get("center").split(",").map((coord: string) => {
                return parseFloat(coord);
            }));
        }
        if (lh.searchParams.has("scale")) {
            dmap.toScale(parseInt(lh.searchParams.get("scale")));
        }
        if (lh.searchParams.has("nocache")) {
            if (RoutePlanner.getInstance()) {
                RoutePlanner.getInstance().setNocache(true);
            }
        }
        if (deeplink !== null && !lh.searchParams.has("route")) {
            if (RoutePlanner.getInstance()) {
                RoutePlanner.getInstance().routeFromShareLink(deeplink);
            }
        }

        if (lh.searchParams.has("routingPreferences")) {
            if (RoutePlanner.getInstance()) {
                const rp = lh.searchParams.get("routingPreferences");
                if (rp === "ThematicRoutes") {
                    const options: IUserRouteOptions = {
                        thematicroutes: true
                    };
                    RoutePlanner.getInstance().setRouteOptions(options);
                }
            }
        }

        if (lh.searchParams.has("start") && lh.searchParams.has("end")
            && lh.searchParams.has("srid")) {
            if (RoutePlanner.getInstance()) {
                const points: IRoutePoints = {
                    startPoint: {
                        idx: 0,
                        srid: parseInt(lh.searchParams.get("srid")),
                        title: lh.searchParams.get("start"),
                        coordinates: lh.searchParams.get("start").split(",").map((coord: string) => {
                            return parseFloat(coord);
                        })
                    },
                    endPoint: {
                        idx: 0,
                        srid: parseInt(lh.searchParams.get("srid")),
                        title: lh.searchParams.get("end"),
                        coordinates: lh.searchParams.get("end").split(",").map((coord: string) => {
                            return parseFloat(coord);
                        })
                    }
                };
                RoutePlanner.getInstance().routeFromPoints(points);
            }
        }
    }

    public static init(options: any) {
        const config = options.Configuration;
        const mapapi = config.mapapi;
        const deeplink = Array.isArray(options.DeeplinkConfig) ? null : options.DeeplinkConfig.data;
        const routeLegends = options.routeLegends;
        const routeId = options.definedRoute ? options.definedRoute[0].id : null
        const isExpert: boolean = mapapi && mapapi.sidebar && mapapi.sidebar.expert ? true : false;
        MapApp.addTranslation(options.Translations);
        MapApp.addSidebar(mapapi, isExpert);
        Utils.initProj4Defs(mapapi.proj4Defs);
        MapApp.initMap(mapapi, config.currentUrl, deeplink, routeId);
        MapApp.addRoutePlanner(config, routeLegends, isExpert);
        MapApp.createMapEventHandler(document.getElementById(mapapi.map.target));
        MapApp.handleLocationHref(imported_options.Configuration.mapapi, deeplink);
    }
}

export const serviceTreeMap: IServiceTreeMap = {
    service2tree: {},
    tree2service: {},
};

let imported_options: any;

export const getConfiguration = (): IConfiguration => {
    return imported_options.Configuration;
};

declare var window: any;

document.addEventListener('readystatechange', (event: Event) => {
    if ((event.target as any).readyState === 'complete' && window.AO) {
        imported_options = window.AO;
        MapApp.init(window.AO);
    }
});
