import {DMap, MODEL} from "../DMap";
import Style from "ol/style/Style";
import GeoJSON from "ol/format/GeoJSON";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Vector from "ol/layer/Vector";
import {Styles} from "../Styles";
import {OlPopup} from "../OlPopup";
import {MapEventType} from "../MapEventObserver";
import {Utils} from "../geom/Utils";
import {RoutingPoiSelect} from "./RoutingPoiSelect";
import Snap from "ol/interaction/Snap";
import Modify, {ModifyEvent} from "ol/interaction/Modify";
import {Coordinate} from "ol/coordinate";
import MapBrowserEvent from "ol/MapBrowserEvent";
import Select from "ol/interaction/Select";
import {IVectorPrintOptions} from "../print/IVectorPrintOptions";
import {StylesToMb3} from "../StylesToMb3";
import {IPrintProperties} from "../print/IPrintProperties";
import LineString from "ol/geom/LineString";
import {RoundRoute} from "./RoundRoute";
import Collection from "ol/Collection";
import {IRouteProperties} from "../print/IRouteProperties";
import {ATTRIBUTION_KEY} from "../source/ASource";
import {PointType} from "./IRoutePoint";
import {IRouteLegend} from "../../route/IRouteLegend";
import {AudioVector} from "../source/AudioVector";


export const pointTypeName = "pointType";
export const numberName = "num";

const SCALE_FOR_SINGLE_POINT = 5000;

export class Routing {

    public static create(dmap: DMap, lines: Vector, points: Vector, pois: Vector, audio: Vector, audioUrl: string, styles: any, popup: OlPopup = null) {
        if (Routing.instance === null) {
            Routing.instance = new Routing(dmap, lines, points, pois, audio, audioUrl, styles, popup);
        }
        return Routing.instance;
    }

    public static getInstance(): Routing | null {
        // return this.instance || (this.instance = new this(dmap, lines, points, pois, styles, popup));
        return Routing.instance;
    }

    private static instance: Routing = null;

    protected dmap: DMap;
    protected points: Vector;
    protected startStyle: Style[];
    protected endStyle: Style[];
    protected viaStyle: Style[];
    protected tempStyle: Style[];
    protected lines: Vector;
    protected lineStyle: Style[];
    protected selection: RoutingPoiSelect;
    protected snap: Snap;
    protected select: Select;
    protected modify: Modify;
    protected routePrintPropeties: IPrintProperties;
    protected routePoints: Coordinate[];
    protected roundRoute: RoundRoute;
    protected audio: AudioVector;
    protected audioUrl: string;
    protected legends: {[key: string]: IRouteLegend[]};

    /**
     * Creates an instance
     * @param dmap
     * @param styles
     */
    private constructor(dmap: DMap, lines: Vector, points: Vector, pois: Vector, audio: Vector, audioUrl: string, styles: any, popup: OlPopup = null) {
        this.dmap = dmap;
        this.lines = lines;
        this.points = points;
        if (styles.circle) {
            this.points.setStyle(Styles.toStyle(styles.circle));
        }
        this.audio = audio.get(MODEL) as AudioVector;
        this.audioUrl = audioUrl;
        this.startStyle = Styles.toStyle(styles.points.start);
        this.endStyle = Styles.toStyle(styles.points.end);
        this.viaStyle = Styles.toStyle(styles.points.via);
        this.tempStyle = Styles.toStyle(styles.points.temp);
        this.lineStyle = Styles.toStyle(styles.route);
        this.lines.setStyle(this.lineStyle);
        this.selection = new RoutingPoiSelect(MapEventType.contextmenu, this.dmap, this.points, pois, this, popup);
        this.snap = new Snap({
            pixelTolerance: 30,
            features: new Collection(), // this.points.getSource().getFeatures(),
            // source: this.points.getSource(),
        });
        this.dmap.getMap().addInteraction(this.snap);
        this.select = new Select({
            layers: [this.points],
        });
        this.dmap.getMap().addInteraction(this.select);
        this.modify = new Modify({
            features: this.select.getFeatures(),
            pixelTolerance: 20,
        });
        this.dmap.getMap().addInteraction(this.modify);
        this.modify.on("modifyend", (e: ModifyEvent) => {
            if (e.features.getArray().length === 1) {
                this.pointSetted(e.features.getArray()[0]);
            }
        });
        this.legends = null;
    }

    public setAudio(audioUrl: string = null) {
        this.audio.setUrl(audioUrl);
    }

    public setLegends(legends: { [key: string]: any }) {
        this.legends = legends;
    }

    public getCurrentLegend() {
        return this.legends;
    }

    public getFirstPoint(): Coordinate {
        return this.routePoints && this.routePoints.length > 1 ? this.routePoints[0] : [];
    }

    public getLastPoint(): Coordinate {
        return this.routePoints && this.routePoints.length > 1 ? this.routePoints[this.routePoints.length - 1] : [];
    }

    public getPrintLayers(extent: number[] = null): IVectorPrintOptions[] {
        let layers: IVectorPrintOptions[];
        layers = [];
        const lines: IVectorPrintOptions = this.getLayerPrintOptions(this.lines, extent);
        if (lines && lines.geometries.length > 0) {
            layers.push(lines);
        }
        const points = this.getLayerPrintOptions(this.points, extent);
        if (points && points.geometries.length > 0) {
            layers.push(points);
        }
        return layers;
    }

    public getRouteProperties(): IPrintProperties {
        // return this.routePrintPropeties;
        const clone = {};
        for (const name of Object.keys(this.routePrintPropeties)) {
            clone[name] = this.routePrintPropeties[name];
        }
        return clone as IPrintProperties;
    }

    /**
     *  Gets current srs as srid
     */
    public getCurrentSrid() {
        return Utils.epsgToSrid(this.dmap.getCurrentSrs());
    }

    /**
     * Removes all pointes und lines
     */
    public reset(): Routing {
        this.resetPoints();
        this.resetRoute();
        return this;
    }

    /**
     * Removes all points
     */
    public resetPoints(): Routing {
        this.points.getSource().clear();
        return this;
    }

    /**
     * Removes a route
     */
    public resetRoute(): Routing {
        this.lines.getSource().clear();
        this.select.getFeatures().clear();
        return this;
    }

    /**
     * Removes a point
     * @param type
     * @param idx
     */
    public removePoint(type: PointType, idx: number, fire: boolean): boolean {
        const removed = this.removeFeature(type, idx, fire);
        if (removed) {
            this.zoomToPoints();
        }
        return removed;
    }

    /**
     * Sets a point
     * @param coordinates
     * @param type
     * @param srid
     * @param idx
     */
    public setPoint(coordinates: number[], type: PointType, srid: number, idx: number, fire: boolean): boolean {
        const setted = this.addFeature(coordinates, srid, type, idx, fire);
        if (setted) {
            this.zoomToPoints();

        }
        return setted;
    }

    /**
     * Gets the "start" point
     */
    public getStartPoint(): Coordinate {
        return this.getPoint(PointType.start, null);
    }

    /**
     * Gets the "end" point
     */
    public getEndPoint(): Coordinate {
        return this.getPoint(PointType.end, null);
    }

    /**
     * Gets all "via" points
     */
    public getViaPoints(): Coordinate[] {
        const coordinates: Coordinate[] = [];
        for (const feature of this.points.getSource().getFeatures()) {
            if (feature.get(pointTypeName) === PointType.via) {
                coordinates.push((feature.getGeometry() as Point).getCoordinates());
            }
        }
        return coordinates;
    }

    /**
     * Finds the typed points
     * @param type
     * @param idx
     */
    public getPoint(type: PointType, idx: number): Coordinate {
        const f = this.findFeature(type, idx);
        return f ? (f.getGeometry() as Point).getCoordinates() : null;
    }

    public setTempPoint(num: number) {
        const feature = this.findFeature(PointType.temp, null);
        if (this.routePoints && this.routePoints.length > num) {
            const coord = this.routePoints[num];
            if (feature) {
                feature.setGeometry(new Point(coord));
                return true;
            } else {
                const newFeature = new Feature(this.createPoint(coord, this.getCurrentSrid()));
                this.points.getSource().addFeature(newFeature);
                this.setValAndStyles(newFeature, PointType.temp, null);
                return true;
            }
        } else {
            this.removeTempPoint();
            return false;
        }
    }

    /**
     * Sets a point
     * @param coordinates
     * @param type
     * @param srid
     * @param idx
     */
    public movePoint(coordinates: number[], type: PointType, srid: number, idx: number, fire: boolean): void {
        const feature = this.findFeature(type, idx);
        feature.setGeometry(new Point(coordinates));
    }

    public removeTempPoint() {
        const feature = this.findFeature(PointType.temp, null);
        if (feature) {
            this.points.getSource().removeFeature(feature);
        }
    }

    /**
     * Sets the route to the map
     * @param geoJson
     */
    public setRoute(geoJson: any): Routing { //, legendName: string, routeProperties: IRouteProperties): Routing {
        // this.isSimple = isSimple;
        const mapsrs = this.dmap.getCurrentSrs();
        const opts: any = {};
        if (geoJson.crs && Utils.epsgToSrid(geoJson.crs.properties.name) !== Utils.epsgToSrid(mapsrs)) {
            opts.dataProjection = geoJson.crs.properties.name;
            opts.featureProjection = mapsrs;
        }
        this.routePrintPropeties = null;
        const features = new GeoJSON(opts).readFeatures(geoJson);
        this.toCoordinates(features);
        this.addChunks(features);
        // this.setPrintPropertiesOld(geoJson, features, legendName, routeProperties);
        return this;
    }

    public setPrintProperties(routeProperties: IPrintProperties) {
        this.routePrintPropeties = routeProperties;
    }

    /**
     * Zooms the map to the route extent
     */
    public zoomToRoute(): Routing {
        const extent = this.getRouteExtent();
        if (extent) {
            this.zoomToExtent(extent as [number, number, number, number]);
        }
        return this;
    }

    /**
     * Zooms the map to the route extent
     */
    public zoomToPoints(): Routing {
        const extent = this.getPointsExtent();
        if (extent) {
            this.zoomToExtent(extent as [number, number, number, number]);
            const zoom = this.dmap.getScale();
            if (zoom < SCALE_FOR_SINGLE_POINT) {
                this.dmap.zoomToCenter(this.dmap.getCenter(), SCALE_FOR_SINGLE_POINT);
            }
        }
        return this;
    }

    public getRouteExtent(): number[] {
        const extent = this.lines.getSource().getExtent();
        if (extent[0] !== Infinity && extent[1] !== Infinity && extent[2] !== Infinity && extent[3] !== Infinity) {
            return extent;
        } else {
            return null;
        }
    }

    public getPointsExtent(): number[] {
        const extent = this.points.getSource().getExtent();
        if (extent[0] !== Infinity && extent[1] !== Infinity && extent[2] !== Infinity && extent[3] !== Infinity) {
            return extent;
        } else {
            return null;
        }
    }

    public getCommonExtent(): number[] {
        const points = this.getPointsExtent();
        const lines = this.getRouteExtent();
        if (!points) {
            return lines;
        } else if (!lines) {
            return points;
        } else {
            const ext = [];
            ext[0] = points[0] < lines[0] ? points[0] : lines[0];
            ext[1] = points[1] < lines[1] ? points[1] : lines[1];
            ext[2] = points[2] > lines[2] ? points[2] : lines[2];
            ext[3] = points[3] > lines[3] ? points[3] : lines[3];
            return ext;
        }
    }

    public getFeatureCount(type: PointType): number {
        let num = 0;
        for (const feature of this.points.getSource().getFeatures()) {
            if (feature.get(pointTypeName) === type) {
                num++;
            }
        }
        return num;
    }

    public makeRoundRoute(coordinates: number[]) {
        this.removePoint(PointType.end, null, true);
        for (let i = this.getViaPoints().length; i > 0; i--) {
            this.removePoint(PointType.via, i, true);
        }
        this.removePoint(PointType.start, null, true);
        this.roundRoute = new RoundRoute(this, this.points, coordinates);
        this.dmap.subscribeMapEvent(MapEventType.pointermove, this.roundRoute.getMove(), false);
        this.dmap.subscribeMapEvent(MapEventType.singleclick, this.roundRoute.getClick(), false);

    }

    public completeRoundRoute() {
        this.dmap.unsubscribeMapEvent(MapEventType.pointermove, this.roundRoute.getMove(), false);
        this.dmap.unsubscribeMapEvent(MapEventType.singleclick, this.roundRoute.getClick(), false);
        window.setTimeout(() => {
            this.roundRoute = null;
            this.pointSetted(this.findFeature(PointType.start));
            this.pointSetted(this.findFeature(PointType.via, 1));
            this.pointSetted(this.findFeature(PointType.via, 2));
            this.pointSetted(this.findFeature(PointType.via, 3));
            this.pointSetted(this.findFeature(PointType.end));
        }, 0);
    }

    protected addChunks(features: Feature[]) {
        for (const feature of features) {
            if (feature.get("color")) {
                const styles: Style[] = [];
                for (const style of this.lineStyle) {
                    if (style.getStroke()) {
                        const newStyle = style.clone();
                        newStyle.getStroke().setColor(feature.get("color"));
                        styles.push(newStyle);
                    }
                }
                feature.setStyle(styles);
            }
            this.lines.getSource().addFeature(feature);
        }
    }

    /**
     * Creates a route point
     * @param x
     * @param y
     * @param srid
     */
    protected createPoint(coordinates: Coordinate, srid: number): Point {
        return new Point(coordinates).transform(Utils.sridToEpsg(srid), this.dmap.getCurrentSrs()) as Point;
    }

    /**
     * Adds a route point
     * @param coordinates
     * @param type
     */
    protected addFeature(coordinates: Coordinate, srid: number, type: PointType, idx: number, fire: boolean): boolean {
        this.resetRoute();
        const feature = this.findFeature(type, idx);
        if (feature) {
            feature.setGeometry(new Point(coordinates));
            if (fire) {
                this.pointSetted(feature);
            }
            return true;
        } else {
            const newFeature = new Feature(this.createPoint(coordinates, srid));
            this.setValAndStyles(newFeature, type, idx);
            this.points.getSource().addFeature(newFeature);
            this.snap.addFeature(newFeature);
            if (fire) {
                this.pointSetted(newFeature);
            }
            return true;
        }
    }

    /**
     * Zooms to extent
     * @param extent
     * @protected
     */
    protected zoomToExtent(extent: [number, number, number, number]) {
        if (extent[0] !== Infinity && extent[1] !== Infinity && extent[2] !== Infinity && extent[3] !== Infinity) {
            this.dmap.zoomToExtent(extent, this.dmap.getCurrentSrs());
        }
    }

    /**
     * Removes a route point
     * @param coordinates
     * @param type
     */
    protected removeFeature(type: PointType, idx: number, fire: boolean): boolean {
        const toremove = this.findFeature(type, idx);
        if (toremove) {
            if (type === PointType.start || type === PointType.end) {
                this.points.getSource().removeFeature(toremove);
                this.snap.removeFeature(toremove);
                this.points.getSource().refresh();
                this.lines.getSource().clear();
                this.routePoints = [];
                if (fire) {
                    this.pointRemoved(toremove);
                }
            } else if (type === PointType.via) {
                const toChange = [];
                for (let i = idx; ; i++) {
                    const f = this.findFeature(type, i);
                    if (f) {
                        toChange.push(f);
                    } else {
                        break;
                    }
                }
                const endPoint = this.findFeature(PointType.end);
                if (endPoint && fire) {
                    this.pointRemoved(endPoint);
                }
                // remove items
                for (let i = toChange.length - 1; i >= 0; i--) {
                    this.points.getSource().removeFeature(toChange[i]);
                    this.snap.removeFeature(toChange[i]);
                    if (fire) {
                        this.pointRemoved(toChange[i]);
                    }
                }
                toChange.shift();
                // add moved items
                for (let i = 0, id = idx; i < toChange.length; i++, id++) {
                    this.setPoint((toChange[i].getGeometry() as Point).getCoordinates(),
                        PointType.via, this.getCurrentSrid(), id, fire);
                    this.snap.addFeature(this.findFeature(type, id));
                }
                this.points.getSource().refresh();
                if (endPoint && fire) {
                    this.pointSetted(endPoint);
                }
            }
            return true;
        }
        return false;
    }

    protected pointSetted(feature: Feature) {
        this.dmap.getTargetElement().dispatchEvent(
            new CustomEvent("routingPointSetted",
                {
                    detail: {
                        routing: {
                            point: {
                                coordinates: (feature.getGeometry() as Point).getCoordinates(),
                                idx: feature.get(numberName),
                                srid: this.getCurrentSrid(),
                                type: feature.get(pointTypeName),
                            },
                        },
                    },
                }));
    }

    protected pointRemoved(feature: Feature) {
        this.dmap.getTargetElement().dispatchEvent(
            new CustomEvent("routingPointRemoved",
                {
                    detail: {
                        routing: {
                            point: {
                                coordinates: (feature.getGeometry() as Point).getCoordinates(),
                                idx: feature.get(numberName),
                                srid: this.getCurrentSrid(),
                                type: feature.get(pointTypeName),
                            },
                        },
                    },
                }));
    }

    private getPointCount(type: PointType): number {
        let count = 0;
        for (const feat of this.points.getSource().getFeatures()) {
            if (feat.get(pointTypeName) === type) {
                count++;
            }
        }
        return count;
    }

    private generateId(type: PointType, idx: number = null) {
        if (type === PointType.start || type === PointType.end) {
            return type;
        }
        return type + (idx !== null && idx !== undefined ? "-" + idx : "");
    }

    private findFeature(type: PointType, idx: number = null): Feature {
        return this.points.getSource().getFeatureById(this.generateId(type, idx));
    }

    private setValAndStyles(f: Feature, type: PointType, idx: number) {
        f.set(pointTypeName, type);
        f.setId(this.generateId(type, idx));
        if (type === PointType.start) {
            f.setStyle(this.startStyle);
        } else if (type === PointType.end) {
            f.setStyle(this.endStyle);
        } else if (type === PointType.via) {
            f.set(numberName, idx);
            f.setStyle(Styles.textFromFeature(f, [numberName], this.viaStyle));
        } else if (type === PointType.temp) {
            f.setStyle(this.tempStyle);
        }
    }

    private onClick(e: MapBrowserEvent) {
        if (!e.dragging) {
            // window.console.warn(e);
        }
    }

    private toCoordinates(features: Feature[]) {
        this.routePoints = [];
        for (const line of features) {
            this.routePoints = this.routePoints.concat((line.getGeometry() as LineString).getCoordinates());
        }
        // window.console .log(this.simplifyRoute(1000));
    }

    private simplifyRoute(tolerance: number = 100) {
        const coords = [];
        if (this.routePoints) {
            let lastIn = null;
            for (const p of this.routePoints) {
                if (lastIn === null || Math.abs(p[0] - lastIn[0]) > tolerance || Math.abs(p[1] - lastIn[1]) > tolerance) {
                    coords.push(p);
                    lastIn = p;
                }
            }
            const lastP = this.routePoints[this.routePoints.length - 1];
            if (lastP[0] !== lastIn[0] || lastP[1] !== lastIn[1]) {
                coords.push();
            }
        }
        return coords;
    }

    private getLayerPrintOptions(layer: Vector, extent: number[] = null): IVectorPrintOptions {
        const features: Feature[] = layer.getSource().getFeatures(); // getFeaturesInExtent(extent);
        if (features.length === 0) {
            return null;
        }
        const fc = [];
        for (const feature of features) {
            const origFeature: any = new GeoJSON().writeGeometryObject(feature.getGeometry());
            const tmpStyles = feature.getStyle() ? feature.getStyle() : layer.getStyle();
            if (tmpStyles) {
                const list: Style[] = tmpStyles instanceof Array ? tmpStyles : [tmpStyles] as Style[];
                for (const style of list) {
                    const feat = list.length > 1 ? Utils.merge({}, origFeature) : origFeature;
                    feat.style = StylesToMb3.toSingleStyle(style, false);
                    fc.push(feat);
                }
            }
        }
        return {
            attribution: layer.getSource().get(ATTRIBUTION_KEY),
            geometries: fc,
            opacity: 1,
            sourceId: layer.get(MODEL).getUuid(),
            type: "GeoJSON+Style",
        };
    }

    private setPrintPropertiesOld(geoJson: any, features: Feature[], legendName: string, routeProps: IRouteProperties) {
        if (features.length > 0) {
            const firstGeom = features[0].getGeometry();
            const lastGeom = features[features.length - 1].getGeometry();
            if (firstGeom instanceof LineString && lastGeom instanceof LineString) {
                const firstCoord = firstGeom.getFirstCoordinate();
                const lastCoord = lastGeom.getLastCoordinate();
                const km = geoJson.properties && geoJson.properties.km ? geoJson.properties.km : null;
                const fromArrOrNum = (km: number | number[], asM: boolean = false, num: number = 0) => {
                    Array.isArray(km) ? km.map((val: number) => {
                        num = num + val;
                        return val;
                    }) : num = km;
                    return asM || Math.round(num) === 0 ? (Math.round(num * 1000) + " m") : (Math.round(num) + " km");
                };
                this.routePrintPropeties = {
                    ascending: fromArrOrNum(km.ascending, true),
                    descending: fromArrOrNum(km.descending, true),
                    length: fromArrOrNum(km.distance, false),
                    startpoint: routeProps.startTitle,
                    endpoint: routeProps.endTitle,
                    route: routeProps.name,
                    description: routeProps.description,
                    route_logo: routeProps.logo,
                    route_instructions: routeProps.instructions,
                    legend_label: legendName
                };
            }
        }
    }
}
