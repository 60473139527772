import Vector from "ol/layer/Vector";
import {DMap} from "./DMap";
import {OlPopup} from "./OlPopup";
import {OverlayTemplate} from "./OverlayTemplate";
import {MapEventType} from "./MapEventObserver";
import {AMulitFeatureInteraction} from "./interaction/AMultiFeatureInteraction";
import Feature from "ol/Feature";
import {Coordinate} from "ol/coordinate";

export class FeatureSelect extends AMulitFeatureInteraction {

    protected overlays: Map<Vector, OverlayTemplate>;
    protected popup: OlPopup;

    public constructor(dmap: DMap, interactionLayer: Vector) {
        super(dmap, MapEventType.singleclick, interactionLayer);
        this.overlays = new Map<Vector, OverlayTemplate>();
    }

    /**
     * @inheritDoc
     */
    public unregister(layer: Vector) {
        super.unregister(layer);
        if (this.overlays.has(layer)) {
            this.overlays.delete(layer);
        }
    }

    /**
     * Adds an overlay
     * @param layer
     * @param template
     */
    public addOverlay(layer: Vector, template: OverlayTemplate): boolean {
        if (this.interactionLayer.has(layer)) {
            this.overlays.set(layer, template);
            return true;
        }
        return false;
    }

    /**
     * Lists features for registrated layers at the mouse position
     * @param e
     */
    public eventEnd(coordinate: [number, number]): void {
        this.getPopup().hide();
        // const self = this;
        this.last.forEach((features: Map<Feature, Feature>, layer: Vector) => {
            const lastFeatures: Set<Feature> = new Set<Feature>(features.keys());
            this.removeFeatures(layer, Array.from(lastFeatures).filter(
                (x: Feature) => !this.eventFeatures.get(layer).has(x) || this.eventFeatures.get(layer).has(x)));
            this.addOverlayFeatures(
                coordinate,
                layer,
                Array.from(this.eventFeatures.get(layer)).filter((x: Feature) => !lastFeatures.has(x)));
            this.eventFeatures.get(layer).clear();
        });
    }

    /**
     * Gets a popup
     */
    protected getPopup(): OlPopup {
        if (!this.popup) {
            const p = document.createElement("div");
            // p.classList.add("first");
            this.dmap.getMap().getTargetElement().parentElement.appendChild(p);
            const options = {
                autoPan: true,
                autoPanAnimation: {
                    duration: 250,
                    source: undefined,
                },
                // className: "ol-popup",
                element: p,
                // positioning: "center-left",
            };
            this.popup = new OlPopup(options, true);
            this.popup.getElement().classList.add("ol-popup");
            this.dmap.getMap().addOverlay(this.popup);
        }
        return this.popup;
    }

    /**
     * Selects features and shows a popup with feature information.
     * @param coordinate
     * @param layer
     * @param features
     */
    protected addOverlayFeatures(coordinate: Coordinate, layer: Vector, features: Feature[]): void {
        let added: boolean = false;
        this.addFeatures(layer, features);
        for (const feature of features) {
            if (this.overlays.has(layer)) {
                const o: any = {};
                for (const name of this.overlays.get(layer).getNames()) {
                    o[name] = feature.get(name) ? feature.get(name) : "";
                }
                this.getPopup().setContent(this.overlays.get(layer).render(o));
                added = true;
            }
        }
        if (added) {
            this.getPopup().show(coordinate);
        }
    }
}
