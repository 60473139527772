/**
 * @author Paul Schmidt<panadium@gmx.de>.
 */

/**
 * Class Eu
 */
export class Eu {

    /**
     * Creates a HTMLElement
     * @param {string} tagname
     * @return {HTMLElement}
     */
    public static create(tagname: string,
                         cssClass: string[] = null,
                         attributes: any = null,
                         text: string = ""): HTMLElement {
        const elm = document.createElement(tagname);
        Eu.change(elm, cssClass, attributes, text);
        return elm;
    }

    public static change(elm: HTMLElement,
                         cssClass: string[] = null,
                         attributes: any = null,
                         text: string = ""): HTMLElement {
        if (text) {
            elm.textContent = text;
        }
        if (cssClass) {
            Eu.addClass(elm, cssClass);
        }
        if (attributes) {
            Eu.addAttr(elm, attributes);
        }
        return elm;
    }

    /**
     * Adds a list of attributes to the element
     * @param {HTMLElement} elm
     * @param attributes
     */
    public static addAttr(elm: HTMLElement, attributes: any): void {
        for (const key of Object.keys(attributes)) {
            elm.setAttribute(key, attributes[key]);
        }
    }

    /**
     * Checks whether the element has a given class
     * @param {string} cssClass
     * @return {boolean}
     */
    public static hasClass(elm: HTMLElement, cssClass: string): boolean {
        return elm.classList.contains(cssClass);
    }

    /**
     * Adds to the element a given class or given classes.
     * @param {string | string[]} cssClasses
     */
    public static addClass(elm: HTMLElement, cssClasses: string[]): void {
        for (const cssClass of cssClasses) {
            if (cssClass && !elm.classList.contains(cssClass)) {
                elm.classList.add(cssClass);
            }
        }
    }

    /**
     * Romoves a given class from the element
     * @param {HTMLElement} elm
     * @param {string} cssClass
     */
    public static removeClass(elm: HTMLElement, cssClasses: string[]): void {
        for (const cssClass of cssClasses) {
            if (cssClass && elm.classList.contains(cssClass)) {
                elm.classList.remove(cssClass);
            }
        }
    }

    /**
     * @param context
     * @param child
     */
    public static append(context: HTMLElement, child: HTMLElement[] | HTMLElement | string): void {
        if (child instanceof Array) {
            for (const elm of child) {
                Eu.append(context, elm);
            }
        } else if (child instanceof HTMLElement) {
            context.appendChild(child); // insertAdjacentElement('beforeend', child);
        } else {
            context.insertAdjacentHTML("beforeend", child as string);
        }
    }

    /**
     *
     * @param context
     * @param child
     */
    public static prepend(context: HTMLElement, child: HTMLElement[] | HTMLElement | string): void {
        if (child instanceof Array) {
            for (const elm of child) {
                Eu.append(context, elm);
            }
        } else if (child instanceof HTMLElement) {
            context.insertAdjacentElement("afterbegin", child);
        } else {
            context.insertAdjacentHTML("afterbegin", child as string);
        }
    }

    /**
     * Adds an given element before the element
     * @param {Ee | HTMLElement | string} element
     * @return {Ee}
     */
    public static before(context: HTMLElement, element: HTMLElement[] | HTMLElement | string): void {
        if (element instanceof Array) {
            for (const elm of element.reverse()) {
                Eu.append(context, elm);
            }
        } else if (element instanceof HTMLElement) {
            context.insertAdjacentElement("beforebegin", element as HTMLElement);
        } else {
            context.insertAdjacentHTML("beforebegin", element as string);
        }
    }

    /**
     * Adds a given element after the element
     * @param {Ee | HTMLElement | string} element
     * @return {Ee}
     */
    public static after(context: HTMLElement, element: HTMLElement[] | HTMLElement | string): void {
        if (element instanceof Array) {
            for (const elm of element) {
                Eu.append(context, elm);
            }
        } else if (element instanceof HTMLElement) {
            context.insertAdjacentElement("afterend", element);
        } else {
            context.insertAdjacentHTML("afterend", element as string);
        }
    }

    /**
     * Finds all elements for a given selector
     * @param {string} selector
     * @param {HTMLElement} context
     * @return {NodeListOf<Element>}
     */
    public static elmAll(selector: string, context?: HTMLElement): NodeListOf<Element> {
        if (context) {
            return context.querySelectorAll(selector);
        } else {
            return document.body.querySelectorAll(selector);
        }
    }

    /**
     * Finds the first element for a given selector
     * @param {string} selector
     * @param {HTMLElement} context
     * @return {HTMLElement}
     */
    public static elmFirst(selector: string, context?: HTMLElement): HTMLElement | null {
        if (context) {
            return context.querySelector(selector) as HTMLElement;
        } else {
            return document.body.querySelector(selector) as HTMLElement;
        }
    }

    /**
     *
     * @param {string} selector
     * @param {HTMLElement} context
     * @return {HTMLElement | null}
     */
    public static parent(selector: string, context: HTMLElement): HTMLElement | null {
        let parent = context.parentNode as HTMLElement;
        while (parent && typeof parent.matches === "function" && parent.matches(selector) === false) {
            parent = (parent.parentNode as HTMLElement);
        }
        return typeof parent.matches === "function" && parent.matches(selector) ? parent : null;
    }

    /**
     * Finds and removes all elements for a given selector
     * @param {string} selector
     * @param {HTMLElement} context
     * @return {NodeListOf<Element>}
     */
    public static removeAll(selector: string, context?: HTMLElement): NodeListOf<Element> {
        let list = null;
        if (context) {
            list = context.querySelectorAll(selector);
        } else {
            list = document.body.querySelectorAll(selector);
        }
        if (list !== null) {
            for (let i = 0; i < list.length; i++) {
                list.item(i).removeData();
            }
        }
        return list;
    }

    /**
     * Removes all children for a given element
     * @param element
     */
    public static removeChildren(element: HTMLElement): void {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }

    /**
     *
     * @param {string} eventType
     * @param handler
     * @param {boolean} useCapture
     * @param {string} selector
     * @param {HTMLElement} context
     */
    public static eventAdd(eventType: string,
                           selector: string,
                           handler: any,
                           useCapture: boolean = false,
                           context?: HTMLElement): void {
        const elms = Eu.elmAll(selector, context);
        for (let i = 0; i < elms.length; i++) {
            elms.item(i).addEventListener(eventType, handler, useCapture);
        }
    }

    /**
     *
     * @param {string} eventType
     * @param handler
     * @param {boolean} useCapture
     * @param {string} selector
     * @param {HTMLElement} context
     */
    public static eventRemove(eventType: string,
                              selector: string,
                              handler: any,
                              useCapture: boolean = false,
                              context?: HTMLElement): void {
        const elms = Eu.elmAll(selector, context);
        for (let i = 0; i < elms.length; i++) {
            elms.item(i).removeEventListener(eventType, handler, useCapture);
        }
    }
}

// const $ = document.querySelector.bind(document);
// const on = (element, event, handler) => {
//     element.addEventListener(event, handler);
// }
