import {Eu} from "../client/service/Eu";
import {IUserRouteOptions} from "./IUserRouteOptions";

export class UserRouteOptions {
    private _options: {[key: string]: boolean | string};

    public constructor(traffic_type: string) {
        this.init(traffic_type);
    }

    public init(traffic_type: string) {
        if (traffic_type) {
            this.resetTrafficType(traffic_type);
        } else {
            this.resetTrafficType((Eu.elmFirst('input[name=traffic_type]:checked') as HTMLInputElement).value);
        }

        Eu.eventAdd("click", 'input[name=traffic_type]', (e: Event) => {
            this.resetTrafficType((e.target as HTMLInputElement).value);
        })
    }

    private toBool(selector: string, context: HTMLElement) {
        return (context.querySelector(selector) as HTMLInputElement).checked;
    }

    private setInput(selector: string, context: HTMLElement, val: boolean) {
        (context.querySelector(selector) as HTMLInputElement).checked = val;
    }

    private toVal(selector: string, context: HTMLElement) {
        return (context.querySelector(selector) as HTMLInputElement).value;
    }

    public setOptions(options: IUserRouteOptions) {
        if (options.thematicroutes !== undefined) {
            this.options;
            if (this.options.touristic === undefined) {
                (document.querySelector('#traffic_type_2') as HTMLInputElement).click();
            }
            const elm = document.body;
            const lab = Eu.elmFirst('[for=use_touristic_thematicroutes]', elm);
            if (options.thematicroutes && !Eu.hasClass(Eu.elmFirst(lab.getAttribute("data-target")), "show")) {
                this.setInput("#use_touristic_thematicroutes", elm, options.thematicroutes);
                lab.setAttribute("aria-expanded", options.thematicroutes.toString());
                Eu.addClass(Eu.elmFirst(lab.getAttribute("data-target")), ["show"]);
            }
        }
    }

    public get options(): { [key: string]: boolean | string } {
        const elm = document.body;
        // elm.querySelector()
        if (this.toBool('#traffic_type_1', elm) && this.toVal('#traffic_type_1', elm) === 'everyday') {
            this._options = {
                'everyday': true,
                'noferry': this.toBool('#everyday_noferry', elm),
                'tagged': this.toBool('#everyday_tagged', elm),
                'shortest': this.toBool('#everyday_shortest', elm),
                'fastest': this.toBool('#everyday_fastest', elm),
                'stairs': this.toBool('#everyday_usestairs', elm),
                'best': false,
                'thematicroutes': false,
                'paved_only': false,
                'paved': false,
            };
        } else if (this.toBool('#traffic_type_2', elm)
            && this.toVal('#traffic_type_2', elm) === 'touristic') {
            this._options = {
                'touristic': true,
                'noferry': this.toBool('#touristic_noferry', elm),
                'tagged': this.toBool('#touristic_tagged', elm),
                'shortest': this.toBool('#touristic_shortest', elm),
                'fastest': this.toBool('#touristic_fastest', elm),
                'stairs': this.toBool('#touristic_usestairs', elm),
                'best': false,
                'thematicroutes': false,
                'paved_only': false,
                'paved': false,
            };
            if (this.toBool('#use_touristic_thematicroutes', elm)) {
                if (this.toBool('#touristic_thematicroutes1', elm)) {
                    this._options['routepreference'] = "thematicroutes";
                }
                this._options['thematicroutes'] = this.toBool('#touristic_thematicroutes2', elm);
            }
            if (this.toBool('#use_touristic_paved', elm)) {
                this._options['paved_only'] = this.toBool('#touristic_paved1', elm);
                this._options['paved'] = this.toBool('#touristic_paved2', elm);
            }
        }
        return this._options;
    }


    private resetTrafficType(traffictype: string) {
        const elm = document.body;
        Eu.addClass(Eu.elmFirst('#touristic_options'),["hidden"]);
        Eu.addClass(Eu.elmFirst('#everyday_options'),["hidden"]);
        if (traffictype === "everyday") {
            this.setInput('#traffic_type_1', elm, true);
            const form = document.querySelector('#everyday_options') as HTMLElement;
            this.setInput('input[name=everyday_shortest][value=shortest]', form, true);
            this.setInput('#everyday_tagged', elm, false);
            this.setInput('#everyday_noferry', elm, false);
            this.setInput('#everyday_usestairs', elm, false);
            Eu.removeClass(form, ["hidden"]);
        } else if (traffictype === "touristic") {
            this.setInput('#traffic_type_2', elm, true);
            const form = document.querySelector('#touristic_options') as HTMLElement;
            this.setInput('input[name=touristic_shortest][value=shortest]', form, true);
            this.setInput('#touristic_thematicroutes1', elm, true);
            this.setInput('#touristic_paved2', elm, true);
            this.setInput('#touristic_tagged', elm, false);
            this.setInput('#touristic_noferry', elm, false);
            this.setInput('#touristic_usestairs', elm, false);
            Eu.removeClass(form, ["hidden"]);
            const lab = Eu.elmFirst('[for=use_touristic_thematicroutes]', elm);
            if (!Eu.hasClass(Eu.elmFirst(lab.getAttribute("data-target")), "show")) {
                this.setInput("#use_touristic_thematicroutes", elm, true);
                lab.setAttribute("aria-expanded", "true");
                Eu.addClass(Eu.elmFirst(lab.getAttribute("data-target")), ["show"]);
            }
            const $butPav = Eu.elmFirst('[for=use_touristic_paved]', elm);
            if (Eu.hasClass(Eu.elmFirst($butPav.getAttribute("data-target")), "show")) {
                this.setInput("#use_touristic_paved", elm, false);
                $butPav.setAttribute("aria-expanded", "false");
                Eu.removeClass(Eu.elmFirst($butPav.getAttribute("data-target")), ["show"]);
            }
        }
    }
}
