import {DMap} from "./DMap";
import {MapEventType} from "./MapEventObserver";
import Vector from "ol/layer/Vector";
import {AMulitFeatureInteraction} from "./interaction/AMultiFeatureInteraction";

export class FeatureHighlight extends AMulitFeatureInteraction {

    public constructor(dmap: DMap, interactionLayer: Vector) {
        super(dmap, MapEventType.pointermove, interactionLayer);
    }
}
