export class Translation {

    public static getInstance(): Translation {
        if (!Translation.instance) {
            Translation.instance = new Translation();
        }
        return Translation.instance;
    }
    private static instance: Translation;

    private data: { [key: string]: string };

    private constructor() {
        this.data = {};
    }

    public setData(data: { [key: string]: string }): void {
        this.data = data;
    }

    public addItem(name: string, translation: string): void {
        this.data[name] = translation;
    }

    public trans(name: string): string {
        return this.data[name] ? this.data[name] : name;
    }

    public transTemplate(template: string): string {
        let translated = ""+template;
        const items = translated.match(/trans\([^\)]+\)?/gi); // not nested "trans"
        if (items !== null && items.length > 0) {
            for (const item of items) {
                const term = item.replace(/trans\(/i, "").replace(/\)$/i, "");
                translated = translated.replace("trans(" + term + ")", this.trans(term)); // self._getTranslatedText(term));
            }
        }
        return translated;
    }
}
const trans = Translation.getInstance();
