import {AInteractionHandler} from "./AInteractionHandler";
import Feature from "ol/Feature";
import Style, {StyleFunction} from "ol/style/Style";
import {Vector} from "ol/layer";
import {Styles} from "../Styles";
import {MapEventType} from "../MapEventObserver";
import {DMap} from "../DMap";

export class AudioRouteHighlicht extends AInteractionHandler {

    protected propertyNames: string[] = [];

    /**
     * Creates an instance
     * @param mapEvent
     * @param dmap
     * @param layer
     * @param styles
     */
    public constructor(mapEvent: MapEventType, dmap: DMap, layer: Vector, styles: Style[] = []) {
        super(mapEvent, dmap, layer, styles);
        this.setPropertyNames();
    }

    protected setPropertyNames() {
        for (const style of this.interactionStyles) { // Style
            // this.guessPropertyName(style);
            const props = Styles.guessPropertyName(style);
            for (const prop of props) {
                if (this.propertyNames.indexOf(prop) === -1) {
                    this.propertyNames.push(prop);
                }
            }
        }
    }

    private getStyles(feature: Feature) {
        if (this.propertyNames.length > 0) {
            return Styles.textFromFeature(feature, this.propertyNames, this.interactionStyles);
        } else {
            return this.interactionStyles;
        }
    }

    /**
     * Adds a feature into an interaction layer
     * @param layer
     * @param feature
     * @param style
     */
    protected interactionOn(coordinate: [number, number], feature: Feature): boolean {
        if (!this.last.has(feature)) {
            const styles: (Style | Style[] | StyleFunction) = feature.getStyle();
            this.last.set(feature, styles instanceof Array ? styles : [styles] as Style[]);
            // change styles
            feature.setStyle(this.getStyles(feature));
            return true;
        }
        return false;
    }
}
