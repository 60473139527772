import {PostProcessor} from "./PostProcessor";
import {IGmlPostProcessorOptions} from "./IGmlPostProcessorOptions";
import {Translation} from "../../../Translation";

export class GmlPostProcessor extends PostProcessor {
    protected options: IGmlPostProcessorOptions[];

    public constructor(options: IGmlPostProcessorOptions[]) {
        super(options);
    }

    protected translateTemplate(options: IGmlPostProcessorOptions[]): IGmlPostProcessorOptions[] {
        for (const item of options) {
            item.template = Translation.getInstance().transTemplate(item.template);
        }
        return options;
    }

    public process(data: string): string {
        const output = [];
        const gml = new DOMParser().parseFromString(data, "text/html");
        const uniqueBy = this.geTemplateForUnique(this.options);
        for (const processorOptions of this.options) {
            const content = this.processGml(gml, processorOptions, uniqueBy);
            if (content) {
                output.push(content);
            }
        }
        return output.join("<br/>");
    }

    private geTemplateForUnique(options: IGmlPostProcessorOptions[]): { [key: string]: any } {
        let t = null;
        for (const item of options) {
            if (item.uniqueBy) {
                if (!t) {
                    t = {};
                }
                t[item.uniqueBy] = [];
            }
        }
        return t;
    }

    private processGml(gml: Document, options: IGmlPostProcessorOptions, uniqueBy: { [key: string]: any }): string {
        const result = gml.querySelectorAll(options.path);
        const output = [];
        if (result) {
            for (const item of Array.from(result)) {
                const content = this.processItem(item, options, uniqueBy);
                if (content) {
                    output.push(content);
                }
            }
        }
        return output.join("<br/>");
    }

    private processItem(item: Element, options: IGmlPostProcessorOptions, uniqueBy: { [key: string]: any }) {
        let template = ""+options.template;
        let groupBy = null;
        let uniqueGroup = null;
        const keys = Object.keys(options.properties);
        for (const key of keys) {
            if (uniqueBy && uniqueBy[key]) {
                groupBy = key;
            }
            // const attrPos = options.properties[key].search(/\[[^\]]+\]/); // TODO support attr at path
            const founded = item.querySelector(key);
            if (founded) {
                let val;
                if (founded.textContent) {
                    val = founded.textContent;
                } else if (founded.nextSibling.textContent.trim()) {
                    val = founded.nextSibling.textContent.trim();
                } else {
                    val = founded.textContent;
                }
                if(val === "" && options.properties[key] === "notempty") {
                    return "";
                }
                if (groupBy === key) {
                    uniqueGroup = val;
                }
                let num = 0;
                while (template.search(RegExp("\\${" + key + "}")) !== -1 && num < 10) {
                    template = template.replace(RegExp("\\${" + key + "}"), val);
                    num++;
                }
            } else {
                return "";
            }
        }
        if (!groupBy) {
            return template;
        } else if (uniqueBy && uniqueBy[groupBy].indexOf(uniqueGroup) === -1) {
            uniqueBy[groupBy].push(uniqueGroup);
            return template;
        } else {
            return "";
        }
    }
}
