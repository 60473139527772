export class DownloadAnchor {
    private anchor: HTMLAnchorElement;
    private url: string;
    private method: string;
    private body: string;

    public create(
        url: string,
        method: string = "POST",
        target: string = "_blank"
    ): DownloadAnchor {
        this.anchor = document.createElement("a");
        this.url = url;
        this.method = method;
        this.anchor.target = target;
        document.body.appendChild(this.anchor);
        return this;
    }

    public addFields(parameters: { [key: string]: any }) {
        this.body = Object.entries(parameters)
            .map(([key, value]) => {
                if (typeof value === "object" && !(value instanceof String)) {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(
                        JSON.stringify(value)
                    )}`;
                } else {
                    return `${encodeURIComponent(key)}=${encodeURIComponent(
                        value
                    )}`;
                }
            })
            .join("&");
        return this;
    }

    public submit(destroyAfter: boolean = true) {
        fetch(this.url, {
            method: this.method,
            headers: {
                "cache-control": "max-age=0",
                "content-type": "application/x-www-form-urlencoded",
            },
            body: this.body,
        })
            .then((res) => {
                this.anchor.download =
                    res?.headers
                        ?.get?.("Content-Disposition")
                        ?.split?.("filename=")?.[1] ||
                    "radwanderland_route.pdf";

                return res.blob();
            })
            .then((blob) => {
                this.anchor.href = window.URL.createObjectURL(blob);
                this.anchor.click();
                if (destroyAfter) {
                    this.anchor.remove();
                }
            });
        return this;
    }
}
