import {RadisRoute} from "./RadisRoute";
import {RoutePoint} from "./RoutePoint";
import {PointType} from "../map/routing/IRoutePoint";
import {IRoutePlannerOptions} from "./IRoutePlannerOptions";
import {ARoute, ConnType, route_type} from "./ARoute";
import {Routing} from "../map/routing/Routing";
import {Utils} from "../map/geom/Utils";
import {LBMChart} from "../routeplanner/Chart";
import {IPrintProperties} from "../map/print/IPrintProperties";
import {RouteLegends} from "./RouteLegends";
import {RouteComponents} from "./RouteComponents";
import {RouteForm} from "./RouteForm";
import {UrlHelper} from "../client/service/UrlHelper";
import {DeeplinkHandler} from "../map/source/DeeplinkHandler";
import {IDeeplinkOpts} from "../map/source/IDeeplinkOpts";
import {UserRoute} from "./UserRoute";
import {renderError} from "../App";
import {IRoutePoints} from "./IRoutePoints";
import {IUserRouteOptions} from "./IUserRouteOptions";
import {HTTP_METHOD, HttpClient} from "../client/service/HttpClient";

export class RoutePlanner {
    private static instance: RoutePlanner;

    public static create(options: IRoutePlannerOptions) {
        if (!RoutePlanner.instance) {
            RoutePlanner.instance = new RoutePlanner(options);
        }
        return RoutePlanner.instance;
    }

    public static getInstance() {
        return RoutePlanner.instance;
    }

    private crs: string = "EPSG:25832";
    private route: ARoute;
    private options: IRoutePlannerOptions;
    private elevationProfile: LBMChart;
    private routeLegends: RouteLegends;
    private routeComponents: RouteComponents;
    private routeform: RouteForm;
    private nocache: boolean = false;

    private constructor(options: IRoutePlannerOptions) {
        this.options = options;
        // console .log(options);
        this.createRoute();
        this.createElevationProfile();
        this.createRouteLegends();
        this.createRouteComponents();
        this.createRouteForm();
        this.calculateRoute();
    }

    private createRoute() {
        if (this.options.radisRouteUrl) { // create named
            this.route = new RadisRoute(this.options.radisRouteUrl, this.crs);
        } else {
            this.route = new UserRoute(this.options.userRouteUrl, this.crs);
        }
    }

    private createRouteForm() {
        if (this.options.routeform) {
            const options = {...this.options.routeform, ...{addressUrl: this.options.addressUrl}};
            this.routeform = RouteForm.create(options);
        }
    }

    private createElevationProfile() {
        if (this.options.elevationprofile && document.getElementById(this.options.elevationprofile.target)) {
            this.elevationProfile = LBMChart.create(this.options.elevationprofile);
        }
    }

    private createRouteLegends() {
        if (this.options.routelegends && this.options.legends) {
            this.routeLegends = new RouteLegends(
                document.getElementById(this.options.routelegends.target),
                this.options.legends, this);
        }
    }

    private createRouteComponents() {
        if (this.options.routecomponents) {
            this.routeComponents = RouteComponents.create(this.options.routecomponents, this);
            this.showAdditionalInformation(false);
        }
    }

    private getLoadEvents() {
        return {
            onloadstart: () => {
                document.getElementById("loader").style.display = "block";
            },
            onloadend: () => {
                document.getElementById("loader").style.display = "none";
            }
        };
    }

    private showAdditionalInformation(show: boolean) {
        if (this.routeComponents) {
            this.routeComponents.showAdditionalInformation(show);
        }
    }

    public setNocache(nocache: boolean) {
        this.nocache = nocache;
    }

    public setRouteOptions(options: IUserRouteOptions) {
        if (this.routeform) {
            this.routeform.setRouteOptions(options);
        }
    }

    public getElevationProfile() {
        return this.elevationProfile;
    }

    public getPrintProperties(): IPrintProperties {
        const printProps: IPrintProperties = this.route.getPrintProperties(this.routeLegends.getLegendsTitle());
        return printProps;
    }

    public getLegends() {
        return this.routeLegends ? this.routeLegends.getLegends() : null;
    }

    public setRouteView(type: route_type) {
        this.route.setRouteType(type);
        if (this.route.isCalculated()) {
            Routing.getInstance().resetRoute();
            Routing.getInstance().setRoute(this.route.getRoute());
        }
    }

    public getShareLink(): Promise<any> {
        if (this.route instanceof RadisRoute) {
            const routeUrl = UrlHelper.URLForStr(this.route.getUrl());
            return new Promise((resolve, reject) => {
                resolve(UrlHelper.joinQs(window.location.href, routeUrl.searchParams));
            });
        } else if (DeeplinkHandler.getInstance()) {
            const dl = DeeplinkHandler.getInstance();
            const data: any = {
                transportation: null
            };
            const points = this.route.getPoints();
            let idx = 0;
            const l = points.length;
            for (; idx < l; idx++) {
                const type = idx === 0 ? PointType.start : (idx === points.length - 1 ? PointType.end : PointType.via);
                // const point = this.pointToShareLinkPoint(points[idx]);
                const point = points[idx].toOptions();
                const isStation = false;
                switch (type) {
                    case PointType.start:
                        data.startPoint = point;
                        break;
                    case PointType.end:
                        data.endPoint = point;
                        break;
                    case PointType.via:
                        if (!data.intermediatePoints) {
                            data.intermediatePoints = []
                        }
                        data.intermediatePoints.push(point);
                        break;
                }
            }
            return dl.saveDeeplink(data);
        }
    }

    public routeFromShareLink(deeplink: IDeeplinkOpts) {
        if (!deeplink.route || !deeplink.route.startPoint || !deeplink.route.endPoint) {
            return;
        }
        const route = deeplink.route;
        this.routeFromPoints(route);
    }

    public routeFromPoints(route: IRoutePoints) {
        this.route.setStart(RoutePoint.fromOptions(route.startPoint));
        if (route.intermediatePoints) {
            let idx = 1;
            for (const via of route.intermediatePoints) {
                this.route.addVia(RoutePoint.fromOptions(via), idx++);
            }
        }
        this.route.setEnd(RoutePoint.fromOptions(route.endPoint));
        this.synchronizeConnection();
        this.synchronizePoints();
        this.calculateRoute();
    }

    public calculateRoute() {
        if (!this.route.isRequestValid()) {
            return;
        }
        if (this.route instanceof UserRoute && !!this.routeform === true) {
            this.route.setOptions(this.routeform.getOptions());
        }
        this.showAdditionalInformation(false); // todo
        const params = this.route.parametersForCalculate();
        HttpClient.sendData(
            this.route.getUrl(),
            this.nocache && this.route instanceof UserRoute? {...params, ...{nocache: true}} : params,
            HTTP_METHOD.GET,
            "json",
            this.getLoadEvents()
        )
            .then((data) => {
                if (data.error) {
                    renderError(data.error);
                } else {
                    this.setCalculated(data);
                    this.showAdditionalInformation(true);
                }
            })
            .catch((error: Error) => {
                console.error(error.message);
            });
    }

    public getPointTitle(idx: number) {
        return this.route.getPoint(idx).getTitle();
    }

    private setCalculated(data: any) {
        this.route.setCalculated(data);
        this.synchronizePoints();
        Routing.getInstance().setRoute(this.route.getRoute());
        Routing.getInstance().setAudio(this.route.getCalculated().audio);
        Routing.getInstance().zoomToRoute();
        if (this.elevationProfile) {
            this.elevationProfile.renderData(this.route.getElevationData());
        }
        if (this.routeComponents) {
            this.routeComponents.setFromRoute(this.route);
        }
        if (this.routeLegends) {
            this.routeLegends.setVisible(data.totalDistanceValue !== null);
        }
    }

    public reverseRoute() {
        this.route.reverseRoute();
        this.synchronizeConnection();
        this.synchronizePoints();
        this.calculateRoute();
    }

    public movePoint(from: number, to: number) {
        this.route.movePoint(from, to);
        this.synchronizeConnection();
        this.synchronizePoints();
        this.calculateRoute();
    }

    public changeConnection(idx: number, conn: ConnType) {
        this.showAdditionalInformation(false); // todo
        const point = this.route.getPoint(idx);
        if (point.getConnection() !== conn) {
            point.setConnection(conn);
            point.setCoordinates(null);
            point.setTitle(null);
            point.setStation(null);
        }
        this.synchronizeConnection();
        this.synchronizePoints();
    }

    public resetPoint(coordinates: number[], label: string, station: string, type: PointType, idx: number) {
        const point = this.route.getPoint(idx);
        point.setCoordinates(coordinates);
        point.setTitle(label);
        point.setStation(station);
        this.synchronizeConnection();
        this.synchronizePoints();
        this.calculateRoute();
    }

    public checkPointValue(type: PointType, idx: number, title: string) {
        const point = this.route.getPoint(idx);
        if (point.getTitle() !== title) {
            point.setCoordinates(null);
            point.setTitle(title);
            this.setPoint(point, type, idx);
        }
    }

    private synchronizeConnection() {
        let idx = 0;
        const points = this.route.getPoints();
        const l = points.length;
        for (; idx < l; idx++) {
            const point = points[idx];
            if (idx === 0) {
                if (point.getConnection() !== point.getType()) {
                    point.setType(point.getConnection());
                    point.resetValue();
                }
            } else if (idx === l - 1) {
                point.setConnection(ConnType.bicycle);
                if (points[idx - 1].getConnection() !== point.getType()) {
                    point.setType(points[idx - 1].getConnection());
                    point.resetValue();
                }
            } else {
                if (point.getConnection() === ConnType.bus) {
                    if (point.getType() !== ConnType.bus) {
                        point.setType(ConnType.bus);
                        point.resetValue();
                    }
                } else if (points[idx - 1].getConnection() === ConnType.bus && point.getType() !== ConnType.bus) {
                    point.setType(ConnType.bus);
                    point.resetValue();
                }
            }
        }
    }

    public resetRoute() {
        this.showAdditionalInformation(false); // todo
        Routing.getInstance().resetRoute();
        Routing.getInstance().resetPoints();
        this.route = new UserRoute(this.options.userRouteUrl, this.crs);
        this.routeform.resetForm();
    }

    private synchronizePoints() {
        // Routing.getInstance().resetRoute();
        // Routing.getInstance().resetPoints();
        let idx = 0;
        const points = this.route.getPoints();
        const l = points.length;
        for (; idx < l; idx++) {
            const type = idx === 0 ? PointType.start : (idx === points.length - 1 ? PointType.end : PointType.via);
            this.setFormPoint(points[idx], type, idx);
            this.setMapPoint(points[idx], type, idx);
        }
    }

    public addViaPoint() {
        this.radisToUser();
        const points = this.route.getPoints();
        const idx = points.length - 1;
        const before = points[idx - 1];
        const after = points[idx]; // after and last
        const type = before.getConnection() === ConnType.bus ? ConnType.bus : ConnType.bicycle;
        this.setPoint(new RoutePoint(null, null, type, ConnType.bicycle, null), PointType.via, idx);
        if (after.getType() === ConnType.bus) {
            this.setPoint(new RoutePoint(null, null, ConnType.bicycle, ConnType.bicycle, null), PointType.end, null);
        }
    }

    public setPoint(point: RoutePoint, type: PointType, idx: number) {
        this.radisToUser();
        Routing.getInstance().resetRoute();
        this.setRoutePoint(point, type, idx);
        this.setFormPoint(point, type, idx);
        this.setMapPoint(point, type, idx);
        this.calculateRoute();
    }

    public removePoint(type: PointType, idx: number) {
        this.radisToUser();
        Routing.getInstance().resetRoute();
        this.removeMapPoint(type, idx);
        this.removeRoutePoint(type, idx);
        this.removeFormPoint(type, idx);
        this.calculateRoute();
    }

    private radisToUser() {
        if (this.route instanceof RadisRoute) {
            const points = this.route.getPoints();
            const l = points.length;
            this.route = new UserRoute(this.options.userRouteUrl, this.crs);
            let i = 0;
            for (; i < l; i++) {
                if (i === 0) {
                    this.route.setStart(points[i]);
                } else if (i === points.length - 1) {
                    this.route.setEnd(points[i]);
                } else {
                    this.route.setVia(points[i], i);
                }
            }
        }
        return this.route;
    }

    private setMapPoint(point: RoutePoint, type: PointType, idx: number) {
        if (point && point.getCoordinates()) {
            Routing.getInstance().setPoint(point.getCoordinates(), type, Utils.epsgToSrid(this.crs), idx, false);
        } else {
            Routing.getInstance().removePoint(type, idx, false);
        }
    }

    private removeMapPoint(type: PointType, idx: number) {
        Routing.getInstance().removePoint(type, idx, false);
    }

    private setRoutePoint(point: RoutePoint, type: PointType, idx: number) {
        switch (type) {
            case PointType.start:
                this.route.setStart(point);
                break;
            case PointType.end:
                this.route.setEnd(point);
                break;
            case PointType.via:
                this.route.setVia(point, idx);
                break;
        }
    }

    private setFormPoint(point: RoutePoint, type: PointType, idx: number) {
        if (!this.routeform) {
            return;
        }
        switch (type) {
            case PointType.start:
                this.routeform.setStart(point);
                break;
            case PointType.end:
                this.routeform.setEnd(point);
                break;
            case PointType.via:
                this.routeform.setVia(point, idx);
                break;
        }
    }

    public removeRoutePoint(type: PointType, idx: number) {
        switch (type) {
            case PointType.start:
                this.route.setStart(null);
                break;
            case PointType.end:
                this.route.setEnd(null);
                break;
            case PointType.via:
                this.route.removeVia(idx);
                break;
        }
    }

    public removeFormPoint(type: PointType, idx: number) {
        if (!this.routeform) {
            return;
        }
        switch (type) {
            case PointType.start:
                this.routeform.removeStart();
                break;
            case PointType.end:
                this.routeform.removeEnd();
                break;
            case PointType.via:
                this.routeform.removeVia(idx);
                break;
        }
    }
}
