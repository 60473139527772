import {Image} from "ol/style";
import CircleStyle from "ol/style/Circle";
import Icon from "ol/style/Icon";
import RegularShape from "ol/style/RegularShape";
import Text from "ol/style/Text";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import Geometry from "ol/geom/Geometry";
import {asArray} from "ol/color";
import {Utils} from "./geom/Utils";
import {StyleFont} from "./StyleFont";

export class StylesToMb3 {

    /**
     * Creates a style object from a string json representation
     * @param options
     */
    public static toSingleStyle(styles: Style | Style[],  withDefault: boolean = false): any {
        let res: any = withDefault ? StylesToMb3.defaultOpts() : {};
        for (const opts of StylesToMb3.toStyles(styles)) {
            res = Utils.merge(res, opts);
        }
        return res;
    }

    /**
     * Creates a style object from a string json representation
     * @param options
     */
    public static toStyles(styles: Style | Style[]): any[] {
        if (styles instanceof Array) {
            let res: any[] = [];
            for (const style of styles) {
                res = res.concat(StylesToMb3.toStyles(style));
            }
            return res;
        } else {
            const style: Style = styles;
            const options = {};
            if (typeof style.getGeometry() === "string" || style.getGeometry() instanceof String) {
                StylesToMb3.fromSimple(style.getGeometry(), "geometry", options);
            } else if (style.getGeometry() instanceof Geometry) {
                window.console.warn("not yet supported");
            }
            StylesToMb3.fromFill(style.getFill(), options);
            StylesToMb3.fromStroke(style.getStroke(), options);
            StylesToMb3.fromText(style.getText(), options);
            StylesToMb3.fromImage(style.getImage(), options);
            StylesToMb3.fromSimple(style.getZIndex(), "zIndex", options);
            return [options];
        }
    }

    private static defaultOpts(): any {
        return {
            fill: true,
            graphic: true,
            labelAlign: "cm",
            labelOutlineColor: "white",
            labelOutlineWidth: 3,
            labelSelect: false,
            pointRadius: 6,
            stroke: true,
            strokeColor: "#ee9900",
            strokeDashstyle: "solid",
            strokeLinecap: "round",
            strokeOpacity: 1,
            strokeWidth: 1,
        };
    }

    private static fromImage(image: Image, opts: any): void {
        if (image) {
            StylesToMb3.fromCircle(image, opts);
            StylesToMb3.fromIcon(image, opts);
            StylesToMb3.fromRegularShape(image, opts);
        }
    }

    private static fromCircle(circle: Image, opts: any): void {
        if (circle instanceof CircleStyle) {
            StylesToMb3.fromSimple(circle.getRadius(), "pointRadius", opts, true);
            StylesToMb3.fromFill(circle.getFill(), opts);
            StylesToMb3.fromStroke(circle.getStroke(), opts);
            // TODO fromAtlasManager StylesToMb3.fromAtlasManager(style, opts);
        }
    }

    private static fromIcon(icon: Image, opts: any): void {
        if (icon instanceof Icon) {
            opts.pointRadius = 0;
            const iconSize = icon.getImageSize() && icon.getImageSize().length === 2 ? icon.getImageSize() : null;
            if (iconSize) {
                opts.graphicWidth = StylesToMb3.scaleToMb(iconSize[0]);
                opts.graphicHeight = StylesToMb3.scaleToMb(iconSize[1]);
            }
            if (icon.getSrc()) {
                opts.externalGraphic = Utils.stripUrl(icon.getSrc());
            }
            if (iconSize && icon.getAnchor()) {
                opts.graphicXOffset = StylesToMb3.scaleToMb(-icon.getAnchor()[0]); // * iconSize[0];
                opts.graphicYOffset = StylesToMb3.scaleToMb(-icon.getAnchor()[1]); // * iconSize[1];
            }
            StylesToMb3.fromSimple(icon.getRotation(), "rotation", opts);
            StylesToMb3.fromSimple(icon.getOpacity(), "graphicOpacity", opts);
        }
    }

    private static fromRegularShape(shape: Image, optsa: any): void {
        if (shape instanceof RegularShape) {
            // optsa.regularShape = {};
            // const opts = optsa.regularShape;
            // StylesToMb3.fromFill(shape.getFill(), "fill", opts);
            // StylesToMb3.fromStroke(shape.getStroke(), "stroke", opts);
            // StylesToMb3.fromSimple(shape.getRadius(), "radius", opts);
            // // StylesToMb3.fromSimple(shape.getRadius, "radius1", opts);
            // StylesToMb3.fromSimple(shape.getRadius2(), "radius2", opts);
            // StylesToMb3.fromSimple(shape.getAngle(), "angle", opts);
            // StylesToMb3.fromSimple(shape.getRotation(), "rotation", opts);
            // StylesToMb3.fromSimple(shape.getRotateWithView(), "rotateWithView", opts);
        }
    }

    private static fromText(text: Text, opts: any): void {
        // opts.labelOutlineOpacity = 1; // from fornt opacity
        if (text) {
            opts.pointRadius = 0;
            opts.label = text.getText();
            StylesToMb3.fromSimple(text.getTextAlign(), "labelAlign", opts);
            StylesToMb3.fromSimple(text.getOffsetX(), "labelXOffset", opts, true);
            StylesToMb3.fromSimple(text.getOffsetY(), "labelYOffset", opts, true);
            opts.labelSelect = false;
            // if (text.getBackgroundFill()) {
            //     const col = asArray(text.getBackgroundFill().getColor() as string | number[]);
            //     opts.labelOutlineColor = StylesToMb3.toHexRGBColor(col[0], col[1], col[2]);
            //     opts.labelOutlineOpacity = col[3];
            //     if (text.getBackgroundStroke() && text.getBackgroundStroke().getWidth()) {
            //         opts.labelOutlineWidth = StylesToMb3.scaleToMb(text.getBackgroundStroke().getWidth());
            //     }
            // }
            if (text.getFill()) {
                if (text.getFill().getColor()) {
                    const col = asArray(text.getFill().getColor() as string | number[]);
                    opts.fontColor = StylesToMb3.toHexRGBColor(col[0], col[1], col[2]);
                    opts.fontOpacity = col[3];
                }
            }
            if (text.getStroke()) {
                if (text.getStroke().getColor()) {
                    const col = asArray(text.getStroke().getColor() as string | number[]);
                    opts.labelOutlineColor = StylesToMb3.toHexRGBColor(col[0], col[1], col[2]);
                    opts.labelOutlineOpacity = col[3];
                }
                if (text.getStroke() && text.getStroke().getWidth()) {
                    opts.labelOutlineWidth = StylesToMb3.scaleToMb(text.getStroke().getWidth());
                }
            }
            if (text.getFont()) {
                const fo = StyleFont.parse(text.getFont());
                if (StyleFont.isValid(fo)) {
                    opts.fontFamily = fo.family;
                    opts.fontSize = StylesToMb3.scaleToMb(parseFloat(fo.size)) +  fo.size.replace(/\d+(\.\d*)?/, "");
                    StylesToMb3.fromSimple(fo.style, "fontStyle", opts);
                    StylesToMb3.fromSimple(fo.weight, "fontWeight", opts);
                }
            }
            StylesToMb3.fromSimple(text.getRotation(), "rotation", opts);
            StylesToMb3.fromSimple(text.getText(), "label", opts);
        }
    }

    private static fromStroke(stroke: Stroke, opts: any, key: string = "stroke"): void {
        if (stroke) {
            if (stroke.getColor()) {
                const col = asArray(stroke.getColor() as string | number[]);
                opts.strokeColor = StylesToMb3.toHexRGBColor(col[0], col[1], col[2]);
                opts.strokeOpacity = col[3];
            }
            StylesToMb3.fromSimple(stroke.getWidth(), "strokeWidth", opts, true);
            StylesToMb3.fromSimple(stroke.getLineCap(), "strokeLinecap", opts, true);
            // TODO number to string  [dot | dash | dashdot | longdash | longdashdot | solid]
            // StylesToMb3.fromSimple(stroke.getLineDash(), "strokeDashstyle", opts);
        }
    }

    private static fromFill(fill: Fill, opts: any): void {
        if (fill && fill.getColor()) {
            const col = asArray(fill.getColor() as string | number[]);
            opts.fillColor = StylesToMb3.toHexRGBColor(col[0], col[1], col[2]);
            opts.fillOpacity = col[3];
        } else {
            opts.fillColor = "#ee9900";
            opts.fillOpacity = 0.4;
        }
    }

    private static fromSimple(value: any, key: string, options: any, scale: boolean = false) {
        if (value !== undefined && value !== null) {
            options[key] = scale ? StylesToMb3.scaleToMb(value) : value;
        }
    }

    private static toHexRGBColor(r: number, g: number, b: number) {
        return "#" + StylesToMb3.to2PosHex(r.toString(16)) + StylesToMb3.to2PosHex(g.toString(16))
            + StylesToMb3.to2PosHex(b.toString(16));
    }

    private static to2PosHex(hexstr: string): string {
        return hexstr.length === 2 ? hexstr : "0" + hexstr;
    }

    private static scaleToMb(val: number, round: boolean = true) {
        const scale = 0.4;
        return round ? Math.round(val * scale) : val * scale;
    }
}
