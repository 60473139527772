import Collection from "ol/Collection";
import Control from "ol/control/Control";
import Base from "ol/layer/Base";
import Group from "ol/layer/Group";
import Layer from "ol/layer/Layer";
import {METERS_PER_UNIT} from "ol/proj/Units";
import View, {FitOptions} from "ol/View";
import {DExtent} from "./geom/DExtent";
import {Utils} from "./geom/Utils";
import {IFeatureEventSubscriber} from "./IFeatureEventSubscriber";
import {OlMap} from "./OlMapping";
import {ASource} from "./source/ASource";
import {MapActivity} from "./source/MapActivity";
import {ViewChangeListener} from "./source/ViewChangeListener";
import {FeatureInfo} from "./source/FeatureInfo/FeatureInfo";
import {DPoint} from "./geom/DPoint";
import {OlPopup} from "./OlPopup";
import {Sidebar} from "../routeplanner/Sidebar";
import {IMapEventSubscriber} from "./IMapEventSubscriber";
import {MapEventObserver, MapEventType} from "./MapEventObserver";
import {IConfiguration} from "../frontend";

export enum SOURCE_POSITION {
    background = "background",
    foreground = "foreground",
    medium = "medium",
    overlay = "overlay",
}

export const ORIG_LAYERS = "LAYERS";

export const MODEL = "MODEL";

export const UUID: string = "uuid";

export class DMap {

    private static id = -1;

    protected map: OlMap;
    protected maxExtent: DExtent;
    protected startExtent: DExtent;
    protected startCenter: DPoint;
    protected startScale: number;
    protected resolutions: number[];
    protected levels: Map<SOURCE_POSITION, Group>;
    protected mapEventObserver: MapEventObserver;
    protected mapActivity: MapActivity;
    protected viewChangeListener: ViewChangeListener;
    protected featureInfo: FeatureInfo;

    public constructor(target: HTMLElement, config: IConfiguration) {
        this.map = new OlMap({
            controls:[]
        });
        this.map.setTarget(target);
        this.levels = new Map<SOURCE_POSITION, Group>();
        this.addMapLevel(SOURCE_POSITION.background);
        this.addMapLevel(SOURCE_POSITION.medium);
        this.addMapLevel(SOURCE_POSITION.foreground);
        this.addMapLevel(SOURCE_POSITION.overlay);
        this.mapEventObserver = new MapEventObserver(this);
        this.mapActivity = MapActivity.create(this);
        this.featureInfo = new FeatureInfo(this, config);
    }

    public getCenter(): number[] {
        return this.map.getView().getCenter();
    }

    public getExtent(): number[] {
        return this.map.getView().calculateExtent(this.map.getSize());
    }

    public getPaddedCenter(): number[] {
        // [top, right, bottom, left]
        const padding = this.getMapPadding();
        const realExt = this.getExtent();
        const realCenter = this.getCenter();
        const mapSize = this.map.getSize();
        // todo for left, right, top, bottom
        // const center = [
        //     realCenter[0] + padding[3] * (realExt[2] - realExt[0]) / mapSize[0] - padding[1] * (realExt[2] - realExt[0]) / mapSize[0],
        //     realCenter[1] - padding[2] * (realExt[3] - realExt[1]) / mapSize[1] + padding[0] * (realExt[3] - realExt[1]) / mapSize[1],
        // ];
        const center = [
            realCenter[0] - (padding[3] - padding[1]) / 2 * (realExt[2] - realExt[0]) / mapSize[0],
            realCenter[1] - (padding[2] - padding[0]) / 2 * (realExt[3] - realExt[1]) / mapSize[1],
        ];
        return center;
    }

    public getPaddedExtent(): number[] {
        // [top, right, bottom, left]
        const padding = this.getMapPadding();
        const realExt = this.getExtent();
        const mapSize = this.map.getSize();
        const kx = (realExt[2] - realExt[0]) / mapSize[0];
        const ky = (realExt[3] - realExt[1]) / mapSize[1];
        return [
            realExt[0] + padding[4] * kx,
            realExt[1] + padding[3] * ky,
            realExt[2] - padding[1] * kx,
            realExt[3] - padding[0] * ky,
        ];
    }
    //
    // public setScale(scale: number): void {
    //     const resolution = this.getResolutionForScale(scale);
    //     this.map.getView().setResolution(resolution);
    // }

    public getScale(): number {
        return Math.round(this.getScaleForResolution(this.getMap().getView().getResolution()));
    }

    public getResolution(): number {
        return this.getMap().getView().getResolution();
    }

    public getScaleForResolution(res: number): number {
        const meterPerUnit: number = METERS_PER_UNIT[this.map.getView().getProjection().getUnits()];
        const factor = Utils.resolutionScaleFactor(meterPerUnit);
        return Utils.scaleForResolution(res, factor);
    }

    public getResolutionForScale(scale: number): number {
        const meterPerUnit: number = METERS_PER_UNIT[this.map.getView().getProjection().getUnits()];
        const factor = Utils.resolutionScaleFactor(meterPerUnit);
        return Utils.resolutionForScale(scale, factor);
    }

    public getCurrentSrs(): string {
        return this.map.getView().getProjection().getCode();
    }

    public getFeatureInfo(): FeatureInfo {
        return this.featureInfo;
    }

    public getMapActivity(): MapActivity {
        return this.mapActivity;
    }

    public sourceChanged(uuid: string, layers: string[], visible) {
        this.getTargetElement().dispatchEvent(
            new CustomEvent("sourceChanged",
                {
                    detail: {
                        source: {
                            id: uuid,
                            unvisibleLayers: visible,
                            visibleLayers: layers,
                        },
                    },
                }));
    }

    public getTargetElement(): Element {
        return this.map.getTargetElement();
    }

    public generateUuid(): string {
        // TODO generate a real uuid?
        DMap.id++;
        return "" + DMap.id;
    }

    public getStartScale(): number {
        return this.startScale;
    }

    public setStartScale(startScale: number): void {
        this.startScale = startScale;
    }

    public getStartCenter(): DPoint {
        return this.startCenter;
    }

    public setStartCenter(startCenter: DPoint): void {
        this.startCenter = startCenter;
    }

    public getMaxExtent(): DExtent {
        return this.maxExtent;
    }

    public setMaxExtent(extent: DExtent): void {
        this.maxExtent = extent;
    }

    public getStartExtent(): DExtent {
        return this.startExtent;
    }

    public setStartExtent(extent: DExtent): void {
        this.startExtent = extent;
    }

    public getResolutions(): number[] {
        return this.resolutions;
    }

    public setResolutions(resolutions: number[]): void {
        this.resolutions = resolutions;
    }

    public getMinResolution(): number {
        return this.resolutions[this.resolutions.length - 1];
    }

    public getMaxResolution(): number {
        return this.resolutions[0];
    }

    public setView(epsgCode: string): DMap {
        const proj = Utils.createProjection(epsgCode);
        const ext = this.maxExtent.getExtent(proj);
        proj.setExtent(ext);
        const view = new View({
            extent: ext,
            projection: proj,
            resolutions: this.resolutions,
            enableRotation: false
        });
        this.map.setView(view);
        if (this.startCenter && this.startScale) {
            this.toCenter(this.startCenter.getCoordinates(proj), this.startScale);
        } else {
            this.zoomToExtent(this.startExtent.getExtent(proj), proj.getCode());
        }

        this.viewChangeListener = new ViewChangeListener(this);
        return this;
    }

    public getMap(): OlMap {
        return this.map;
    }

    public zoomToExtent(extent: number[], epsgCode: string) {
        const proj = Utils.createProjection(epsgCode);
        const ext = DExtent.fromArray(extent, proj).getExtent(proj);
        const opts: FitOptions = {
            padding: this.calculateMapPadding(20),
            size: this.map.getSize(),
            callback: () => {
                this.toCenter(this.map.getView().getCenter());
            },
        };
        this.map.getView().fit(ext, opts);
    }

    public toCenter(coordinates: number[], scale: number = null) {
        this.map.getView().setCenter(coordinates);
        if (scale !== null) {
            this.toScale(scale);
        }
    }

    public zoomToCenter(coordinates: number[], scale: number = null) {
        if (scale !== null) {
            this.toScale(scale);
        }
        this.map.getView().setCenter(this.getPaddedCenter());
    }

    public toScale(scale: number) {
        this.map.getView().setResolution(this.getResolutionForScale(scale));
    }

    public getMapPadding(): number[] {
        return this.calculateMapPadding(0);
    }

    public getSrs(): string {
        return this.map.getView().getProjection().getCode();
    }

    public getViewChangeListener(): ViewChangeListener {
        return this.viewChangeListener;
    }

    public subscribeFeatureEvent(eventType: MapEventType, subscriber: IFeatureEventSubscriber): void {
        this.mapEventObserver.subscribeFeatureEvent(eventType, subscriber);
    }

    public unsubscribeFeatureEvent(eventType: MapEventType, subscriber: IFeatureEventSubscriber): void {
        this.mapEventObserver.unsubscribeFeatureEvent(eventType, subscriber);
    }

    public subscribeMapEvent(eventType: MapEventType, subscriber: IMapEventSubscriber, byDragging: boolean): void {
        this.mapEventObserver.subscribeMapEvent(eventType, subscriber, byDragging);
    }

    public unsubscribeMapEvent(eventType: MapEventType, subscriber: IMapEventSubscriber, byDragging: boolean): void {
        this.mapEventObserver.unsubscribeMapEvent(eventType, subscriber, byDragging);
    }

    public addSource(source: Layer, level: SOURCE_POSITION = SOURCE_POSITION.medium, uuid: string = null): string {
        source.set(UUID, uuid ? uuid : this.generateUuid());
        this.addToLevel(source, level);
        return source.get(UUID);
    }

    public addControl(control: Control): DMap {
        this.map.addControl(control);
        return this;
    }

    public getSource(uuid: string): Base {
        return this.findSource(uuid, this.map.getLayers());
    }

    public showSource(uuid: string): boolean {
        const source = this.findSource(uuid, this.map.getLayers());
        if (source && source.get(MODEL)) {
            (source.get(MODEL) as ASource).setVisible(true);
            return true;
        }
        return false;
    }

    public getASource(uuid: string): ASource {
        const source = this.findSource(uuid, this.map.getLayers());
        if (source && source.get(MODEL)) {
            return (source.get(MODEL) as ASource);
        }
        return null;
    }

    public hideSource(uuid: string): boolean {
        const source = this.findSource(uuid, this.map.getLayers());
        if (source && source.get(MODEL)) {
            (source.get(MODEL) as ASource).setVisible(false);
            return true;
        }
        return false;
    }

    public showLayers(uuid: string, layers: string[]): boolean {
        const source = this.findSource(uuid, this.map.getLayers());
        if (source && source.get(MODEL)) {
            (source.get(MODEL) as ASource).setItemVisible(layers, true);
        }
        return true;
    }

    public hideLayers(uuid: string, layers: string[]): boolean {
        const source = this.findSource(uuid, this.map.getLayers());
        if (source && source.get(MODEL)) {
            (source.get(MODEL) as ASource).setItemVisible(layers, false);
        }
        return true;
    }

    public listLayers(): ASource[] {
        return this.allLayers(this.map.getLayers());
    }

    public addOverlay(overlay: OlPopup) {
        this.map.addOverlay(overlay);
    }

    public removeOverlay(overlay: OlPopup) {
        this.map.removeOverlay(overlay);
    }

    /**
     * Gets padding values. Values in the array are top, right, bottom and left padding.
     * @param defVal
     */
    private calculateMapPadding(defVal: number = 0): number[] {
        const sidebar = Sidebar.getInstance();
        if (sidebar) {
            const bb1 = this.getTargetElement().getBoundingClientRect();
            const bb2 = sidebar.getElement().getBoundingClientRect();
            const overlaps = !(
                bb1.top > bb2.bottom ||
                bb1.right < bb2.left ||
                bb1.bottom < bb2.top ||
                bb1.left > bb2.right
            );
            return overlaps ? [defVal, sidebar.getSidebarWidth() + defVal, defVal, defVal] : [defVal, defVal, defVal, defVal];
        } else {
            return [defVal, defVal, defVal, defVal];
        }
    }

    private allLayers(layers: Collection<Base>): ASource[] {
        let list: ASource[] = [];
        for (const l of layers.getArray()) {
            if (l.get(UUID)) {
                list.push(l.get(MODEL));
            } else if (l instanceof Group) {
                list = list.concat(this.allLayers(l.getLayers()));
            }
        }
        return list;
    }

    private addToLevel(source: Base, level: SOURCE_POSITION, position: number = -1) {
        this.levels.get(level).getLayers().insertAt(
            position !== -1 ? position : this.levels.get(level).getLayers().getLength(), source);
    }

    private addMapLevel(level: SOURCE_POSITION) {
        if (!this.levels.has(level)) {
            const gr = new Group({layers: new Collection<Base>()});
            this.map.addLayer(gr);
            this.levels.set(level, gr);
        }
    }

    private getMapLevel(level: SOURCE_POSITION) {
        if (!this.levels.has(level)) {
            return this.levels.get(level);
        }
        return null;
    }

    private findSource(uuid: string, layers: Collection<Base>): Base {
        if (!uuid) {
            return null;
        }
        for (const l of layers.getArray()) {
            if (l.get(UUID) === uuid) {
                return l;
            } else if (l instanceof Group) {
                const fl = this.findSource(uuid, l.getLayers());
                if (fl !== null) {
                    return fl;
                }
            }
        }
        return null;
    }

    public createPopup(): OlPopup {
        const elm = document.createElement("div");
        this.getTargetElement().parentElement.appendChild(elm);
        const options = {
            element: elm,
        };
        const popup = new OlPopup(options, false);
        popup.getElement().classList.add("ol-popup");
        this.getMap().addOverlay(popup);
        return popup;
    }
}
