// @ts-nocheck
import {IChangedSource} from "../map/source/IChangedSource";
import {getMap, serviceTreeMap} from "../client/MapApp";
import {Eu} from "../client/service/Eu";

interface ISidebarOption {
    elementId: string;
    expertMode: boolean;
}

export class Sidebar {

    private static instance: Sidebar;

    public static create(options: ISidebarOption) {
        if (!Sidebar.instance) {
            Sidebar.instance = new Sidebar(options);
        }
        return Sidebar.instance;
    }

    public static getInstance() {
        return  Sidebar.instance;
    }

    private serviceDomMap;
    private readonly options: ISidebarOption;

    private constructor(options: ISidebarOption) {
        this.options = {
            arrowClass: "--js-sidebar-arrow",
            elementClass: ".--js-map-sidebar",
            elementId: options.elementId,
            expertMode: options.expertMode,
            iconArrowLeftClass: "oi-caret-left",
            iconArrowRightClass: "--js-sidebar-arrow",
            isSlidedOutClass: "--js-slidedOut",
            slideOutCssClass: "slide-out",
        };
        this.initEvents();
    }

    private initEvents() {
        const self = this;
        const selfElm = this.getElement();
        selfElm.querySelector('.--js-sidebar-slide').addEventListener("click", (e: Event) => {
            self.toggle();
        });
        const handler = (e: Event) => {
            const input: HTMLInputElement = e.target;
            const ref = input.getAttribute("data-ref");
            if (ref && serviceTreeMap.tree2service[ref]) {
                for (const service of Object.keys(serviceTreeMap.tree2service[ref])) {
                    if (input.checked) {
                        getMap().showLayers(service, serviceTreeMap.tree2service[ref][service]);
                    } else {
                        getMap().hideLayers(service, serviceTreeMap.tree2service[ref][service]);
                    }
                }
            }
        };
        Eu.eventAdd("change", '.--js-pois .list__item__checkbox', handler, false, selfElm);
        if (this.options.expertMode) {
            Eu.eventAdd("change", '.--js-portal .list__item__checkbox', handler, false, selfElm);
        }
    }

    public getElement(): HTMLElement {
        return document.getElementById(this.options.elementId);
    }

    public isExpertMode(): boolean {
        return !!this.options.expertMode;
    }

    public isVisible(): boolean {
        return !$(this.getElement()).hasClass("--js-slidedOut");
    }

    public getSidebarWidth(): number {
        // return $(this.getElement()).data("sidebar-width");
        return $(this.getElement()).width();
    }

    public toggle(): void {
        const $sidebar = $(this.options.elementClass);
        const $sidebarArrow = $(this.options.arrowClass);
        const isSlidedOutClass = "--js-slidedOut";
        const slideOutCssClass = "slide-out";
        const slideInCssClass = this.isExpertMode() ? "slide-in-expert" : "slide-in";

        if ($sidebar.hasClass(isSlidedOutClass)) {
            $sidebar
                .removeClass(slideInCssClass)
                .addClass(slideOutCssClass)
                .removeClass(isSlidedOutClass)
            ;

            $sidebarArrow
                .removeClass(this.iconArrowLeftClass)
                .addClass(this.iconArrowRightClass);
        } else {
            $sidebar
                .removeClass(slideOutCssClass)
                .addClass(slideInCssClass)
                .addClass(isSlidedOutClass)
            ;

            $sidebarArrow
                .removeClass(this.iconArrowRightClass)
                .addClass(this.iconArrowLeftClass);
        }
    }

    public handleVisibility(changed: IChangedSource) {
        if (changed.names.length === 0 || !serviceTreeMap) {
            return;
        }
        if (!this.serviceDomMap) {
            this.initServiceDomMap(serviceTreeMap.service2tree);
        }
        if (serviceTreeMap.service2tree[changed.uuid]) {
            const serv = serviceTreeMap.service2tree[changed.uuid];
            for (const layerName of changed.names) {
                if (serv[layerName]) {
                    const set = this.serviceDomMap[serv[layerName]];
                    set.input.prop("checked", changed.visibles.indexOf(layerName) !== -1 ? true : false);
                    if (changed.realVisibles.indexOf(layerName) !== -1) {
                        set.label.removeClass("source-invisible");
                    } else {
                        set.label.addClass("source-invisible");
                    }
                }
            }
        }
    }

    private initServiceDomMap(service2tree: { [key: string]: { [key: string]: string } }) {
        this.serviceDomMap = {};
        for (const uuid of Object.keys(service2tree)) {
            for (const layerName of Object.keys(service2tree[uuid])) {
                const idx = service2tree[uuid][layerName];
                this.serviceDomMap[idx] = {
                    input: $("input[data-ref=" + idx + "]", "#poi,#portal"),
                    label: null,
                };
                this.serviceDomMap[idx].label =
                    $("label[for=" + this.serviceDomMap[idx].input.attr("id") + "]", "#poi,#portal");
            }
        }
    }
}
