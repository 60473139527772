import {Item} from "./Item";
import {AItem} from "./AItem";

export class GroupItem extends Item {

    protected items: Item[] = [];

    public itemsCount(): number {
        return this.items.length;
    }

    public addItem(item: Item): void {
        if (this.items.indexOf(item) === -1) {
            this.items.push(item);
        }
    }

    public removeItem(item: Item): Item {
        const founded = this.items.indexOf(item);
        if (founded > -1) {
            return this.items.splice(founded, 1)[0];
        } else {
            return null;
        }
    }

    public getItem(idx: number): Item {
        return this.items.length > idx ? this.items[idx] : null;
    }

    public getNames(names: string[] = []): string[] {
        for (let i = 0; i < this.itemsCount(); i++) {
            const item = this.getItem(i);
            if (item instanceof GroupItem) {
                names = names.concat(item.getNames());
            } else if (item.hasName() === true) {
                names.push(item.getName());
            }
        }
        return names;
    }

    protected setVisibilityForNames(names: string[], visible: boolean, changed: string[] = []): string[] {
        if (this.hasName() === true && names.indexOf(this.getName()) !== -1) {
            this.setVisible(visible);
        }
        for (let i = 0; i < this.itemsCount(); i++) {
            const item = this.getItem(i);
            if (item instanceof GroupItem) {
                changed = changed.concat(item.setVisibilityForNames(names, visible));
            } else {
                if (item.hasName() === true && names.indexOf(item.getName()) !== -1) {
                    if (item.getVisible() !== visible) {
                        changed.push(item.getName());
                    }
                    item.setVisible(visible);
                }
            }
        }
        return changed;
    }

    protected setInfoableForNames(names: string[], infoable: boolean, changed: string[] = []): string[] {
        for (let i = 0; i < this.itemsCount(); i++) {
            const item = this.getItem(i);
            if (item instanceof GroupItem) {
                changed = changed.concat(item.setInfoableForNames(names, infoable));
            } else {
                if (item.hasName() === true && names.indexOf(item.getName()) !== -1) {
                    if (item.getInfoable() !== infoable) {
                        changed.push(item.getName());
                    }
                    item.setInfoable(infoable);
                }
            }
        }
        return changed;
    }

    public getVisibles(names: string[] = []): string[] {
        for (let i = 0; i < this.itemsCount(); i++) {
            const item = this.getItem(i);
            if (item instanceof GroupItem) {
                names = names.concat(item.getVisibles());
            } else {// use only names form simple layers
                if (item.hasName() && item.getVisible() === true) {
                    names.push(item.getName());
                }
            }
        }
        return names;
    }

    public getRealVisibles(scale: number, names: string[] = []): string[] {
        if (this.isVisibleForScale(scale)) {
            for (let i = 0; i < this.itemsCount(); i++) {
                const item = this.getItem(i);
                if (item instanceof GroupItem) {
                    names = names.concat(item.getRealVisibles(scale));
                } else {// use only names form simple layers
                    if (item.hasName() && item.isVisibleForScale(scale) === true) {
                        names.push(item.getName());
                    }
                }
            }
        }
        return names;
    }

    public getInfoables(names: string[] = []): string[] {
        for (let i = 0; i < this.itemsCount(); i++) {
            const item = this.getItem(i);
            if (item instanceof GroupItem) {
                names = names.concat(item.getInfoables());
            } else {// use only names form simple layers
                if (item.hasName() && item.getInfoable() === true) {
                    names.push(item.getName());
                }
            }
        }
        return names;
    }

    public getRealInfoables(scale: number, names: string[] = []): string[] {
        if (this.isVisibleForScale(scale)) {
            for (let i = 0; i < this.itemsCount(); i++) {
                const item = this.getItem(i);
                if (item instanceof GroupItem) {
                    names = names.concat(item.getRealInfoables(scale));
                } else { // use only names form simple layers
                    if (item.hasName() && item.isVisibleForScale(scale) === true && item.getInfoable() === true) {
                        names.push(item.getName());
                    }
                }
            }
        }
        return names;
    }

    public fromOptions(options: { [key: string]: any }) {
        super.fromOptions(options);
        for (const subOptions of (options.layers || [])) {
            const item = subOptions.layers ? new GroupItem() : new Item();
            item.setParent(this as AItem);
            this.addItem(item);
            item.fromOptions(subOptions);
            if (typeof subOptions === "string" || subOptions instanceof String) {
                item.setName(subOptions as string);
            }
        }
    }

    //
    // public getRealInvisibles(scale: number, names: string[] = []): string[] {
    //     if (this.isVisibleForScale(scale)) {
    //         for (let i = 0; i < this.itemsCount(); i++) {
    //             this.getItem(i).getRealInvisibles(scale, names);
    //         }
    //     }
    //     return names;
    // }
    //
    // private visibilityForName(item: AItem, names: string[], visible: boolean) {
    //     const name = item.getName();
    //     if (name !== null && name.length && names.indexOf(name) !== -1) {
    //         item.setVisible(visible);
    //     }
    // }
}
