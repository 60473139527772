export class Progress {
    private loading: number;
    private loaded: number;
    private elm: HTMLElement;

    public constructor(elm: HTMLElement) {
        this.elm = elm;
        this.loading = 0;
        this.loaded = 0;
    }

    public addLoading() {
        if (this.loading === 0) {
            this.show();
        }
        this.loading++;
        this.update();
    }

    public addLoaded() {
        this.loaded++;
        setTimeout(() => {
            this.update();
        }, 0);
    }

    /**
     * Update the progress bar.
     */
    protected update() {
        this.elm.style.width = (this.loaded / this.loading * 100).toFixed(1) + "%";
        if (this.loading === this.loaded || this.loaded > this.loading) {
            this.loading = 0;
            this.loaded = 0;
            setTimeout(() => {
                this.hide();
            }, 100);
        }
    }

    /**
     * Show the progress bar.
     */
    protected show() {
        this.elm.style.visibility = "visible";
    }

    /**
     * Hide the progress bar.
     */
    protected hide() {
        if (this.loading === this.loaded) {
            this.elm.style.visibility = "hidden";
            this.elm.style.width = "0";
        }
    }
}
