import {IFeatureEventSubscriber} from "../../IFeatureEventSubscriber";
import {DMap} from "../../DMap";
import {Vector} from "ol/layer";
import Feature from "ol/Feature";
import {MapEventType} from "../../MapEventObserver";
import {ASource} from "../ASource";
import {TabPopup} from "../TabPopup";
import {SourceWms} from "../SourceWms";
import {GmlPostProcessor} from "./GmlPostProcessor";
import {IGmlPostProcessorOptions} from "./IGmlPostProcessorOptions";
import {HTTP_METHOD, HttpClient} from "../../../client/service/HttpClient";
import {IConfiguration} from "../../../frontend";

export class FeatureInfo implements IFeatureEventSubscriber {
    protected dmap: DMap;
    protected subscribers: ASource[];
    protected popup: TabPopup;
    protected config: IConfiguration;

    /**
     * Creates an instance
     * @param dmap
     * @param styles
     */
    public constructor(dmap: DMap, config: IConfiguration) {
        this.dmap = dmap;
        this.config = config;
        this.subscribers = [];
        this.dmap.subscribeFeatureEvent(MapEventType.singleclick, this);
        const elm = document.createElement("div");
        this.dmap.getTargetElement().parentElement.appendChild(elm);
        const options = {
            element: elm,
        };
        this.popup = new TabPopup(options, false);
        this.popup.getElement().classList.add("ol-popup");
        this.dmap.getMap().addOverlay(this.popup);
    }

    /**
     * Registers a source for a map event
     * @param layer
     * @param interactionStyle
     */
    public register(source: ASource) {
        if (this.subscribers.indexOf(source) === -1) {
            this.subscribers.push(source);
        }
    }

    /**
     * Unregisters a vector layer at an interaction layer
     * @param layer
     */
    public unregister(source: ASource) {
        const founded = this.subscribers.indexOf(source);
        if (founded > -1) {
            this.subscribers.splice(founded, 1);
        }
    }

    /**
     * Sets a DMap instance
     * @param dmap
     */
    public setMap(dmap: DMap): void {
        this.dmap = dmap;
    }

    /**
     * Checks if a layer is registrated
     * @param layer
     */
    public hasLayer(layer: Vector): boolean {
        return false;
    }

    /**
     * Called on event start
     */
    public eventStart(): void {
        // window.console.warn(); //
    }

    /**
     * Called on each feature by event
     * @param layer
     * @param feature
     */
    public addByEvent(layer: Vector, feature: Feature): void {
        //
    }

    /**
     * Called on event end
     * @param coordinate
     */
    public eventEnd(coordinate: [number, number]): void {
        this.popup.hide();
        this.popup.clearContent();
        for (const source of this.subscribers) {
            this.loadInfo(source, coordinate);
        }
    }

    /**
     *
     * @param url
     * @param uuid
     */
    private loadInfo(source: ASource, coordinate: [number, number]) {
        const gfUrl = source.getInfoUrl(coordinate);
        if (!gfUrl) {
            return;
        }
        const url = this.config.baseUrl + this.config.basePath
            + "/tunnel?url=" + encodeURIComponent(gfUrl);
        const events = this.dmap.getMapActivity().getEvents(source.getUuid());
        return HttpClient.sendData(url, null, HTTP_METHOD.GET, "text", events)
            .then((data: any) => {
                this.handleResponse(source, data, coordinate);
            })
            .catch((errors) => {
                // reject(errors);
            });
    }

    private handleResponse(source: ASource, response: string, coordinate: [number, number]) {
        const opts = (source as SourceWms).getFeatureInfoOptions();
        if (opts.postprocessor) {
            const pp = new GmlPostProcessor(opts.postprocessor as IGmlPostProcessorOptions[]);
            this.show(coordinate, source.getTitle(), pp.process(response));
        } else {
            this.show(coordinate, source.getTitle(), response);
        }
    }

    private show(coordinate: [number, number], title: string, content: string | Node[]) {
        if (content) {
            this.popup.addTabContent(title, content);
            if (this.popup.getPosition() === undefined) {
                this.popup.show(coordinate);
            }
        }
    }
}
