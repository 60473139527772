export class Utils {

    public static currentDate(locale: string = "de-DE", options: object = {}): string {
        const defaults = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        };
        options = options ? {...options, ...defaults} : defaults;
        return this.toLocaleString("de-DE", options);
    }

    public static currentTime(locale: string = "de-DE", options: object = {}): string {
        const defaults = {
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
            timeZone: "Europe/Berlin",
        };
        options = options ? {...options, ...defaults} : defaults;
        return this.toLocaleString("de-DE", options);
    }

    private static toLocaleString(locale?: string, options: object = {}): string {
        return new Date().toLocaleString(locale, options);
    }
    //
    // public static replaceSemicolonWithCommatas(semicolonSeparated: string): string {
    //     return semicolonSeparated
    //         .replace(/,/g, "")
    //         .replace(/;/g, ", ")
    //         // .replace(/\s+/g, " ")
    //         ;
    // }
    //
    // public static flattenPointsToPositionsAsString(points: IPoint[]): string {
    //     return points.filter((p) => {
    //         return p;
    //     }).map((p) => {
    //         return (p.station === null) ? p.position : p.position + "|" + p.station;
    //     }).join(";");
    // }
    //
    // public static generateId(figures: number = 9): string {
    //     return Math.random().toString(36).substr(2, figures);
    // }
    //
    // public static positionToObject(pos: string): {x: number, y: number} {
    //     let splitted;
    //     if (typeof pos === "string") {
    //         splitted = pos.split(",");
    //         return {x: splitted[0].trim(), y: splitted[1].trim()};
    //     }
    // }
    //
    // public static getScriptTemplateById(HtmlId) {
    //     return document.getElementById(HtmlId).innerHTML;
    // }
    //
    // public static reverseChildrenOfElement(element) {
    //     let i = 1; const len = element.childNodes.length;
    //     for (i; i < len; i++) {
    //         element.insertBefore(element.childNodes[i], element.firstChild);
    //     }
    // }
    //
    // public static swapValues(content, preValue, postValue, tmpValue) {
    //     const preRegEx = new RegExp(preValue, "g");
    //     const postRegEx = new RegExp(postValue, "g");
    //
    //     return content.replace(preRegEx, tmpValue).replace(postRegEx, postValue);
    // }
}
