export class UrlHelper {

    /**
     * Gets a base url (protocol://host)
     * @param urlStr
     */
    public static baseUrl(urlStr: string = window.document.location.href): string {
        const url = new URL(urlStr);
        return url.protocol + "//" + url.host;
    }

    /**
     * Creates an URL
     * @param baseUrl
     * @param parameters
     * @constructor
     */
    public static URL(path: string, parameters: { [key: string]: string }, baseUrl: string = UrlHelper.baseUrl()): URL {
        const url = path ? new URL(path, baseUrl) : new URL(baseUrl);
        for (const key of Object.keys(parameters)) {
            url.searchParams.set(key, parameters[key]);
        }
        return url;
    }

    /**
     * Checks if a given string is an URL
     * @param urlStr
     */
    public static URLForStr(urlStr: string): URL {
        try {
            return new URL(urlStr);
        } catch (e) { }
        try {
            return new URL(urlStr, UrlHelper.baseUrl());
        }catch (e) {}
        return null;
    }

    public static relUri(url: URL) {
        return url.toString().replace(UrlHelper.baseUrl(url.toString()), "");
    }

    /**
     * @param url
     * @param toAdd
     */
    public static joinQs(url: string, toAdd: URLSearchParams) {
        let spl = url.split("?");
        if (spl.length === 1) {
            const qs = toAdd.toString();
            return spl[0] + (qs ? "?" + qs : "");
        }
        const p = new URLSearchParams(spl[1]);
        const hash = spl[1].split("#");
        toAdd.forEach((value: string, key: string) => {
            p.set(key, value);
        });
        return spl[0] + "?" + p.toString() + (hash.length === 2 ? "#" + hash[1] : "");
    }
}
