import {IConfiguration} from "../frontend";

export abstract class OverlayTemplate {
    private _options: IConfiguration;

    public constructor(options: IConfiguration) {
        this._options = options;
    }

    public get options() {
        return this._options;
    }

    public abstract getNames(): string[];

    public abstract render(object: any): string;
}
