import {PointType} from "./IRoutePoint";
import {Routing} from "./Routing";
import {Vector} from "ol/layer";
import {IMapEventSubscriber} from "../IMapEventSubscriber";
import MapBrowserEvent from "ol/MapBrowserEvent";
import Feature from "ol/Feature";
import Circle from "ol/geom/Circle";

export class RoundRoute {
    protected routing: Routing;
    protected layer: Vector;
    protected move: IMapEventSubscriber;
    protected click: IMapEventSubscriber;
    protected circle: Feature;

    /**
     * @param routing
     * @param layer
     * @param coordinate
     */
    public constructor(routing: Routing, layer: Vector, coordinate: number[]) {
        this.routing = routing;
        this.layer = layer;
        const self = this;
        this.move = {
            onEvent(e: MapBrowserEvent): void {
                self.change(e);
            }
        };
        this.click = {
            onEvent(e: MapBrowserEvent): void {
                self.stop(e);
            }
        };
        // this.cleanRouting();
        this.addPoints(coordinate);
    }

    /**
     * Sets the rount route points
     * @param coordinate
     */
    public addPoints(coordinate: number[]) {
        this.circle = new Feature();
        this.circle.setGeometry(new Circle(coordinate, 0));
        this.layer.getSource().addFeature(this.circle);
        this.routing.setPoint(coordinate, PointType.start, this.routing.getCurrentSrid(), null, false);
        this.routing.setPoint(coordinate, PointType.end, this.routing.getCurrentSrid(), null, false);
        this.routing.setPoint(coordinate, PointType.via, this.routing.getCurrentSrid(), 1, false);
        this.routing.setPoint(coordinate, PointType.via, this.routing.getCurrentSrid(), 2, false);
        this.routing.setPoint(coordinate, PointType.via, this.routing.getCurrentSrid(), 3, false);
    }

    /**
     * Gets the mouse move subscriber.
     */
    public getMove(): IMapEventSubscriber {
        return this.move;
    }

    /**
     * Gets the mouse click subscriber.
     */
    public getClick(): IMapEventSubscriber {
        return this.click;
    }

    /**
     * Handler for mouse move
     * @param e
     */
    private change(e: MapBrowserEvent) {
        this.updatePoints(e.coordinate);
    }

    /**
     * Updates the round route points
     * @param coordinate
     */
    private updatePoints(coordinate: number[]) {
        this.routing.movePoint(coordinate, PointType.via, this.routing.getCurrentSrid(), 2, false);
        const start = this.routing.getStartPoint();
        const xd = coordinate[0] - start[0];
        const yd = coordinate[1] - start[1];
        const d = Math.sqrt(xd * xd + yd * yd);
        let a_rad = this.calculateAngle(yd / d, xd / d);
        const center = [start[0] + xd / 2, start[1] + yd / 2];
        (this.circle.getGeometry() as Circle).setCenter(center);
        (this.circle.getGeometry() as Circle).setRadius(d / 2);
        this.routing.movePoint(this.calculateCoordinate(center, a_rad + Math.PI / 2, d / 2),
            PointType.via, this.routing.getCurrentSrid(), 1, false);
        this.routing.movePoint(this.calculateCoordinate(center, a_rad + Math.PI + Math.PI / 2, d / 2),
            PointType.via, this.routing.getCurrentSrid(), 3, false);
    }

    /**
     * Finalizes a round route
     * @param e
     */
    private stop(e: MapBrowserEvent) {
        this.layer.getSource().removeFeature(this.circle);
        this.routing.completeRoundRoute();
    }

    /**
     * Calculates a real angle for given sin and cos.
     * @param sin_a
     * @param cos_a
     */
    private calculateAngle(sin_a: number, cos_a: number) {
        if (sin_a > 0) {
            return Math.acos(cos_a);
        } else if(sin_a < 0) {
            return 2 * Math.PI - Math.acos(cos_a);
        } else if (cos_a === 1) {
            return 0;
        } else {
            return 2 * Math.PI;
        }
    }

    /**
     * Calculates the point's coordinates for given: center, angle, radius.
     * @param center
     * @param angle_rad
     * @param radius
     */
    private calculateCoordinate(center: number[], angle_rad: number, radius: number) {
        return [center[0] + Math.cos(angle_rad) * radius, center[1] + Math.sin(angle_rad) * radius];
    }
}
