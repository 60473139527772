import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import Projection from "ol/proj/Projection";
import {METERS_PER_UNIT} from "ol/proj/Units";
import {get} from "ol/proj";
import {getConfiguration} from "../../client/MapApp";

export class Utils {

    /**
     * Converts a srid code to an epsg code
     * @param srid
     */
    public static sridToEpsg(srid: number): string {
        return "EPSG:" + srid;
    }

    /**
     * Converts an epsg code to a srid code
     * @param code
     */
    public static epsgToSrid(code: string): number {
        const chunks: string[] = code.split(":");
        return chunks.length > 1 ? parseInt(chunks[chunks.length - 1], 10) : null;
    }

    public static initProj4Defs(proj4Defs: any): void {
        for (const name in proj4Defs) {
            if (name) {
                Utils.setProj4Def(name, proj4Defs[name]);
            }
        }
        register(proj4);
    }

    public static setProj4Def(name: string, definition: string) {
        proj4.defs(name, definition);
    }

    public static getProj4Def(name: string) {
        return get(name);
    }

    public static createProjection(name: string, ext: [number, number, number, number] = null) {
        const p = Utils.getProj4Def(name);
        const proj = new Projection({
            code: name,
            units: p.getUnits(),
        });
        if (ext !== null) {
            proj.setExtent(ext);
        }
        return proj;
    }

    /**
     * Calculates a resolution scale factor
     * @param meterPerUnit
     * @param units 'degrees'|'ft'|'m'|'us-ft'
     */
    public static resolutionScaleFactor(meterPerUnit: number): number {
        const dpi = 25.4 / 0.28;
        // const mpu = ol.proj.METERS_PER_UNIT[units];
        const inchesPerMeter = 39.37;
        return meterPerUnit * inchesPerMeter * dpi;
    }

    /**
     * Calculates a resolution for a given scale
     * @param scale
     * @param factor
     */
    public static resolutionForScale(scale: number, factor: number): number {
        return scale / factor;
    }

    /**
     * Calculates resolutions for scales
     * @param scales
     * @param units 'degrees'|'ft'|'m'|'us-ft'
     * @param meterPerUnit
     */
    public static resolutionsForScales(scales: number[], units: string): number[] {
        const resolutions = [];
        const meterPerUnit: number = METERS_PER_UNIT[units];
        const factor = Utils.resolutionScaleFactor(meterPerUnit);
        for (const scale of scales) {
            resolutions.push(Utils.resolutionForScale(scale, factor));
        }
        return resolutions;
    }

    /**
     * Calculates a scale for a given resolution
     * @param resolution
     * @param factor
     */
    public static scaleForResolution(resolution: number, factor: number): number {
        return resolution * factor;
    }

    /**
     * Calculates scales for resolutions
     * @param resolutions
     * @param units
     * @param meterPerUnit
     */
    public static scalesForResolutions(resolutions: number[], units: string): number[] {
        const scales = [];
        const meterPerUnit: number = METERS_PER_UNIT[units];
        const factor = Utils.resolutionScaleFactor(meterPerUnit);
        for (const resolution of resolutions) {
            scales.push(Utils.scaleForResolution(resolution, factor));
        }
        return scales;
    }

    /**
     * Clones a given object
     * @param obj
     */
    public static clone(obj: any): any {
        // Handle the 3 simple types, and null or undefined
        if (null === obj || "object" !== typeof obj) {
            return obj;
        } else if (obj instanceof Date) { // Handle Date
            const copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        } else if (obj instanceof Array) { // Handle Array
            const copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = Utils.clone(obj[i]);
            }
            return copy;
        } else if (obj instanceof Object) { // Handle Object
            const copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = Utils.clone(obj[attr]);
                }
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    /**
     * Merges ob2 into ob1 objects. If create is true returns a result as a new object, otherwise a ob1.
     * @param {any} ob1
     * @param {any} ob2
     * @param {any} create
     * @return {any}
     */
    public static merge(ob1: any, ob2: any, create: boolean = false): any {
        let ob: any;
        if (create === true) {
            ob = Object.create(null);
            for (const k of Object.keys(ob1)) {
                ob[k] = ob1[k];
            }
        } else {
            ob = ob1;
        }
        for (const k of Object.keys(ob2)) {
            ob[k] = ob2[k];
        }
        return ob;
    }

    public static pathToUri(tocheck: string): string {
        if (tocheck.startsWith("http")) { // TODO for others protocols?
            return tocheck;
        } else {
            return getConfiguration().baseUrl + getConfiguration().basePath + tocheck;
        }
    }

    public static stripUrl(tosrip: string, prefix: string = getConfiguration().baseUrl + getConfiguration().basePath): string {
        return tosrip.startsWith(prefix) ? tosrip.substr(prefix.length) : tosrip;
    }
}
