export enum PointType {
    start = "start",
    end = "end",
    via = "via",
    temp = "temp",
}

export interface IRoutePoint {
    coordinates: number[];
    idx: null | number;
    srid: number;
    type: PointType;
}
