import Feature from "ol/Feature";
import Vector from "ol/layer/Vector";
import MapBrowserEvent from "ol/MapBrowserEvent";
import {DMap} from "./DMap";
import {IFeatureEventSubscriber} from "./IFeatureEventSubscriber";
import {MapEventType} from "./MapEventObserver";
import {IMapEventSubscriber} from "./IMapEventSubscriber";

let last_event_type = '';
let last_contextmenu_event_time = 0;

export class FeatureEventObserver implements IMapEventSubscriber {
    protected dmap: DMap;
    protected eventType: MapEventType;
    protected subscribers: IFeatureEventSubscriber[];

    public constructor(dmap: DMap, eventType: MapEventType) {
        this.dmap = dmap;
        this.eventType = eventType;
        this.subscribers = [];
    }

    public setEventType(eventType: MapEventType) {
        this.eventType = eventType;
    }

    public getEventType() {
        return this.eventType;
    }

    public count() {
        return this.subscribers.length;
    }

    public subscribe(subscriber: IFeatureEventSubscriber): boolean {
        const subscribers = new Set(this.subscribers);
        if (!subscribers.has(subscriber)) {
            this.subscribers.push(subscriber);
            return true;
        } else {
            return false;
        }
    }

    public unsubscribe(subscriber: IFeatureEventSubscriber) {
        const subscribers = new Set(this.subscribers);
        if (subscribers.has(subscriber)) {
            const d = subscribers.delete(subscriber);
            this.subscribers = Array.from(subscribers);
            return d;
        } else {
            return false;
        }
    }

    public onEvent(e: MapBrowserEvent) {
        if (e.type === MapEventType.contextmenu) {
            e.preventDefault();
        }
        if (!e.dragging) {
            for (const subscr of this.subscribers) {
                subscr.eventStart();
            }
            // add features
            this.dmap.getMap().forEachFeatureAtPixel(
                this.dmap.getMap().getEventPixel(e.originalEvent),
                (f: Feature, l: Vector) => {
                    for (const subscr of this.subscribers) {
                        if (subscr.hasLayer(l)) {
                            subscr.addByEvent(l, f);
                        }
                    }
                });
            for (const subscr of this.subscribers) {
                // touch-hold fires a contextmenu event and on touchrelease a singleclick touch event that can't be differentiated from singleclick touch event
                if(
                    typeof (<any>e).originalEvent.pointerType === 'undefined' ||
                    (
                        typeof (<any>e).originalEvent.pointerType !== 'undefined' && (
                            (<any>e).originalEvent.pointerType !== 'touch' ||
                            ((<any>e).originalEvent.pointerType === 'touch' && last_event_type !== 'contextmenu' && new Date().getTime() - last_contextmenu_event_time > 700)
                        )
                    )
                ){
                    subscr.eventEnd(e.coordinate as [number, number]);
                }
            }
        }
        if(last_event_type === 'contextmenu'){
            // prevent the context menu from being immediately closed if someone with shaky hands is trying to open it using long touch
            // as minimal finger movement will fire another singleclick event on the map that causes the contextmenu to close
            last_contextmenu_event_time = new Date().getTime();
        }
        last_event_type = e.type;
    }
}
