/**
 * O2QS Class - object to query string
 */
export class O2QS {

    private static add(s: string[], key: string, value: any) {
        value = (typeof value == 'function') ? value() : (value == null ? "" : value);
        s[s.length] = key + "=" + value;
    }

    private static build(s: string[], key: string, value: any) {
        if (value instanceof Array) {
            let i = 0;
            const t = /\[\]$/;
            const l = value.length;
            for (; i < l; i++) {
                if (t.test(key)) {
                    O2QS.add(s, key, value[i]);
                } else {
                    O2QS.build(s, key + "[" + (typeof value[i] === "object" ? i : "") + "]", value[i]);
                }
            }
        } else if (typeof value == "object") {
            for (const okey in value) {
                O2QS.build(s, key + "[" + okey + "]", value[okey]);
            }
        } else {
            O2QS.add(s, key, value);
        }
    }

    public static toQS(obj: any): string {
        const s: string[] = [];
        if (obj instanceof Array) {
            for (const idx in obj) {
                this.add(s, idx, obj[idx]);
            }
        } else {
            for (const key in obj) {
                O2QS.build(s, key, obj[key]);
            }
        }
        return s.join("&").replace(/%20/g, "+");
    }
}
